import { getCookies } from 'libs/common/cookies'
import { PaginatedResponse, Response } from 'types/responses'
import { DELETE, GET, PATCH, POST, PUT } from 'utils/requests.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

const BASE_PATH = 'integrations/v1/integrations/hubspot'
const BASE_URL = `${getElopageConfig('integrationServiceHost')}/${BASE_PATH}`

export interface ElopageIntegration {
  id: number
  createdAt: Date
  updatedAt: Date
}

export interface BareHubspotIntegration {
  accessToken: string
  dealCreateTemplate: string
  dealUpdateTemplate: string
  contactCreateTemplate: string
  contactUpdateTemplate: string
}

export interface HubspotIntegration extends ElopageIntegration, BareHubspotIntegration {
  configuration: Configuration
  uuid: string
  userId: string
  status: string
}

interface Configuration {
  matchOrderIdOn: string
}

export interface ElopageIntegrationCreateData {
  dummy: string
}

export interface HubspotIntegrationCreateData extends ElopageIntegrationCreateData, BareHubspotIntegration {}

export interface Execution {
  id: number
  integrationId: number
  webhookPayload: WebhookPayload
  status: string
  error: null
  createdAt: Date
  updatedAt: Date
}

export interface WebhookPayload {
  event: string
}

type Integration = ElopageIntegration | HubspotIntegration
type IntegrationCreateData = ElopageIntegrationCreateData | HubspotIntegrationCreateData

const defaultOptions = {
  __unsafeOptions: {
    hasBearer: true,
  },
}

const token = getCookies('access_token') as string

export const fetchList = (data): Promise<PaginatedResponse<Integration[]>> =>
  GET(BASE_URL, { ...data, authorization: `Bearer ${token}` }, true)

export const fetchItem = (id, data): Promise<Response<Integration>> =>
  GET(`${BASE_URL}/${id}`, { ...data, authorization: `Bearer ${token}` }, true)

export const createItem = (data: IntegrationCreateData): Promise<Response<Integration>> =>
  POST(BASE_URL, { ...defaultOptions, ...data })

export const updateItem = (id: number, data: IntegrationCreateData): Promise<Response<Integration>> =>
  PATCH(`${BASE_URL}/${id}`, { ...defaultOptions, ...data })

export const deleteItem = (id: number): Promise<Response<Integration>> =>
  DELETE(`${BASE_URL}/${id}`, { authorization: `Bearer ${token}` }, true)

export const activateConnection = (id: number): Promise<Response<Integration>> =>
  PUT(`${BASE_URL}/${id}/activate`, defaultOptions)

export const deactivateConnection = (id: number): Promise<Response<Integration>> =>
  PUT(`${BASE_URL}/${id}/deactivate`, defaultOptions)

export const fetchExecutions = (id: number, data): Promise<PaginatedResponse<Execution[]>> =>
  GET(`${BASE_URL}/${id}/executions`, { ...data, authorization: `Bearer ${token}` }, true)

export const fetchPipelines = (id: number): Promise<PaginatedResponse<Execution>> =>
  GET(`${BASE_URL}/${id}/pipeline`, { authorization: `Bearer ${token}` }, true)

export const retryExecution = (id: number, integrationId): Promise<Response<Execution>> =>
  PUT(`${BASE_URL}/${integrationId}/executions/${id}/retry`, defaultOptions)
