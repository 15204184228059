import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { AffiliateMarketplaceProduct } from 'shared/components/affiliate-marketplace/types/types'

import * as api from '../api/affiliateMarketplaceProducts.api'

export class AffiliateMarketplaceProductsStore extends SharedStore<AffiliateMarketplaceProduct> {
  storeName = 'AffiliateMarketplaceProductsStore'
  childApi = api

  constructor() {
    super()
    makeObservable(this)
  }

  @action deleteMarketplaceProduct = async (id) => {
    this.toggleLoading(true)
    const resp = await this.childApi.deleteMarketplaceProduct(id)
    this.toggleLoading(false)
    return resp
  }

  @action revokeItem = async (id) => {
    this.toggleLoading(true)
    const resp = await this.childApi.revokeItem(id)
    this.toggleLoading(false)
    return resp
  }

  @action unpublishItem = async (id) => {
    this.toggleLoading(true)
    const resp = await this.childApi.unpublishItem(id)
    this.toggleLoading(false)
    return resp
  }
}
