import * as api from '../api/iapProduct.api'
import SharedStore from '../../shared/stores/shared.store'

export class IapProductStore extends SharedStore {
  storeName = 'IapProductStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions
}

export default new IapProductStore()
