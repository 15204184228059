import { observable, action, makeObservable } from 'mobx'
import { findBlockById } from 'utils/contentBlock.utils'
import { arrayLast } from 'utils/array.utils'
import { BLOCK_DEFAULT_VALUES } from './constants/block.constants'

export class DragAndDropModel {
  @observable dnd = {
    isDragging: false,
    topAreaDrag: false,
    blockInDrag: null,
  }

  constructor({ contentPageStore, contentBlockStore }) {
    makeObservable(this)
    this.contentPageStore = contentPageStore
    this.contentBlockStore = contentBlockStore
  }

  @action setDND = (field, value) => {
    this.dnd = {
      ...this.dnd,
      [field]: value,
    }
  }

  onDragStart = (e, block = {}, row = {}) => {
    e.stopPropagation()
    e.dataTransfer.setData('id', block.id)
    setTimeout(() => {
      this.setDND('blockInDrag', block)
      this.setDND('blockInDragRow', row)
      this.setDND('isDragging', true)
    }, 0)
  }

  handleBlockEdit = (block) => this.contentBlockStore.switchBlockEdit(block)

  onDragStartCreation = (e, type) => {
    e.stopPropagation()
    e.dataTransfer.setData('id', 'new')
    setTimeout(() => {
      this.setDND('blockType', type)
      this.setDND('isDragging', true)
    }, 0)
  }

  onDragStartCopy = (e, block) => {
    e.stopPropagation()
    e.dataTransfer.setData('id', 'new')
    setTimeout(() => {
      this.setDND('blockToCopy', block)
      this.setDND('isDragging', true)
    }, 0)
  }

  onDragEnd = (e) => {
    e.stopPropagation()
    this.setDND('isDragging', false)
    this.setDND('blockInDrag', null)
  }

  onDragEnter = (e, block = {}, topAreaDrag = false) => {
    e.stopPropagation()

    if ((this.dnd.blockUnderDrag || {}).id !== block.id) {
      this.setDND('blockUnderDrag', block)
    }

    this.setDND('topAreaDrag', topAreaDrag)
  }

  onDragOver = (e) => {
    e.preventDefault()
  }

  resetDND = () =>
    ['blockInDrag', 'blockType', 'blockToCopy', 'blockInDragRow', 'topAreaDrag'].forEach((item) =>
      this.setDND(item, null)
    )

  onDrop = (e, block = {}) => {
    e.preventDefault()
    this.setDND('isDragging', false)
    this.setDND('topAreaDrag', false)
    this.setDND('blockUnderDrag', null)

    if (this.dnd.blockInDrag && this.dnd.blockInDrag.id) {
      this.contentPageStore.updateContentBlock(this.dnd.blockInDrag.id, {
        parentId: block.parentId,
        position: block.position + 1,
      })

      const hasChildrenLength = block?.children?.length
      const lastChildrenBlock = hasChildrenLength ? arrayLast(block.children) : block

      const commonData = {
        ...this.dnd.blockInDrag,
        isCreated: false,
        dragAndDropped: true,
        position: block.position + 1,
      }
      const undoArrayData = hasChildrenLength
        ? { ...commonData }
        : {
            ...commonData,
            parentId: '',
            redoParentId: lastChildrenBlock.parentId,
          }
      this.contentBlockStore.updateUndoArray(undoArrayData)
      this.contentBlockStore.resetRedoArray()
    } else if (this.dnd.blockType) {
      const blockObject = Object.assign(block)
      const dndObject = Object.assign(this.dnd)
      this.contentPageStore
        .createContentBlock(
          block.parentId,
          this.dnd.blockType,
          block.position + 1,
          BLOCK_DEFAULT_VALUES[this.dnd.blockType]
        )
        .then(({ data, success }) => {
          if (success) {
            const newBlock = findBlockById(data.contentBlocks, data.contentBlockId)
            this.handleBlockEdit(newBlock)
            this.contentBlockStore.updateUndoArray({
              parentId: blockObject.parentId,
              form: dndObject.blockType,
              position: blockObject.position + 1,
              ...BLOCK_DEFAULT_VALUES[dndObject.blockType],
              isCreated: true,
              contentBlockId: newBlock.id,
            })
            this.contentBlockStore.resetRedoArray()
          }
        })
    } else if (this.dnd.blockToCopy) {
      this.contentPageStore.createItemFromTemplate(this.dnd.blockToCopy.id, {
        parentId: block.parentId,
        position: block.position + 1,
      })
    }

    this.resetDND()
  }

  showRowDropZoneFor = (row = {}) =>
    this.dnd.isDragging &&
    !this.dnd.topAreaDrag &&
    (this.dnd.blockUnderDrag || {}).id === row.id &&
    ((this.dnd.blockInDragRow && (this.dnd.blockInDragRow.children || []).length > 1) ||
      ((this.dnd.blockInDrag || {}).parentId !== row.id &&
        (this.dnd.blockInDragRow || {}).position !== row.position + 1))

  showColDropZoneFor = (block = {}) =>
    this.dnd.isDragging &&
    (this.dnd.blockUnderDrag || {}).id === block.id &&
    (this.dnd.blockInDrag || {}).id !== block.id &&
    ((this.dnd.blockInDrag || {}).parentId !== block.parentId ||
      (this.dnd.blockInDrag || {}).position - 1 !== block.position)
}

export default DragAndDropModel
