import { action, computed, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/productUpsells.api'

const defaultPrefs = {
  ownPlans: false,
}

export class ProductUpsellsStore extends SharedStore {
  storeName = 'ProductUpsellsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @override
  resetItem() {
    this.item = {
      prefs: defaultPrefs,
    }
  }

  @action updatePrefs = (name, value) => {
    this.item = {
      ...this.item,
      prefs: {
        ...this.item.prefs,
        [name]: value,
      },
    }
  }

  @action changeItem = (name, value) => {
    this.item = {
      ...this.item,
      [name]: value,
      prefs: {
        ...this.item.prefs,
      },
    }
  }

  @computed get preparePricesForSave() {
    return this.item.pricingPlans?.map(({ id, priceId, position, _destroy }) => ({
      position,
      _destroy,
      id: priceId,
      pricingPlanId: id,
    }))
  }

  @action setListPositions = (list, oldPosition, newPosition) => {
    const { id } = this.list.find(({ position }) => position === oldPosition)
    this.updateItem(id, { position: newPosition })
    this.list = list
    this.list.forEach((item, index) => {
      item.position = index
    })
  }

  constructor() {
    super()

    makeObservable(this)

    this.item = {
      prefs: defaultPrefs,
    }
  }
}

export default new ProductUpsellsStore()
