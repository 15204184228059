export const QR_CODE_RANGE_CONFIG = {
  min: 10,
  max: 35,
}

export const FONT_SIZE_MAX_VALUE = 85

export const TICKET_ITEMS_IDS = {
  name: 'name',
  location: 'location',
  date: 'date',
  time: 'time',
  additionalText: 'additionalText',
  code: 'code',
  qrCode: 'qrCode',
}

export const TICKET_DESIGN_DEFAULT_CONFIG = [
  {
    id: TICKET_ITEMS_IDS.name,
    nameXPosition: 4,
    nameYPosition: 24,
    nameFont: 'Open Sans',
    nameItalic: 0,
    nameBold: 1,
    nameColor: '#b7b7b7',
    nameSize: '45',
    nameHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.location,
    locationXPosition: 4,
    locationYPosition: 58,
    locationFont: 'Open Sans',
    locationItalic: 0,
    locationBold: 1,
    locationColor: '#b7b7b7',
    locationSize: '12',
    locationHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.date,
    dateXPosition: 4,
    dateYPosition: 69,
    dateFont: 'Open Sans',
    dateItalic: 0,
    dateBold: 1,
    dateColor: '#b7b7b7',
    dateSize: '15',
    dateHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.time,
    timeXPosition: 21,
    timeYPosition: 69,
    timeFont: 'Open Sans',
    timeItalic: 0,
    timeBold: 1,
    timeColor: '#b7b7b7',
    timeSize: '15',
    timeHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.additionalText,
    additionalTextXPosition: 4,
    additionalTextYPosition: 43,
    additionalTextFont: 'Open Sans',
    additionalTextItalic: 0,
    additionalTextBold: 0,
    additionalTextColor: '#b7b7b7',
    additionalTextSize: '12',
    additionalTextHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.code,
    codeXPosition: 4,
    codeYPosition: 88,
    codeFont: 'Open Sans',
    codeItalic: 0,
    codeBold: 0,
    codeColor: '#b7b7b7',
    codeSize: '12',
    codeHidden: 0,
  },
  {
    id: TICKET_ITEMS_IDS.qrCode,
    qrCodeXPosition: 84,
    qrCodeYPosition: 72,
    qrCodeWidth: 13,
  },
]

export const TICKET_FORM_LOCATION = {
  online: 'online',
  offline: 'offline',
}

export const MAX_TICKET_QUANTITY_PER_PAYMENT = 75
