import { action, computed, observable, makeObservable } from 'mobx'

import { getObjectWithoutProperties } from 'utils/helpers.utils'

import { MODAL_KEYS } from '../utils/modals'

export class PageBuilderModalsStore {
  @observable activeModals = {}

  constructor() {
    makeObservable(this)
  }

  @action openModal = (key: MODAL_KEYS, settings = {}) => {
    this.activeModals = {
      [key]: {
        settings,
      },
    }
  }

  @action hideModal = (key: MODAL_KEYS) => {
    this.activeModals = getObjectWithoutProperties(this.activeModals, [key])
  }

  @computed get activeModalsList() {
    return Object.entries(this.activeModals).map(([key, value]) => ({
      name: key,
      data: value,
    }))
  }
}

export default new PageBuilderModalsStore()
