import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/metabaseIntegration.api'

export class MetabaseIntegrationStore extends SharedStore<api.Token> {
  storeName = 'MetabaseIntegrationStore'
  childApi = api

  constructor() {
    super()
    makeObservable(this)
  }
}

export default new MetabaseIntegrationStore()
