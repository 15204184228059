import { observable, action, makeObservable } from 'mobx'

import { EXPAND_ORDER_PARAMS } from 'constants/orders.constants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/orders.api'

export class OrdersStore extends SharedStore {
  storeName = 'OrdersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable availableBalances = []
  @observable availableBalanceIndex = null

  @action extendAccess = async (id, sellableItemId) => {
    this.loading = true
    const resp = await api.extendAccess(id, { sellableItemId })

    if (resp.success) {
      this.fetchItem(id, { expand: EXPAND_ORDER_PARAMS })
    }

    this.loading = false
  }

  @action setAvailableBalanceIndex = (index) => (this.availableBalanceIndex = index)

  updateRatesList = (id, data) => api.updateRatesList(id, data)

  cancelItem = async (id, data = {}) => {
    this.loading = true
    const resp = await api.cancel(id, data)
    this.loading = false
    return resp
  }

  giveAccess = (id) => api.giveAccess(id)
  giveAccessTeamMember = (id, sellerName) => api.giveAccessTeamMember(id, sellerName)
  fetchSummary = (data) => api.fetchSummary(data)
  chargeOrder = (id, data) => api.chargeOrder(id, data)
  @action getAvailableBalance = async (id) => {
    const resp = await api.getAvailableBalance(id)
    if (resp.success) {
      this.availableBalances = resp.data
    }
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new OrdersStore()
