import { GET, PUT, POST, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/seller_custom_notifications'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const updateItem = (id, data, params) => PUT(`${BASE_URL}/${id}`, data, params)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)

export const createItem = (data, params) => POST(BASE_URL, data, params)

export const sendNotification = (id) => POST(`${BASE_URL}/${id}/send_notifications`)
