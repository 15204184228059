import { POST } from 'utils/requests.utils'

const BASE_URL = '/cabinet/setup_intents'

export const createItem = (data) =>
  POST(BASE_URL, {
    async: false,
    ...data,
  })

export default createItem
