import { GET } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = '/cabinet/metabase_dashboard_token'

export interface Token {
  token: string
  theme: string
}

export const fetchItem = (appKey = '') => GET<Response<Token>>(`${BASE_URL}/${appKey}`)
