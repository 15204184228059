import { action, toJS, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { DEFAULT_FULL_LIST } from 'constants/pagination.constants'

import * as api from '../api/contentBlockTemplates.api'

export class ContentBlockTemplatesStore extends SharedStore {
  storeName = 'ContentBlockTemplatesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions

  @action fetchSharedTemplatesList = async (id) => {
    this.loading = true
    const resp = await this.childApi.fetchSharedTemplatesList(id, { ...toJS(this.scopes) })
    if (resp.success) {
      this.list = resp.data.list
    }
    this.loading = false
    return resp
  }

  @action fetchSharedTemplatesFullList = async (id) => {
    this.loading = true
    const resp = await this.childApi.fetchSharedTemplatesList(id, {
      per: DEFAULT_FULL_LIST,
      ...toJS(this.scopes),
    })
    if (resp.success) {
      this.list = resp.data.list
    }
    this.loading = false
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new ContentBlockTemplatesStore()
