import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/orders'

const TEAM_MEMBER_BASE_URL = '/team_member/sellers'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL}/${id}`, data)

export const extendAccess = (id, data) => POST(`${BASE_URL}/${id}/extend_access`, data)

export const assignPublisher = (id, data) => POST(`${BASE_URL}/${id}/assign_publisher`, data)

export const updateRatesList = (id, data) => POST(`${BASE_URL}/${id}/update_order_rates`, data)

export const cancel = (id, data) => POST(`${BASE_URL}/${id}/cancel`, data)

export const giveAccess = (id) => POST(`${BASE_URL}/${id}/give_access`)

export const giveAccessTeamMember = (id, sellerName) =>
  POST(`${TEAM_MEMBER_BASE_URL}/${sellerName}/orders/${id}/give_access`)

export const fetchSummary = (data) => GET(`${BASE_URL}/summary`, data)

export const getAvailableBalance = (id) => GET(`${BASE_URL}/${id}/available_balance`)

export const chargeOrder = (id, data) => POST(`${BASE_URL}/${id}/charge`, data)
