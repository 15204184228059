import SharedStore from 'shared/stores/shared.store'
import { observable, action, makeObservable } from 'mobx'
import * as api from '../api/salesTeamMemberInvites.api'

export class SalesTeamMemberInvitesStore extends SharedStore {
  storeName = 'SalesTeamMemberInvitesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()
    makeObservable(this)
  }

  @observable invitesCount = null

  @action async fetchInviteCount({ id }) {
    this.toggleLoading(true)

    const resp = await this.childApi.fetchList({ id, state: 'initialized' })

    if (resp.success) {
      this.invitesCount = resp.data?.totalCount
    }

    this.toggleLoading(false)
  }
}

export default new SalesTeamMemberInvitesStore()
