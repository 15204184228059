import type { ApiClientV2Default } from 'utils/api-client/api-client.utils'
import { FromSwagger } from 'types/helpers'

import { OrderOrderRatesBulkUpdateAttributes } from 'types/__generated__/api'

export type OrderRates = FromSwagger<OrderOrderRatesBulkUpdateAttributes>

const BASE_URL = 'common/orders'

interface OrderRate {
  id: number
  amount?: number
  charge_date?: string
}

interface RegularOrderRate {
  amount: number
  interval: {
    count: number
    period: string
  }
}

interface Sallable {
  id: number
  orderRates: {
    update?: OrderRate[]
    delete?: OrderRate[]
    create?: OrderRate[]
  }
}

interface RegularSellable {
  id: number
  initialRate: RegularOrderRate
  recurringRate: RegularOrderRate
}

export interface UpdateSubscriptionBody {
  notifyUser: boolean
  role: string
  sellables: Sallable[]
}

export interface UpdateRegularSubscriptionBody {
  notifyUser: boolean
  role: string
  sellables: RegularSellable[]
}

export const createOrdersApi = ({ PUT }: ApiClientV2Default) => ({
  updateOrderRates: (id: number, data: UpdateRegularSubscriptionBody | UpdateSubscriptionBody) =>
    PUT<OrderRates>(`${BASE_URL}/${id}/order_rates/bulk_update`, data),
})

export type OrdersApi = ReturnType<typeof createOrdersApi>
