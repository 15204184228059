import { action, makeObservable, override } from 'mobx'

import { DEFAULT_UPSELLS_TYPE } from 'constants/products.constants'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/productSettings.api'

const initialPrefs = {
  upsell: false,
  mainProductRequired: false,
  mainProductSettings: false,
  emailForEachItem: false,
  upsellTitle: '',
  upsellType: DEFAULT_UPSELLS_TYPE,
}

export class ProductSettingsStore extends SharedStore {
  storeName = 'ProductSettingsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions

  @override
  resetItem() {
    this.item = {
      prefs: initialPrefs,
    }
  }

  @action createSettingsItem = (id, data) => api.createSettingsItem(id, data)

  @action changeProductSettings = (name, value, subItem) => {
    if (subItem) {
      this.item.prefs = {
        ...this.item.prefs,
        [name]: value,
      }
    } else {
      this.item = {
        ...this.item,
        [name]: value,
      }
    }
  }

  constructor() {
    super()

    makeObservable(this)

    this.item = {
      prefs: initialPrefs,
    }
  }
}

export default new ProductSettingsStore()
