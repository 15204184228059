import { GET, POST, DELETE, GET_API_URL, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/csv_logs'

export const fetchList = (data) => GET(BASE_URL, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const downloadCsv = (id) => GET_API_URL(`${BASE_URL}/${id}/download_csv`)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)
