import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/deals.api'

export class DealsStore extends SharedStore {
  storeName = 'DealsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  changePaymentMethod = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.changePaymentMethod(id, data)
    this.toggleLoading(false)
    return resp
  }

  sendEmail = async (id) => {
    this.toggleLoading(true)
    const resp = await this.childApi.sendEmail(id)
    this.toggleLoading(false)
    return resp
  }

  sendBindingOfferEmail = async (id) => {
    this.toggleLoading(true)
    const resp = await this.childApi.sendBindingOfferEmail(id)
    this.toggleLoading(false)
    return resp
  }
}

export default new DealsStore()
