import { DELETE, GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/settings/payment_setting/lemon_way_legitimation'
const BANK_ACCOUNTS_URL = `${BASE_URL}/lemon_way_bank_accounts`
const WALLET_URL = `${BASE_URL}/lemon_way_wallet`

// BASE_URL
export const fetchItem = () => GET(`${BASE_URL}?expand[]=legitimation_documents`)
export const createItem = (data) => POST(BASE_URL, data)
export const updateItem = (data) => PUT(BASE_URL, data)

// BANK_ACCOUNTS_URL
export const fetchBankAccountsList = () => GET(`${BANK_ACCOUNTS_URL}?per=1000`)
export const createBankAccount = (data) => POST(BANK_ACCOUNTS_URL, data)
export const fetchBankAccount = (id) => GET(`${BANK_ACCOUNTS_URL}/${id}`)
export const updateBankAccount = (id, data) => PUT(`${BANK_ACCOUNTS_URL}/${id}`, data)
export const deleteBankAccount = (id) => DELETE(`${BANK_ACCOUNTS_URL}/${id}`)

// WALLET_URL
export const fetchWallet = () => GET(WALLET_URL)
