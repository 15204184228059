import { action, computed, observable, makeObservable } from 'mobx'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { getWalletState } from 'utils/paymentSetting.utils'
import { LEMONWAY_EXTERNAL_STATUSES, LEMONWAY_INTERNAL_STATUSES } from 'constants/paymentSetting.constants'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/lemonwayLegitimation.api'

export class LemonwayLegitimationStore extends SharedStore {
  storeName = 'LemonwayLegitimationStore'
  childApi = api
  @observable bankAccountsList = []
  @observable submitting = false
  @observable wallet = {}

  @action setBankAccountList = (bankAccountsList) => (this.bankAccountsList = bankAccountsList)
  @action setSubmitting = (submitting) => (this.submitting = submitting)

  @action setWallet = ({ data, success } = {}) => {
    if (data && success) this.wallet = data
  }

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get hasBankAccounts() {
    return !!this.bankAccountsList.length
  }

  @computed get legitimationCreated() {
    return !isEmpty(this.item)
  }

  @computed get allowedToSell() {
    return this.item.state === LEMONWAY_EXTERNAL_STATUSES.kyc2
  }

  @computed get disableEdit() {
    const { internalState } = this.item
    return (
      this.allowedToSell ||
      internalState === LEMONWAY_INTERNAL_STATUSES.inReview ||
      internalState === LEMONWAY_INTERNAL_STATUSES.reviewed
    )
  }

  @computed get walletState() {
    const { internalState, state } = this.item
    return getWalletState({
      internalState,
      state,
    })
  }

  createBankAccount = this.childApi.createBankAccount

  fetchBankAccountsList = async () => {
    const response = await this.childApi.fetchBankAccountsList()
    const { success, data = {} } = response || {}
    const { list } = data
    if (success && list) {
      this.setBankAccountList(list)
    }
  }

  fetchWallet = async () => {
    const response = await this.childApi.fetchWallet()
    this.setWallet(response)
  }
}

export default new LemonwayLegitimationStore()
