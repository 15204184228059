import SharedStore from 'shared/stores/shared.store'
import { makeObservable } from 'mobx'
import * as api from '../api/trustSeals.api'

export class TrustSealsStore extends SharedStore<api.TrustSeal> {
  storeName = 'TrustSealsStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new TrustSealsStore()
