import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/membershipSessions.api'

export class MembershipSessionsStore extends SharedStore {
  storeName = 'membershipSessionsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promice .then() to do after-actions
  toggleBlock = async (id) => {
    this.toggleLoading(true)
    const resp = await api.toggleBlock(id)
    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
      this.fetchList()
    } else {
      notify('warning', I18n.t('react.cabinet.notific.warning'))
    }
    this.toggleLoading(false)
  }

  cancelSubscription = async (id) => {
    this.toggleLoading(true)

    const resp = await api.cancelItemSubscription(id)
    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.unsubscribeSuccess'))
      this.fetchList()
    } else {
      notify('warning', I18n.t('react.cabinet.notific.warning'))
    }

    this.toggleLoading(false)
  }

  continueSubscription = async (id) => {
    this.toggleLoading(true)

    const resp = await api.continueItemSubscription(id)

    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.success'))
      this.fetchList()
    } else {
      notify('error', I18n.t('react.shared.renew.messages.setup_payment_first'))
    }

    this.toggleLoading(false)
  }

  giveFullAccess = (data) => api.giveFullAccess(data)
}

export default new MembershipSessionsStore()
