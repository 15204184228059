import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/cabinet/membership_sessions'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const toggleBlock = (id) => POST(`${BASE_URL}/${id}/toggle_block`)

export const cancelItemSubscription = (id) => POST(`${BASE_URL}/${id}/cancel_subscription`)

export const continueItemSubscription = (id) => POST(`${BASE_URL}/${id}/continue_subscription`)

export const giveFullAccess = (data) => POST(`${BASE_URL}/give_full_access`, data)
