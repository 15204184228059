import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/salesTeamInviteBulks.api'

export class SalesTeamInviteBulksStore extends SharedStore {
  storeName = 'SalesTeamInviteBulksStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  sendInvites = (data) => {
    this.toggleLoading(true)
    const resp = this.childApi.sendInvites(data)
    this.toggleLoading(false)
    return resp
  }
}

export default new SalesTeamInviteBulksStore()
