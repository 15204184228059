import { makeObservable } from 'mobx'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/partiallyPaid.api'

export class PartiallyPaidStore extends SharedStore<api.TransfersList> {
  storeName = 'PartiallyPaidStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PartiallyPaidStore()
