import { makeObservable, override } from 'mobx'
import * as api from '../api/customNotifications.api'
import SharedStore from '../../shared/stores/shared.store'

export class CustomNotifications extends SharedStore {
  storeName = 'CustomNotifications'
  childApi = api

  @override
  createItem(data, params) {
    return this.childApi.createItem(data, params)
  }

  @override
  updateItem(id, data, params) {
    return this.childApi.updateItem(id, data, params)
  }

  sendNotification = (id) => this.childApi.sendNotification(id)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new CustomNotifications()
