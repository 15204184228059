import { action, computed, makeObservable, observable } from 'mobx'

import { getNestedChildren } from 'utils/product.utils'
import { notify } from 'libs/common/notify'

import { LESSON_EXPAND } from 'constants/routes/lessons.contants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/lessons.api'

export class LessonsStore extends SharedStore {
  storeName = 'LessonsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable activeTab = 'content'
  @observable activeSubTab = 'mainContent'
  @observable settingsOpened = false
  @observable showNotify = false

  @action getAllLessonsCount = () => this.list.filter((l) => !l.isCategory).length

  fetchLessonsInSelect = async (requestData = {}) => {
    this.toggleCustomLoading(true)
    const data = {
      productId: this.root.productsStore.item.id,
      ...requestData,
    }
    const resp = await this.fetchList(data)
    this.toggleCustomLoading(false)
    return resp
  }

  @action fetchLessons = async (data) => {
    this.toggleCustomLoading(true)
    const requestData = data || { productId: this.root?.productsStore?.item?.id }
    await this.fetchFullList(requestData).then(() => this.toggleCustomLoading(false))
  }

  @action handleTabClick = (name, elem) => {
    this[name] = elem
  }

  @action handleInput = (name, value) => {
    this.item[name] = value
  }

  @action fetchLesson = async (lessonId, data, activeTab = 'content') => {
    const resp = await this.fetchItem(lessonId, {
      productId: this.root.productsStore.item.id,
      ...(data || {}),
      expand: LESSON_EXPAND,
    })
    if (resp.success) {
      this.activeTab = activeTab
      this.showNotify = false
      this.item = resp.data
    }
    return resp
  }

  @action createLesson = async (data) => {
    const resp = await this.createItem({
      ...data,
      productId: this.root.productsStore.item.id,
    })
    if (resp.success) {
      this.fetchLessons()
      this.fetchLesson(resp.data.id)
      this.settingsOpened = false
    }
  }

  @action updateLesson = async (lessonId, data) => {
    const resp = await this.updateItem(lessonId, {
      ...data,
      productId: this.root.productsStore.item.id,
    })
    if (resp.success) {
      this.fetchLessons()
      this.fetchLesson(lessonId)
    }
  }

  @action removeLesson = async (lessonId) => {
    this.customLoadingEnabled = true
    this.loading = true
    const resp = await this.deleteItem(lessonId, {
      productId: this.root.productsStore.item.id,
    })
    this.customLoadingEnabled = false
    if (resp.success) {
      if (lessonId === this.item.id) {
        this.item = {}
      }
      this.settingsOpened = false
      this.loading = false
      this.fetchLessons()
    }
  }

  @action duplicateLesson = async (lessonId) => {
    const resp = await this.duplicateItem(lessonId, {
      productId: this.root.productsStore.item.id,
    })
    if (resp.success) {
      this.fetchLessons()
      this.fetchLesson(resp.data.id)
    }
  }

  @action toggleSettings = () => {
    this.settingsOpened = !this.settingsOpened
  }

  @action handleLessonAttrSave = (name, val) => {
    this.item[name] = val
    this.handleSave(name)
  }

  @action handleLessonSettingsAttrChange = (name, val) => {
    this.item = {
      ...this.item,
      lessonSettings: {
        ...this.item.lessonSettings,
        [name]: val,
      },
    }
  }

  @action handleLessonSettingsAttrSave = (name, val) => {
    this.handleLessonSettingsAttrChange(name, val)
    this.handleSave('lessonSettings')
  }

  @action handleSave = async (name) => {
    const resp = await api.updateItem(this.item.id, {
      productId: this.root.productsStore.item.id,
      [name]: this.item[name],
    })

    if (resp.success) {
      this.fetchLessons()
    }
  }

  @action blocksModified = () => {
    if (
      this.activeTab === 'content' &&
      !this.item.static &&
      this.item.lessonPublishStates &&
      Object.keys(this.item.lessonPublishStates).length
    ) {
      this.showNotify = true
    }
  }

  @action notifyAboutChanges = async (states) => {
    const resp = await api.notifyAboutChangesItem(this.item.id, {
      productId: this.root.productsStore.item.id,
      states,
    })

    if (resp.success) {
      this.showNotify = false
      notify('success', I18n.t('react.cabinet.notific.success'))
    }
  }

  @computed get lessonsTree() {
    return getNestedChildren(this.list, null)
  }

  constructor(rootStore) {
    super()
    this.root = rootStore
    makeObservable(this)
    this.item = {}
  }
}

export const lessonsForModalsStore = new LessonsStore()
