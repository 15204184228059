import { makeObservable, observable } from 'mobx'

import { notify } from 'libs/common/notify'
import { POST_STATUSES } from 'constants/communities.constants'
import * as api from '../api/communityPostsModeration.api'
import SharedStore from './shared.store'

export class CommunitiesPostsModerationStore extends SharedStore<any> {
  storeName = 'CommunitiesPostsModerationStore'
  childApi = api
  @observable loadMoreButtonLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  handleActionError = (error) => {
    if (error) {
      if (error.type === 'record_not_found') {
        notify('error', I18n.t('react.cabinet.community.post_removed'))
      } else {
        notify('error', error.message)
      }
    }
  }

  rejectPost = async (discussionId: number, id: number): Promise<any> => {
    const { success, error } = await this.childApi.updateItem(
      discussionId,
      { id, state: POST_STATUSES.rejected },
      { skipErrorNotific: true }
    )
    if (success) {
      notify('success', I18n.t('react.cabinet.community.post_rejected'))
    }

    this.handleActionError(error)

    await this.fetchList()
  }

  approvePost = async (discussionId: number, id: number): Promise<any> => {
    const { success, error } = await this.childApi.updateItem(
      discussionId,
      { id, state: POST_STATUSES.approved },
      { skipErrorNotific: true }
    )
    if (success) {
      notify('success', I18n.t('react.cabinet.community.post_approved'))
    }

    this.handleActionError(error)

    await this.fetchList()
  }
}

export default new CommunitiesPostsModerationStore()
