import { action, observable, toJS, makeObservable, override } from 'mobx'

import { MULTI_CURRENCY_OPTION_KEY } from 'constants/options.constants'
import { DEFAULT_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'
import { CURRENCY_KEYS } from 'constants/currencies.constants'

import SharedStore from 'shared/stores/shared.store'

import {
  convertToPrice,
  getAvailableCurrencies,
  getCurrencyId,
  getCurrencyKey,
  getCurrencySignPosition,
  getCurrencyUnit,
} from 'utils/currencies.utils'
import * as api from '../api/currencies.api'

export class CurrenciesStore extends SharedStore {
  storeName = 'CurrenciesStore'
  childApi = api

  @observable fullList = []
  @observable allowCurrenciesList = []

  @action setAllowCurrenciesList = (list) => (this.allowCurrenciesList = list)
  @action setFullList = (fullList) => (this.fullList = fullList)

  @override
  async fetchList(data) {
    this.toggleLoading(true)

    const resp = await api.fetchList({
      expand: toJS(this.expands),
      ...this.queryParams,
      ...data,
      sortDir: 'asc',
    })

    if (resp.success) {
      const { list: allCurrencies = [], totalCount = 0 } = resp.data || {}

      const multiCurrencyAppBooked = this.root.sellerStore.isAppActive(MULTI_CURRENCY_OPTION_KEY)
      const { allowedPaymentMethods = {} } = this.root.sellerStore.item || {}
      const allowList = multiCurrencyAppBooked
        ? [...allCurrencies]
        : [...allCurrencies.filter(({ key }) => key === CURRENCY_KEYS.eur)]

      const list = allCurrencies.filter(
        ({ id, key }) =>
          (allowedPaymentMethods[id] || []).length && (key === CURRENCY_KEYS.eur || multiCurrencyAppBooked)
      )

      this.setAllowCurrenciesList(allowList)
      this.setList(list)
      this.setFullList(allCurrencies)
      this.updatePagination({ total: totalCount })

      const { page, per, total } = this.pagination

      const pageCount = total ? Math.ceil(total / per) : DEFAULT_PAGE

      if (page > pageCount) {
        this.handlePaginationChange(PAGINATION_KEYS.page, pageCount)
      }

      if (page < DEFAULT_PAGE) {
        this.handlePaginationChange(PAGINATION_KEYS.page, DEFAULT_PAGE)
      }
    }

    this.toggleLoading(false)

    /** for MultiAsyncSelectField */
    resp.data = {
      list: this.list,
      totalCount: this.list.length,
    }
    return resp
  }

  getId = (key) => getCurrencyId(this.fullList, key)

  getKey = (id) => getCurrencyKey(this.fullList, id)

  getUnit = (id) => getCurrencyUnit(this.fullList, id)

  getCurrencySignPosition = (currencySign) => getCurrencySignPosition(currencySign)

  convertToPrice = (value, currencyId, separator = '') =>
    convertToPrice(this.fullList, value || 0, currencyId, separator)

  filterAvailableCurrencies = ({ paymentSystem, paymentForm, paymentProvider } = {}) =>
    getAvailableCurrencies(this.fullList, paymentSystem, paymentForm, paymentProvider)

  fetchActivatedPayMethods = (id) => api.fetchActivatedPayMethods(id)

  constructor(rootStore) {
    super()

    this.root = rootStore
    makeObservable(this)
  }
}
