import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/designTemplates.api'

export class DesignTemplatesStore extends SharedStore {
  storeName = 'DesignTemplatesStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new DesignTemplatesStore()
