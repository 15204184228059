import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/salesTeam.api'

export class SalesTeamsStore extends SharedStore {
  storeName = 'SalesTeamStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  inviteBulks = api.inviteBulks
  activateTeam = api.activeTeam
  createIncentivePlan = api.createIncentivePlan
}

export default new SalesTeamsStore()
