import { GET } from 'utils/requests.utils'
import { Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/cabinet/transfers/partially_paid'

interface TransfersListFeeRate {
  total: number
  planId: number
  serviceStatic: number
  serviceDynamic: number
}

interface TransfersListPaypalInfo {
  approvalUrl: string
  payerId: string
  paymentId: string
  token: string
}

export interface TransfersList {
  amount: string
  authorId: Nullable<number>
  campaignId: Nullable<number>
  chargebackFee: string
  confirmationToken: Nullable<string>
  confirmationToken2: Nullable<string>
  createdAt: string
  creditCardId: Nullable<number>
  currencyId: number
  errorData: Record<string, unknown>
  extId: string
  feeRates: TransfersListFeeRate
  form: string
  id: number
  idForSeller: number
  initReceiverId: Nullable<number>
  initReceiverType: Nullable<string>
  isCorrection: boolean
  monthlyInvoiceId: Nullable<number>
  p2pVersion: string
  paypalInfo: Array<TransfersListPaypalInfo>
  postProcessed: boolean
  processingFee: string
  provider: string
  providerData: Record<string, any>
  publisherFee: string
  receiverId: number
  receiverType: string
  receiverWalletId: Nullable<number>
  refundFee: string
  resellerId: number
  sddMandateId: Nullable<number>
  senderId: number
  senderType: string
  senderWalletId: Nullable<number>
  serviceFee: string
  state: string
  successDate: string
  team_memberFee: string
  total: string
  transferableId: number
  transferableType: string
  updatedAt: string
  vatAmount: string
  vatRate: string
}
export const fetchList = (data) => GET<Response<TransfersList>>(BASE_URL, data)
