import { GET, POST, PATCH } from 'utils/requests.utils'
import { HTTPResponse } from 'types/responses'

const BASE_URL = (id) => `/cabinet/sales_teams/${id}/sales_team_invite_bulks`

export const fetchList = ({ id, ...restData }) => GET<HTTPResponse>(BASE_URL(id), restData)

export const createItem = ({ id, data }) => POST<HTTPResponse>(`${BASE_URL(id)}`, data)

export const updateItem = ({ id, salesTeamInviteId }, data) =>
  PATCH<HTTPResponse>(`${BASE_URL(id)}/${salesTeamInviteId}`, data)

export const sendInvites = ({ id, salesTeamInviteId }) =>
  POST<HTTPResponse>(`${BASE_URL(id)}/${salesTeamInviteId}/send_invites`)
