import { DELETE, GET, PUT } from 'utils/requests.utils'
import { Response, PaginatedResponse } from 'types/responses'

import { AffiliateMarketplaceProduct } from 'shared/components/affiliate-marketplace/types/types'

const BASE_URL = '/cabinet/affiliate_marketplace/products'

export const fetchList = (data) => GET<PaginatedResponse<AffiliateMarketplaceProduct>>(BASE_URL, data)

export const fetchItem = (id: string | number, data) =>
  GET<Response<AffiliateMarketplaceProduct>>(`${BASE_URL}/${id}`, data)

export const deleteMarketplaceProduct = (id) =>
  DELETE<PaginatedResponse<AffiliateMarketplaceProduct>>(`${BASE_URL}/${id}`)

export const deleteItem = (id: string | number) => DELETE<Response<AffiliateMarketplaceProduct>>(`${BASE_URL}/${id}`)

export const revokeItem = (id: string | number) =>
  PUT<Response<AffiliateMarketplaceProduct>>(`${BASE_URL}/${id}/revoke`)

export const unpublishItem = (id: string | number) =>
  PUT<Response<AffiliateMarketplaceProduct>>(`${BASE_URL}/${id}/un_publish`)
