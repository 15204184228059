import { useContext, useMemo } from 'react'
import { MobXProviderContext } from 'mobx-react'

import { CabinetRootStore } from '../stores/cabinetRoot.store'

export const getCabinetStores = () => new CabinetRootStore()

// Singleton - to avoid recreating adminRootStore on client side on client-side page transitions through Link/router.push
// https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props#when-does-getserversideprops-run
let cabinetRootStore

function initializeStore(): CabinetRootStore {
  const shouldCreateStores = typeof cabinetRootStore === 'undefined'

  if (shouldCreateStores) {
    cabinetRootStore = getCabinetStores()
  }

  return cabinetRootStore
}

export function useCabinetStore() {
  return useMemo(() => initializeStore(), [])
}

export const useCabinetStores = () => useContext(MobXProviderContext) as CabinetRootStore
