import { DELETE, GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/settings/payment_setting/mango_pay_legitimation'

const LEGAL_REPRESENTATIVE_URL = `${BASE_URL}/legal_representative`
const DOCUMENTS_URL = `${BASE_URL}/documents`
const UBO_URL = `${BASE_URL}/ubo`
const LEGITIMATE_URL = `${BASE_URL}/legitimate`

const WALLETS_URL = `${BASE_URL}/mango_pay_wallets`
const BANK_ACCOUNTS_URL = `${BASE_URL}/mango_pay_bank_accounts`
const MANGO_PAY_UBOS_URL = `${BASE_URL}/mango_pay_ubos`
const MANDATES_URL = `${BASE_URL}/mango_pay_mandates`

// BASE_URL
export const fetchItem = () => GET(`${BASE_URL}?expand[]=legitimation_documents`)
export const updateItem = (data) => PUT(BASE_URL, data)
export const createItem = (data) => POST(BASE_URL, data)

export const createLegalRepresentative = () => POST(LEGAL_REPRESENTATIVE_URL)
export const createDocuments = (data) => POST(DOCUMENTS_URL, data)
export const createUbo = (data) => POST(UBO_URL, data)
export const createLegitimate = (data) => POST(LEGITIMATE_URL, data)

// WALLETS_URL
export const fetchWalletsList = () => GET(WALLETS_URL)
export const createWallet = (data) => POST(WALLETS_URL, data)
export const fetchWallet = (id) => GET(`${WALLETS_URL}/${id}`)

// BANK_ACCOUNTS_URL
export const fetchBankAccountsList = () => GET(`${BANK_ACCOUNTS_URL}?per=1000`)
export const createBankAccount = (data) => POST(BANK_ACCOUNTS_URL, data)
export const fetchBankAccount = (id) => GET(`${BANK_ACCOUNTS_URL}/${id}`)
export const updateBankAccount = (id, data) => PUT(`${BANK_ACCOUNTS_URL}/${id}`, data)
export const deleteBankAccount = (id) => DELETE(`${BANK_ACCOUNTS_URL}/${id}`)

// MANGO_PAY_UBOS_URL
export const fetchMangopayUboList = () => GET(MANGO_PAY_UBOS_URL)
export const createMangopayUbo = (data) => POST(MANGO_PAY_UBOS_URL, data)
export const fetchMangopayUbo = (id) => GET(`${MANGO_PAY_UBOS_URL}/${id}`)
export const updateMangopayUbo = (id, data) => PUT(`${MANGO_PAY_UBOS_URL}/${id}`, data)
export const deleteMangopayUbo = (id) => DELETE(`${MANGO_PAY_UBOS_URL}/${id}`)

// MANDATES_URL
export const fetchMangopayMandatesList = () => GET(MANDATES_URL)
export const createMangopayMandate = (data) => POST(MANDATES_URL, data)
