import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/powerSellerDocuments.api'

export class PowerSellerDocumentsStore extends SharedStore<any> {
  storeName = 'PowerSellerDocumentsStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PowerSellerDocumentsStore()
