import { GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/seller_email_configuration'

export const fetchItem = () => GET(BASE_URL)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (data) => PUT(BASE_URL, data)

export const testEmail = (data) => PUT(`${BASE_URL}/test`, data)
