import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/additional_fees'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL}/${id}`, data)
