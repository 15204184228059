import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/setupIntents.api'

export class SetupIntents extends SharedStore {
  storeName = 'SetupIntents'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()

    makeObservable(this)

    this.loading = false
  }
}

export default new SetupIntents()
