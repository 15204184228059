import { action, makeObservable } from 'mobx'

import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/courseSessions.api'

export class CourseSessionsStore extends SharedStore {
  storeName = 'CourseSessionsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action cancelSubscription = async (id) => {
    this.loading = true

    const resp = await api.cancelItemSubscription(id)
    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.unsubscribeSuccess'))
      this.fetchList()
    }

    this.loading = false
  }

  @action continueSubscription = async (id) => {
    this.loading = true

    const resp = await api.continueItemSubscription(id)

    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.success'))
      this.fetchList()
    }

    this.loading = false
  }

  @action toggleBlock = async (id) => {
    this.loading = true

    const resp = await api.toggleBlock(id)
    if (resp.success) {
      notify(
        'success',
        resp.data.blocked
          ? I18n.t('react.cabinet.common.blockedSuccess')
          : I18n.t('react.cabinet.common.restoredSuccess')
      )
      this.fetchList()
    }

    this.loading = false
  }

  giveFullAccess = (data) => api.giveFullAccess(data)
  extendDuration = (data) => api.extendDuration(data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new CourseSessionsStore()
