import { action, observable, makeObservable } from 'mobx'
import { Nullable } from 'types/helpers'

interface Highlighter {
  id?: Nullable<string>
  blockId: Nullable<string | number>
  elements: Nullable<HTMLElement[]>
}

const DEFAULT_HIGHLIGHTER_OPTIONS: Highlighter = {
  id: null,
  blockId: null,
  elements: null,
}

export class HighlighterStore {
  @observable highlighter = DEFAULT_HIGHLIGHTER_OPTIONS
  @observable isActive = false

  @action setHighlighterSettings = ({ options, isActive }) => {
    this.highlighter = {
      ...this.highlighter,
      ...options,
    }
    this.isActive = isActive
  }

  @action resetHighlighterSettings = () => {
    this.highlighter = DEFAULT_HIGHLIGHTER_OPTIONS
    this.isActive = false
  }

  constructor() {
    makeObservable(this)
  }
}

export default new HighlighterStore()
