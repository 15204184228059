import { ApiClientV2Default } from 'utils/api-client/api-client.utils'

import { PlansStore } from 'shared/stores/plans.store'
import { UserProfilesStore } from 'shared/stores/userProfiles.store'
import { UserStore } from 'shared/stores/user.store'
import { ManagerStore } from 'shared/stores/manager.store'
import { BankAccountsStore } from 'shared/stores/bankAccounts.store'
import { PublisherStore } from 'shared/stores/publisher.store'
import { EloPublisherStore } from 'shared/stores/eloPublisher.store'
import { TeamMemberStore } from 'shared/stores/teamMember.store'
import { SalesTeamMemberStore } from 'shared/stores/salesTeamMember.store'
import { PayerStore } from 'shared/stores/payer.store'
import { OptionsStore } from 'shared/stores/options.store'
import { CountriesStore } from 'shared/stores/countries.store'
import { ContentBlockStore } from 'shared/components/page-builder/stores/contentBlock.store'
import { HighlighterStore } from 'shared/components/page-builder/stores/highlighter.store'
import { PageBuilderStore } from 'shared/components/page-builder/stores/pageBuilder.store'
import { PageBuilderModalsStore } from 'shared/components/page-builder/stores/pageBuilderModals.store'
import { NotificTypesStore } from 'shared/stores/notificTypes.store'
import { NotificTypesProfilesStore } from 'shared/stores/notificTypesProfiles.store'
import { CookiesConsentCategoriesStore } from 'shared/stores/cookiesConsentCategories.store'
import { IapProductsStore } from 'shared/stores/iapProducts.store'
import { MobileIAPStore } from 'shared/stores/mobileIAP.store'
import { OptionAdvertisementBlocksStore } from 'shared/stores/optionAdvertisementBlocks.store'
import { CommunitiesStore } from 'shared/stores/communities.store'
import { CommunityPostsStore } from 'shared/stores/communityPosts.store'
import { CommunityMemberStore } from 'shared/stores/communityMember.store'
import { CommunityLabelsStore } from 'shared/stores/communityLabels.store'
import { CommunitiesFeedPostsStore } from 'shared/stores/communitiesFeedPosts.store'
import { CommunitiesPostsModerationStore } from 'shared/stores/communitiesPostsModeration.store'
import { CommunityMembersStore } from 'shared/stores/communityMembers.store'
import { CommunitiesMembersStore } from 'shared/stores/communitiesMembers.store'
import { LiveShoppingStore } from 'shared/stores/liveShopping.store'
import { AudioPlayersStore } from 'shared/stores/audioPlayers.store'
import { DragAndDropModel } from 'shared/components/page-builder/dragAndDropModel'
import { VatUserRegistrationsStore } from 'shared/stores/vatUserRegistrations.store'
import { OrdersV2Store } from 'shared/stores/ordersV2.store'
import { PaymentCountriesStore } from 'shared/stores/paymentCountries.store'
import { AffiliateMarketplaceCategoriesCountsStore } from 'shared/stores/affiliateMarketplaceCategoriesCounts.store'

import { MobileAppDocumentsStore } from 'shop/stores/mobileAppDocuments.store'

import { SellerStore } from './seller.store'
import { CoversStore } from './covers.store'
import { SellersStore } from './sellers.store'
import { DigitalsStore } from './digitals.store'
import { GoodsStore } from './goods.store'
import { TicketsStore } from './tickets.store'
import { TicketDatesStore } from './ticketDates.store'
import { InvoicesStore } from './invoices.store'
import { QuizAttemptsStore } from './quizAttempts.store'
import { CreditMemosStore } from './creditMemos.store'
import { PaymentTicketsStore } from './paymentTickets.store'
import { SellableItemsStore } from './sellableItems.store'
import { CouponsStore } from './coupons.store'
import { ProductsStore } from './products.store'
import { LessonsStore } from './lessons.store'
import { TicketAttendeesStore } from './ticketAttendees.store'
import { QuizStore } from './quiz.store'
import { FunnelPagesStore } from './funnelPages.store'
import { OptInsStore } from './optIns.store'
import { PaymentPageTemplatesStore } from './paymentPageTemplates.store'
import { EmbeddableItemsStore } from './embeddableItems.store'
import { ProductGroupsStore } from './productGroups.store'
import { SubTransfersStore } from './subTransfers.store'
import { ContentPageStore } from './contentPage.store'
import { FunnelsStore } from './funnels.store'
import { AddonThemesStore } from './orderBumpsThemes.store'
import { FunnelNodesStore } from './funnelNodes.store'
import { TrackingCodesStore } from './trackingCodes.store'
import { WebhookEndpointsStore } from './webhookEndpoints.store'
import { AffiliateProgramsStore } from './affiliatePrograms.store'
import { PublishersStore } from './publishers.store'
import { AffiliateNodesStore } from './affiliateNodes.store'
import { AffiliateRedirectionsStore } from './affiliateRedirections.store'
import { MonthlyInvoicesStore } from './monthlyInvoices.store'
import { SyncEmailLogsStore } from './syncEmailLogs.store'
import { SyncContactLogsStore } from './syncContactLogs.store'
import { SyncWebhookLogsStore } from './syncWebhookLogs.store'
import { TrackingLogsStore } from './trackingLogs.store'
import { TeamMemberRolesStore } from './teamMemberRoles.store'
import { TeamMemberRolePermissionsStore } from './teamMemberRolePermissions.store'
import { TeamMemberInvitesStore } from './teamMemberInvites.store'
import { ThemePagesStore } from './themePages.store'
import { SellerSettingsStore } from './sellerSettings.store'
import { PayersStore } from './payers.store'
import { TransfersStore } from './transfers.store'
import { OrdersStore } from './orders.store'
import { CourseSessionsStore } from './courseSessions.store'
import { LessonStatusesStore } from './lessonStatuses.store'
import { OrderRatesStore } from './orderRates.store'
import { JointVenturesStore } from './jointVentures.store'
import { OptInQuestionsStore } from './optInQuestions.store'
import { CommentsStore } from './comments.store'
import { CourseViewsStore } from './courseViews.store'
import { PartiallyPaidStore } from './partiallyPaid.store'
import { BalanceStore as SellerBalanceStore } from './sellerBalance.store'
import { NotificationsStore } from './notifications.store'
import { AffiliateProgramsStatisticsStore } from './affiliateProgramsStatistics.store'
import { AffiliatePublishersStatisticsStore } from './affiliatePublishersStatistics.store'
import { AffiliateProductsStatisticsStore } from './affiliateProductsStatistics.store'
import { AffiliateStatisticsOverviewStore } from './affiliateStatisticsOverview.store'
import { ProductAddonsStore } from './productAddons.store'
import { CourseThemesStore } from './courseThemes.store'
import { ApiKeysStore } from './apiKeys.store'
import { InvoiceSummariesStore } from './invoiceSummaries.store'
import { PayoutsStore } from './payouts.store'
import { MembershipThemesStore } from './membershipThemes.store'
import { ShopThemesStore } from './shopThemes.store'
import { FiltersStore } from './filters.store'
import { AdditionalFeesStore } from './additionalFees.store'
import { DocumentsStore } from './documents.store'
import { SubscriptionsStore } from './subscriptions.store'
import { PricingPlansStore } from './pricingPlans.store'
import { FinancialReportsStore } from './financialReports.store'
import { PaymentSettingStore } from './paymentSetting.store'
import { LemonwayLegitimationStore } from './lemonwayLegitimation.store'
import { ResellerRequest as ResellerRequestStore } from './resellerRequest.store'
import { ProductUpsellsStore } from './productUpsells.store'
import { LicenseKeysStore } from './licenseKeys.store'
import { MangopayLegitimationStore } from './mangopayLegitimation.store'
import { InkassoLegitimationStore } from './inkassoLegitimation.store'
import { ProductSettingsStore } from './productSettings.store'
import { SetupIntents as SetupIntentsStore } from './setupIntents.store'
import { PrivacySettingsStore } from './privacySettings.store'
import { PrivacyTemplateStore } from './privacyTemplate.store'
import { AppThemesStore } from './appThemes.store'
import { BookingStore } from './booking.store'
import { CashoutsStore } from './cashouts.store'
import { CustomEmailsStore } from './customEmails.store'
import { CertificatesStore } from './certificates.store'
import { SellablesStore } from './sellables.store'
import { ContentBlockTemplatesStore } from './contentBlockTemplates.store'
import { ContentBlockSharedTemplatesStore } from './contentBlockSharedTemplates.store'
import { ContentPageTemplatesStore } from './contentPageTemplates.store'
import { ContentPageSharedTemplatesStore } from './contentPageSharedTemplates.store'
import { ProductAssignmentStore } from './productAssignment.store'
import { PayoutsTransfersStore } from './payoutsTransfers.store'
import { MembershipSessionsStore } from './membershipSessions.store'
import { IntegrationsStore } from './integrations.store'
import { EventsStore } from './events.store'
import { ProductsForSharingsStore } from './productsForSharings.store'
import { ContentPageCategoriesStore } from './contentPageCategories.store'
import { BonusPaymentsStore } from './bonusPayments.store'
import { ContentPageLogsStore } from './contentPageLogs.store'
import { CancellationTermsStore } from './cancellationTerms.store'
import { CookiesConsentsStore } from './cookiesConsents.store'
import { SharingsStore } from './sharings.store'
import { IapProductStore } from './iapProduct.store'
import { MobileAppStore } from './mobileApp.store'
import { EmailTemplateCategoriesStore } from './emailTemplateCategories.store'
import { FontsStore as SellerFontsStore } from './sellerFonts.store'
import { CurrenciesStore } from './currencies.store'
import { NeedActionTransfersStore } from './needActionTransfers.store'
import { SellerEmailConfigurationsStore } from './sellerEmailConfigurations.store'
import { CampaignStore } from './campaign.store'
import { DnsRecordsStore } from './dnsRecords.store'
import { CustomNotifications as CustomNotificationsStore } from './customNotifications.store'
import { PushNotificationStore } from './pushNotification.store'
import { DomainsStore } from './domains.store'
import { PayoutIntervalsStore } from './payoutIntervals.store'
import { CsvLogsStore } from './csvLogs.store'
import { TeamMemberRolePermissionCategoriesStore } from './teamMemberRolePermissionCategories.store'
import { AffiliateLandingsStore } from './affiliateLandings.store'
import { ApiConnectionsStore } from './apiConnections.store'
import { LeadsStore } from './contactFormLeads.store'
import { OptInAnswersStore } from './optInAnswers.store'
import { ElopageConnectStore } from './elopageConnect.store'
import { LiveShoppingEventsStore } from './liveShoppingEvents.store'
import { LiveShoppingOffersStore } from './liveShoppingOffers.store'
import { PayoutScheduleStore } from './payoutSchedule.store'
import { PayoutStatisticStore as PayoutsStatisticStore } from './payoutStatistic.store'
import { InternalNamesStore } from './internalNames.store'
import { ElopageIntegrationServiceStore } from './elopageIntegrationService.store'
import { ElopageIntegrationExecutionServiceStore } from './elopageIntegrationExecutionService.store'
import { LabelsStore } from './labels.store'
import { DesignTemplatesStore } from './designTemplates.store'
import { SalesTeamsStore } from './salesTeams.store'
import { DunningStore } from './dunning.store'
import { DunningFiltersStore } from './dunningFilters.store'
import { InkassoReportsStore } from './inkassoReports.store'
import { PayoutSettingsStore } from './payoutSettings.store'
import { VatSettingsStore } from './vatSettings.store'
import { PastDueOrdersStore } from './pastDueOrders.store'
import { PastDueOrdersFiltersStore } from './pastDueOrdersFilters.store'
import { MetabaseIntegrationStore } from './metabaseIntegration.store'
import { IncentivePlansStore } from './incentivePlans.store'
import { SalesTeamInviteBulksStore } from './salesTeamInviteBulks.store'
import { DealsStore } from './deals.store'
import { SalesTeamMemberInvitesStore } from './salesTeamMemberInvites.store'
import { SalesTeamMembersStore } from './salesTeamMembers.store'
import { TrustSealsStore } from './trustSeals.store'
import { PowerSellerDocumentsStore } from './powerSellerDocuments.store'
import { ProductIncentivesStore } from './productIncentives.store'
import { CountriesV2Store } from './countriesV2.store'
import { SalesTeamPayersStore } from './salesTeamPayers.store'
import { SalesTeamSettingsStore } from './salesTeamSettings.store'
import { TicketDateUpgradeMappingsStore } from './ticketDateUpgradeMappings.store'
import { ProfileDeletionStore } from './profileDeletion.store'
import { SharedTemplatesStore } from './sharedTemplates.store'
import { AffiliateMarketplaceProductsStore } from './affiliateMarketplaceProducts.store'
import { AffiliateMarketplaceStore } from './affiliateMarketplace.store'
import { AffiliateMarketplaceCategoriesStore } from './affiliateMarketplaceCategories.store'
import { ClearCacheStore } from './clearCache.store'
import { AffiliateMarketplaceProgramProductsStore } from './affiliateMarketplaceProgramProductsStore.store'
import { CabinetOrdersV2Store } from './cabinetOrdersV2.store'
import { AffiliateMarketplaceOffersStore } from './affiliateMarketplaceOffers.store'

export class CabinetRootStore {
  apiClient: ApiClientV2Default

  userStore: UserStore
  managerStore: ManagerStore
  sellerStore: SellerStore
  publisherStore: PublisherStore
  eloPublisherStore: EloPublisherStore
  teamMemberStore: TeamMemberStore
  payerStore: PayerStore
  salesTeamMemberStore: SalesTeamMemberStore
  paymentCountriesStore: PaymentCountriesStore

  highlighterStore: HighlighterStore
  pageBuilderStore: PageBuilderStore
  pageBuilderModalsStore: PageBuilderModalsStore
  notificTypesStore: NotificTypesStore
  notificTypesProfilesStore: NotificTypesProfilesStore
  communitiesStore: CommunitiesStore
  communityPostsStore: CommunityPostsStore
  communityMemberStore: CommunityMemberStore
  communityLabelsStore: CommunityLabelsStore
  communitiesFeedPostsStore: CommunitiesFeedPostsStore
  communitiesPostsModerationStore: CommunitiesPostsModerationStore
  communityMembersStore: CommunityMembersStore
  communitiesMembersStore: CommunitiesMembersStore
  liveShoppingStore: LiveShoppingStore
  audioPlayersStore: AudioPlayersStore
  mobileAppDocumentsStore: MobileAppDocumentsStore
  ticketAttendeesStore: TicketAttendeesStore
  quizStore: QuizStore
  funnelPagesStore: FunnelPagesStore
  optInsStore: OptInsStore
  paymentPageTemplatesStore: PaymentPageTemplatesStore
  embeddableItemsStore: EmbeddableItemsStore
  productGroupsStore: ProductGroupsStore
  subTransfersStore: SubTransfersStore
  jointVenturesStore: JointVenturesStore
  optInQuestionsStore: OptInQuestionsStore
  commentsStore: CommentsStore
  courseViewsStore: CourseViewsStore
  partiallyPaidStore: PartiallyPaidStore
  sellerBalanceStore: SellerBalanceStore
  notificationsStore: NotificationsStore
  affiliateProgramsStatisticsStore: AffiliateProgramsStatisticsStore
  affiliatePublishersStatisticsStore: AffiliatePublishersStatisticsStore
  affiliateProductsStatisticsStore: AffiliateProductsStatisticsStore
  affiliateStatisticsOverviewStore: AffiliateStatisticsOverviewStore
  productAddonsStore: ProductAddonsStore
  courseThemesStore: CourseThemesStore
  apiKeysStore: ApiKeysStore
  invoiceSummariesStore: InvoiceSummariesStore
  mangopayLegitimationStore: MangopayLegitimationStore
  inkassoLegitimationStore: InkassoLegitimationStore
  productSettingsStore: ProductSettingsStore
  setupIntentsStore: SetupIntentsStore
  privacySettingsStore: PrivacySettingsStore
  privacyTemplateStore: PrivacyTemplateStore
  appThemesStore: AppThemesStore
  bookingStore: BookingStore
  cashoutsStore: CashoutsStore
  customEmailsStore: CustomEmailsStore
  certificatesStore: CertificatesStore
  contentBlockTemplatesStore: ContentBlockTemplatesStore
  contentBlockSharedTemplatesStore: ContentBlockSharedTemplatesStore
  contentPageTemplatesStore: ContentPageTemplatesStore
  contentPageSharedTemplatesStore: ContentPageSharedTemplatesStore
  productAssignmentStore: ProductAssignmentStore
  payoutsTransfersStore: PayoutsTransfersStore
  membershipSessionsStore: MembershipSessionsStore
  integrationsStore: IntegrationsStore
  eventsStore: EventsStore
  productsForSharingsStore: ProductsForSharingsStore
  sellerEmailConfigurationsStore: SellerEmailConfigurationsStore
  campaignStore: CampaignStore
  dnsRecordsStore: DnsRecordsStore
  customNotificationsStore: CustomNotificationsStore
  pushNotificationStore: PushNotificationStore
  apiConnectionsStore: ApiConnectionsStore
  leadsStore: LeadsStore
  optInAnswersStore: OptInAnswersStore
  elopageConnectStore: ElopageConnectStore
  liveShoppingEventsStore: LiveShoppingEventsStore
  liveShoppingOffersStore: LiveShoppingOffersStore
  payoutScheduleStore: PayoutScheduleStore
  payoutsStatisticStore: PayoutsStatisticStore
  internalNamesStore: InternalNamesStore
  elopageIntegrationServiceStore: ElopageIntegrationServiceStore
  elopageIntegrationExecutionServiceStore: ElopageIntegrationExecutionServiceStore
  labelsStore: LabelsStore
  designTemplatesStore: DesignTemplatesStore
  salesTeamsStore: SalesTeamsStore
  openDunningStore: DunningStore
  resolvedDunningStore: DunningStore
  dunningFiltersStore: DunningFiltersStore
  inkassoReportsStore: InkassoReportsStore
  metabaseIntegrationStore: MetabaseIntegrationStore
  incentivePlansStore: IncentivePlansStore
  salesTeamInviteBulksStore: SalesTeamInviteBulksStore
  dealsStore: DealsStore
  salesTeamMemberInvitesStore: SalesTeamMemberInvitesStore
  salesTeamMembersStore: SalesTeamMembersStore
  trustSealsStore: TrustSealsStore
  powerSellerDocumentsStore: any
  productIncentivesStore: ProductIncentivesStore
  salesTeamSettingsStore: SalesTeamSettingsStore

  optionsStore: OptionsStore
  currenciesStore: CurrenciesStore
  countriesStore: CountriesStore
  vatUserRegistrationsStore: VatUserRegistrationsStore
  bankAccountsStore: BankAccountsStore
  userProfilesStore: UserProfilesStore
  coversStore: CoversStore
  goodsStore: GoodsStore
  filtersStore: FiltersStore
  productsStore: ProductsStore
  contentPageStore: ContentPageStore
  membershipThemesStore: MembershipThemesStore
  shopThemesStore: ShopThemesStore
  shopThemeStore: ShopThemesStore
  themePagesStore: ThemePagesStore
  emailTemplateCategoriesStore: EmailTemplateCategoriesStore
  needActionTransfersStore: NeedActionTransfersStore
  plansStore: PlansStore
  publishersStore: PublishersStore
  payersStore: PayersStore
  sellersStore: SellersStore
  syncEmailLogsStore: SyncEmailLogsStore
  affiliateRedirectionsStore: AffiliateRedirectionsStore
  sellerSettingsStore: SellerSettingsStore
  lemonwayLegitimationStore: LemonwayLegitimationStore
  courseSessionsStore: CourseSessionsStore
  ticketsStore: TicketsStore
  ticketDatesStore: TicketDatesStore
  sellablesStore: SellablesStore
  sellableItemsStore: SellableItemsStore
  transfersStore: TransfersStore
  ordersStore: OrdersStore
  invoicesStore: InvoicesStore
  payoutsStore: PayoutsStore
  payoutIntervalsStore: PayoutIntervalsStore
  monthlyInvoicesStore: MonthlyInvoicesStore
  financialReportsStore: FinancialReportsStore
  teamMemberRolePermissionsStore: TeamMemberRolePermissionsStore
  teamMemberRolePermissionCategoriesStore: TeamMemberRolePermissionCategoriesStore
  cookiesConsentsStore: CookiesConsentsStore
  webhookEndpointsStore: WebhookEndpointsStore
  cancellationTermsStore: CancellationTermsStore
  subscriptionsStore: SubscriptionsStore
  digitalsStore: DigitalsStore
  contentPageCategoriesStore: ContentPageCategoriesStore
  cookiesConsentCategoriesStore: CookiesConsentCategoriesStore
  iapProductStore: IapProductStore
  iapProductsStore: IapProductsStore
  mobileAppStore: MobileAppStore
  sharingsStore: SharingsStore
  csvLogsStore: CsvLogsStore
  optionAdvertisementBlocksStore: OptionAdvertisementBlocksStore
  addonThemesStore: AddonThemesStore
  productUpsellsStore: ProductUpsellsStore
  mobileIAPStore: MobileIAPStore
  affiliateLandingsStore: AffiliateLandingsStore
  payoutSettingsStore: PayoutSettingsStore
  additionalFeesStore: AdditionalFeesStore
  affiliateNodesStore: AffiliateNodesStore
  affiliateProgramsStore: AffiliateProgramsStore
  bonusPaymentsStore: BonusPaymentsStore
  contentPageLogsStore: ContentPageLogsStore
  couponsStore: CouponsStore
  creditMemosStore: CreditMemosStore
  documentsStore: DocumentsStore
  domainsStore: DomainsStore
  funnelNodesStore: FunnelNodesStore
  funnelsStore: FunnelsStore
  lessonStatusesStore: LessonStatusesStore
  lessonsStore: LessonsStore
  licenseKeysStore: LicenseKeysStore
  orderRatesStore: OrderRatesStore
  paymentTicketsStore: PaymentTicketsStore
  pricingPlansStore: PricingPlansStore
  quizAttemptsStore: QuizAttemptsStore
  syncContactLogsStore: SyncContactLogsStore
  syncWebhookLogsStore: SyncWebhookLogsStore
  teamMemberInvitesStore: TeamMemberInvitesStore
  teamMemberRolesStore: TeamMemberRolesStore
  trackingCodesStore: TrackingCodesStore
  trackingLogsStore: TrackingLogsStore
  vatSettingsStore: VatSettingsStore
  pastDueOrdersStore: PastDueOrdersStore
  pastDueOrdersFiltersStore: PastDueOrdersFiltersStore
  resellerRequestStore: ResellerRequestStore
  paymentSettingStore: PaymentSettingStore
  sellerFontsStore: SellerFontsStore
  contentBlockStore: ContentBlockStore
  cabinetNotificationsStore: NotificationsStore
  dndModel: DragAndDropModel
  salesTeamPayersStore: SalesTeamPayersStore
  productSessionStore: any
  themeStore: any
  trackingStore: any
  quizzesStore: any
  experimentsStore: any
  ticketDateUpgradeMappingsStore: TicketDateUpgradeMappingsStore
  profileDeletionStore: ProfileDeletionStore
  sharedTemplatesStore: SharedTemplatesStore
  affiliateMarketplaceProductsStore: AffiliateMarketplaceProductsStore
  affiliateMarketplaceStore: AffiliateMarketplaceStore
  affiliateMarketplaceCategoriesStore: AffiliateMarketplaceCategoriesStore
  affiliateMarketplaceProgramProductsStore: AffiliateMarketplaceProgramProductsStore
  cabinetOrdersV2Store: CabinetOrdersV2Store
  affiliateMarketplaceCategoriesCountsStore: AffiliateMarketplaceCategoriesCountsStore
  affiliateMarketplaceOffersStore: AffiliateMarketplaceOffersStore

  // V2 api stores
  countriesV2Store: CountriesV2Store
  ordersV2Store: OrdersV2Store
  clearCacheStore: ClearCacheStore

  constructor() {
    this.apiClient = new ApiClientV2Default()

    this.userStore = new UserStore()
    this.managerStore = new ManagerStore()
    this.sellerStore = new SellerStore(this)
    this.publisherStore = new PublisherStore()
    this.eloPublisherStore = new EloPublisherStore()
    this.teamMemberStore = new TeamMemberStore()
    this.payerStore = new PayerStore()
    this.salesTeamMemberStore = new SalesTeamMemberStore()

    this.optionsStore = new OptionsStore()
    this.currenciesStore = new CurrenciesStore(this)
    this.vatUserRegistrationsStore = new VatUserRegistrationsStore()

    this.bankAccountsStore = new BankAccountsStore()
    this.userProfilesStore = new UserProfilesStore()
    this.coversStore = new CoversStore()
    this.goodsStore = new GoodsStore()
    this.filtersStore = new FiltersStore()
    this.productsStore = new ProductsStore()
    this.contentPageStore = new ContentPageStore(this)
    this.membershipThemesStore = new MembershipThemesStore()
    this.shopThemesStore = new ShopThemesStore()
    this.shopThemeStore = this.shopThemesStore
    this.themePagesStore = new ThemePagesStore()
    this.emailTemplateCategoriesStore = new EmailTemplateCategoriesStore()
    this.needActionTransfersStore = new NeedActionTransfersStore()
    this.plansStore = new PlansStore()
    this.publishersStore = new PublishersStore()
    this.payersStore = new PayersStore()
    this.sellersStore = new SellersStore()
    this.syncEmailLogsStore = new SyncEmailLogsStore()
    this.affiliateRedirectionsStore = new AffiliateRedirectionsStore()
    this.sellerSettingsStore = new SellerSettingsStore(this)
    this.lemonwayLegitimationStore = new LemonwayLegitimationStore()
    this.courseSessionsStore = new CourseSessionsStore()
    this.ticketsStore = new TicketsStore(this)
    this.ticketDatesStore = new TicketDatesStore()
    this.sellablesStore = new SellablesStore()
    this.sellableItemsStore = new SellableItemsStore()
    this.transfersStore = new TransfersStore()
    this.ordersStore = new OrdersStore()
    this.invoicesStore = new InvoicesStore()
    this.payoutsStore = new PayoutsStore()
    this.payoutIntervalsStore = new PayoutIntervalsStore()
    this.monthlyInvoicesStore = new MonthlyInvoicesStore()
    this.financialReportsStore = new FinancialReportsStore()
    this.teamMemberRolePermissionsStore = new TeamMemberRolePermissionsStore()
    this.teamMemberRolePermissionCategoriesStore = new TeamMemberRolePermissionCategoriesStore()
    this.cookiesConsentsStore = new CookiesConsentsStore()
    this.webhookEndpointsStore = new WebhookEndpointsStore()
    this.cancellationTermsStore = new CancellationTermsStore()
    this.subscriptionsStore = new SubscriptionsStore()
    this.digitalsStore = new DigitalsStore(this)
    this.contentPageCategoriesStore = new ContentPageCategoriesStore()
    this.cookiesConsentCategoriesStore = new CookiesConsentCategoriesStore()
    this.iapProductStore = new IapProductStore()
    this.iapProductsStore = new IapProductsStore()
    this.mobileAppStore = new MobileAppStore()
    this.sharingsStore = new SharingsStore()
    this.csvLogsStore = new CsvLogsStore()
    this.optionAdvertisementBlocksStore = new OptionAdvertisementBlocksStore()
    this.addonThemesStore = new AddonThemesStore()
    this.productUpsellsStore = new ProductUpsellsStore()
    this.mobileIAPStore = new MobileIAPStore()
    this.affiliateLandingsStore = new AffiliateLandingsStore()
    this.payoutSettingsStore = new PayoutSettingsStore()
    this.additionalFeesStore = new AdditionalFeesStore()
    this.affiliateNodesStore = new AffiliateNodesStore()
    this.affiliateProgramsStore = new AffiliateProgramsStore()
    this.bonusPaymentsStore = new BonusPaymentsStore()
    this.contentPageLogsStore = new ContentPageLogsStore()
    this.couponsStore = new CouponsStore()
    this.creditMemosStore = new CreditMemosStore()
    this.documentsStore = new DocumentsStore()
    this.domainsStore = new DomainsStore()
    this.funnelNodesStore = new FunnelNodesStore()
    this.funnelsStore = new FunnelsStore()
    this.lessonStatusesStore = new LessonStatusesStore()
    this.licenseKeysStore = new LicenseKeysStore()
    this.orderRatesStore = new OrderRatesStore()
    this.paymentTicketsStore = new PaymentTicketsStore()
    this.pricingPlansStore = new PricingPlansStore()
    this.quizAttemptsStore = new QuizAttemptsStore()
    this.syncContactLogsStore = new SyncContactLogsStore()
    this.syncWebhookLogsStore = new SyncWebhookLogsStore()
    this.teamMemberInvitesStore = new TeamMemberInvitesStore()
    this.teamMemberRolesStore = new TeamMemberRolesStore()
    this.trackingCodesStore = new TrackingCodesStore()
    this.trackingLogsStore = new TrackingLogsStore()
    this.vatSettingsStore = new VatSettingsStore()
    this.pastDueOrdersStore = new PastDueOrdersStore()
    this.pastDueOrdersFiltersStore = new PastDueOrdersFiltersStore()
    this.resellerRequestStore = new ResellerRequestStore()
    this.paymentSettingStore = new PaymentSettingStore(this)
    this.sellerFontsStore = new SellerFontsStore()
    this.contentBlockStore = new ContentBlockStore(this)
    this.highlighterStore = new HighlighterStore()
    this.pageBuilderStore = new PageBuilderStore()
    this.pageBuilderModalsStore = new PageBuilderModalsStore()
    this.notificTypesStore = new NotificTypesStore()
    this.notificTypesProfilesStore = new NotificTypesProfilesStore()
    this.communitiesStore = new CommunitiesStore()
    this.communityPostsStore = new CommunityPostsStore()
    this.communityMemberStore = new CommunityMemberStore()
    this.communityLabelsStore = new CommunityLabelsStore()
    this.communitiesFeedPostsStore = new CommunitiesFeedPostsStore()
    this.communitiesPostsModerationStore = new CommunitiesPostsModerationStore()
    this.communityMembersStore = new CommunityMembersStore()
    this.communitiesMembersStore = new CommunitiesMembersStore()
    this.liveShoppingStore = new LiveShoppingStore()
    this.audioPlayersStore = new AudioPlayersStore()
    this.mobileAppDocumentsStore = new MobileAppDocumentsStore()
    this.ticketAttendeesStore = new TicketAttendeesStore()
    this.quizStore = new QuizStore(this)
    this.funnelPagesStore = new FunnelPagesStore()
    this.optInsStore = new OptInsStore()
    this.paymentPageTemplatesStore = new PaymentPageTemplatesStore()
    this.embeddableItemsStore = new EmbeddableItemsStore()
    this.productGroupsStore = new ProductGroupsStore()
    this.subTransfersStore = new SubTransfersStore()
    this.jointVenturesStore = new JointVenturesStore()
    this.optInQuestionsStore = new OptInQuestionsStore()
    this.commentsStore = new CommentsStore()
    this.courseViewsStore = new CourseViewsStore(this)
    this.partiallyPaidStore = new PartiallyPaidStore()
    this.sellerBalanceStore = new SellerBalanceStore()
    this.notificationsStore = new NotificationsStore()
    this.affiliateProgramsStatisticsStore = new AffiliateProgramsStatisticsStore()
    this.affiliatePublishersStatisticsStore = new AffiliatePublishersStatisticsStore()
    this.affiliateProductsStatisticsStore = new AffiliateProductsStatisticsStore()
    this.affiliateStatisticsOverviewStore = new AffiliateStatisticsOverviewStore()
    this.productAddonsStore = new ProductAddonsStore()
    this.courseThemesStore = new CourseThemesStore()
    this.apiKeysStore = new ApiKeysStore()
    this.invoiceSummariesStore = new InvoiceSummariesStore()
    this.mangopayLegitimationStore = new MangopayLegitimationStore()
    this.inkassoLegitimationStore = new InkassoLegitimationStore()
    this.productSettingsStore = new ProductSettingsStore()
    this.setupIntentsStore = new SetupIntentsStore()
    this.privacySettingsStore = new PrivacySettingsStore()
    this.privacyTemplateStore = new PrivacyTemplateStore()
    this.appThemesStore = new AppThemesStore()
    this.bookingStore = new BookingStore()
    this.cashoutsStore = new CashoutsStore()
    this.customEmailsStore = new CustomEmailsStore()
    this.certificatesStore = new CertificatesStore()
    this.contentBlockTemplatesStore = new ContentBlockTemplatesStore()
    this.contentBlockSharedTemplatesStore = new ContentBlockSharedTemplatesStore()
    this.contentPageTemplatesStore = new ContentPageTemplatesStore()
    this.contentPageSharedTemplatesStore = new ContentPageSharedTemplatesStore()
    this.productAssignmentStore = new ProductAssignmentStore()
    this.payoutsTransfersStore = new PayoutsTransfersStore()
    this.membershipSessionsStore = new MembershipSessionsStore()
    this.integrationsStore = new IntegrationsStore()
    this.eventsStore = new EventsStore()
    this.productsForSharingsStore = new ProductsForSharingsStore()
    this.sellerEmailConfigurationsStore = new SellerEmailConfigurationsStore()
    this.campaignStore = new CampaignStore()
    this.dnsRecordsStore = new DnsRecordsStore()
    this.customNotificationsStore = new CustomNotificationsStore()
    this.pushNotificationStore = new PushNotificationStore()
    this.apiConnectionsStore = new ApiConnectionsStore()
    this.leadsStore = new LeadsStore()
    this.optInAnswersStore = new OptInAnswersStore()
    this.elopageConnectStore = new ElopageConnectStore()
    this.liveShoppingEventsStore = new LiveShoppingEventsStore()
    this.liveShoppingOffersStore = new LiveShoppingOffersStore()
    this.payoutScheduleStore = new PayoutScheduleStore()
    this.payoutsStatisticStore = new PayoutsStatisticStore()
    this.internalNamesStore = new InternalNamesStore()
    this.elopageIntegrationServiceStore = new ElopageIntegrationServiceStore()
    this.elopageIntegrationExecutionServiceStore = new ElopageIntegrationExecutionServiceStore()
    this.labelsStore = new LabelsStore()
    this.designTemplatesStore = new DesignTemplatesStore()
    this.salesTeamsStore = new SalesTeamsStore()
    this.openDunningStore = new DunningStore('OpenDunningStore', { key: 'resolved', value: false })
    this.resolvedDunningStore = new DunningStore('ResolvedDunningStore', { key: 'resolved', value: true })
    this.inkassoReportsStore = new InkassoReportsStore()
    this.dunningFiltersStore = new DunningFiltersStore()
    this.metabaseIntegrationStore = new MetabaseIntegrationStore()
    this.incentivePlansStore = new IncentivePlansStore()
    this.salesTeamInviteBulksStore = new SalesTeamInviteBulksStore()
    this.dealsStore = new DealsStore()
    this.salesTeamMemberInvitesStore = new SalesTeamMemberInvitesStore()
    this.salesTeamMembersStore = new SalesTeamMembersStore()
    this.trustSealsStore = new TrustSealsStore()
    this.powerSellerDocumentsStore = new PowerSellerDocumentsStore()
    this.productIncentivesStore = new ProductIncentivesStore()
    this.countriesStore = new CountriesStore()
    this.cabinetNotificationsStore = new NotificationsStore()
    this.salesTeamPayersStore = new SalesTeamPayersStore()
    this.salesTeamSettingsStore = new SalesTeamSettingsStore()
    this.lessonsStore = new LessonsStore(this)
    this.ticketDateUpgradeMappingsStore = new TicketDateUpgradeMappingsStore()
    this.profileDeletionStore = new ProfileDeletionStore()
    this.sharedTemplatesStore = new SharedTemplatesStore()
    this.affiliateMarketplaceProductsStore = new AffiliateMarketplaceProductsStore()
    this.affiliateMarketplaceStore = new AffiliateMarketplaceStore()
    this.affiliateMarketplaceCategoriesStore = new AffiliateMarketplaceCategoriesStore()
    this.affiliateMarketplaceProgramProductsStore = new AffiliateMarketplaceProgramProductsStore()
    this.affiliateMarketplaceCategoriesCountsStore = new AffiliateMarketplaceCategoriesCountsStore()
    this.affiliateMarketplaceOffersStore = new AffiliateMarketplaceOffersStore()

    this.dndModel = new DragAndDropModel({
      contentPageStore: this.contentPageStore,
      contentBlockStore: this.contentBlockStore,
    })

    this.productSessionStore = {}
    this.themeStore = {}
    this.trackingStore = {}
    this.quizzesStore = {}
    this.experimentsStore = {
      useExperiment: () => ({
        get: () => null,
      }),
    }

    // V2 api stores
    this.countriesV2Store = new CountriesV2Store(this)
    this.ordersV2Store = new OrdersV2Store(this)
    this.paymentCountriesStore = new PaymentCountriesStore(this)
    this.clearCacheStore = new ClearCacheStore(this)
    this.cabinetOrdersV2Store = new CabinetOrdersV2Store()
  }
}
