import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/tickets'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchFullList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const duplicateItem = (id, data) => POST(`${BASE_URL}/${id}/duplicate`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL}/${id}`, data)

export const updatePositions = (data) => POST(`${BASE_URL}/positions`, data)

export const bulkApplyDesign = (data) => POST(`${BASE_URL}/bulk_apply_design`, data)
