import SharedStore from 'shared/stores/shared.store'
import { makeObservable } from 'mobx'
import * as api from '../api/dunning.api'

export class DunningFiltersStore extends SharedStore<api.Claim> {
  storeName = 'DunningFiltersStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new DunningFiltersStore()
