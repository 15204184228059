import { computed, makeObservable } from 'mobx'

import { RESELLER_REQUEST_FORMS, RESELLER_REQUEST_STATUSES } from 'constants/paymentSetting.constants'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/resellerRequest.api'

export class ResellerRequest extends SharedStore {
  storeName = 'ResellerRequest'
  childApi = api

  @computed get isStateEqualApproved() {
    return this.item.state === RESELLER_REQUEST_STATUSES.approved
  }

  @computed get isStateEqualCanceled() {
    return this.item.state === RESELLER_REQUEST_STATUSES.canceled
  }

  @computed get isStateEqualRejected() {
    return this.item.state === RESELLER_REQUEST_STATUSES.rejected
  }

  @computed get isStateEqualWaiting() {
    return this.item.state === RESELLER_REQUEST_STATUSES.waiting
  }

  @computed get isFormEqualOpen() {
    return this.item.form === RESELLER_REQUEST_FORMS.open
  }

  @computed get isFormEqualClose() {
    return this.item.form === RESELLER_REQUEST_FORMS.close
  }

  @computed get resellerModeDescription() {
    const { form, id, state } = this.item
    const powerSellerDescription = `description_${
      !!id &&
      ((this.isFormEqualOpen && this.isStateEqualApproved) || (this.isFormEqualClose && this.isStateEqualRejected))
        ? 'after'
        : 'before'
    }`
    const descriptionDependsOnState =
      !!id &&
      (((this.isFormEqualOpen || this.isFormEqualClose) && this.isStateEqualWaiting) ||
        (this.isFormEqualOpen && this.isStateEqualRejected))
    return `descriptions.${descriptionDependsOnState ? `${form}_${state}` : powerSellerDescription}`
  }

  cancelResellerRequest = () => this.updateItem(this.item.id, { state: RESELLER_REQUEST_STATUSES.canceled })

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new ResellerRequest()
