export const TEXTS = {
  templatesTab: {
    removedMessage: I18n.t('react.cabinet.notific.removed'),
    textFieldToggle: {
      checkedLabel: I18n.t('react.shared.blocks'),
      unCheckedLabel: I18n.t('react.shared.pages'),
    },
    loadModeButton: I18n.t('react.shared.load_more'),
    noDataMessage: I18n.t('react.shared.no_template'),
  },
  templatesModal: {
    openTemplatesButton: I18n.t('react.cabinet.open_templates'),
  },
}

export enum TABS_KEYS {
  custom = 'custom',
  templates = 'templates',
  saved = 'saved',
}

export const TABS = [
  {
    key: TABS_KEYS.custom,
    label: I18n.t('react.shared.custom'),
  },
  {
    key: TABS_KEYS.templates,
    label: I18n.t('react.shared.designs'),
  },
  {
    key: TABS_KEYS.saved,
    label: I18n.t('react.shared.saved'),
  },
]
