import { GET, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/payment_tickets'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const rebookItem = (id, data) => PUT(`${BASE_URL}/${id}/rebook`, data)
