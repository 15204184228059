import { action, observable, makeObservable } from 'mobx'

import { notify } from 'libs/common/notify'

import * as api from '../api/apiKeys.api'

export class ApiKeysStore {
  childApi = api
  @observable data = {
    api: '',
    zapier: '',
  }

  @action setData = (response) => {
    const list =
      response?.list || (response?.data && response?.data?.list) || response.map(({ data }) => data?.list).flat()

    list.forEach(({ form, ...data }) => {
      this.data = {
        ...this.data,
        [form]: data,
      }
    })
  }

  @action resetData = (key) => {
    this.data = {
      ...this.data,
      [key]: '',
    }
  }

  @action fetchList = (forms) =>
    (forms
      ? Promise.all(forms.map((form) => this.data.hasOwnProperty(form) && this.childApi.fetchList({ form })))
      : this.childApi.fetchList()
    ).then((response) => this.setData(response))

  @action fetchItem = (form) =>
    this.data.hasOwnProperty(form) && this.childApi.fetchList({ form }).then(({ data }) => this.setData(data))

  @action deleteItem = (id, form) => {
    const { deleteItem } = this.childApi

    return deleteItem(id).then(() => {
      if (!!form) {
        this.resetData(form)
        notify('success', I18n.t('react.cabinet.notific.removed'))
      }
    })
  }

  @action createItem = (data, id) => {
    const { createItem } = this.childApi

    createItem(data).then(() => {
      notify('success', id ? I18n.t('react.cabinet.notific.updated') : I18n.t('react.cabinet.notific.created'))
      return this.fetchItem(data.form)
    })
  }

  // temporary implementation, until there is no PUT implementation at back end
  @action updateItem = (id, data) => this.deleteItem(id).then(() => this.createItem(data, id))

  constructor() {
    makeObservable(this)
  }
}

export default new ApiKeysStore()
