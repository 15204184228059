import { GET, PUT, POST } from 'utils/requests.utils'
import { HTTPResponse } from 'types/responses'

const BASE_URL = '/cabinet/deals'

export const fetchList = ({ id, ...restData }) => GET<HTTPResponse>(BASE_URL, restData)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const changePaymentMethod = (id, data) => PUT(`${BASE_URL}/${id}/update_selected_pay_method`, data)

export const sendEmail = (id) => POST(`${BASE_URL}/${id}/send_payment_email`)

export const sendBindingOfferEmail = (id: number) => POST(`${BASE_URL}/${id}/send_binding_offer_email`)
