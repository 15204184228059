import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/productGroups.api'

export class ProductGroupsStore extends SharedStore {
  storeName = 'ProductGroupsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions
}

export default new ProductGroupsStore()
