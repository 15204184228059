import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/pricingPlans.api'

export class PricingPlansStore extends SharedStore {
  storeName = 'PricingPlansStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable isBulkUpdateModalOpen = false

  @action toggleBulkUpdateModal = () => {
    this.isBulkUpdateModalOpen = !this.isBulkUpdateModalOpen
  }

  addPayMethods = api.addPayMethods
  removePayMethods = api.removePayMethods

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PricingPlansStore()
