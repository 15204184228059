import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ProfileDeletion } from '../../shared/types/profile-deletion'

import * as api from '../api/profileDeletion.api'

export class ProfileDeletionStore extends SharedStore<ProfileDeletion> {
  storeName = 'ProfileDeletionStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }

  checkProfileDeletion = () => this.childApi.checkProfileDeletion()
}

export default new ProfileDeletionStore()
