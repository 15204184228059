import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/hubspot.api'

export class ElopageIntegrationServiceStore extends SharedStore<any> {
  storeName = 'ElopageIntegrationServiceStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise .then() to do after-actions

  activateConnection = this.childApi.activateConnection

  deactivateConnection = this.childApi.deactivateConnection
}

export default new ElopageIntegrationServiceStore()
