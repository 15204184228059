import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/needActionTransfers.api'

export class NeedActionTransfersStore extends SharedStore {
  storeName = 'NeedActionTransfersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions
  assignOrderRateToTransfer = async (id, data) => {
    this.toggleLoading(true)
    const resp = await api.assignOrderRateToTransfer(id, data)
    this.toggleLoading(false)
    return resp
  }
}

export default new NeedActionTransfersStore()
