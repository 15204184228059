import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/coupons.api'

export class CouponsStore extends SharedStore {
  storeName = 'CouponsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  sendCouponsToRemove = async (data, multiple = true) => {
    this.toggleLoading(true)

    const resp = await api.sendCouponsToRemove(data)
    multiple ? await this.fetchList() : await this.fetchItem(this.item.id)

    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.removed'))
      this.fetchList()
      return resp
    }
    this.toggleLoading(false)
  }
}

export default new CouponsStore()
