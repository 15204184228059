import { GET, DELETE, POST_WITH_PROGRESS, PATCH_WITH_PROGRESS, PUT, POST } from 'utils/requests.utils'

const BASE_URL = '/cabinet/digitals'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data, handleProgress) => POST_WITH_PROGRESS(BASE_URL, data, handleProgress)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const patchItemWithProcess = (id, data, handleProgress) =>
  PATCH_WITH_PROGRESS(`${BASE_URL}/${id}`, data, handleProgress)

export const sendVideosToRemove = (data) => POST(`${BASE_URL}/sent_to_remove`, data)

export const deleteVideoThumbnail = (id) => DELETE(`/v2${BASE_URL}/${id}/video_thumbnail`)
