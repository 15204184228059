import { GET, PATCH } from 'utils/requests.utils'
import { HTTPResponse } from 'types/responses'

const BASE_URL = ({ salesTeamId }) => `/cabinet/sales_teams/${salesTeamId}/sales_team_settings`

export interface SalesTeam {
  name: string
  description?: string
  showContactInfo?: boolean
  showCustomFields?: boolean
}

export const fetchItem = (salesTeamId, data) => GET<HTTPResponse>(BASE_URL({ salesTeamId }), data)

export const updateItem = (salesTeamId: number, data: SalesTeam) => PATCH<HTTPResponse>(BASE_URL({ salesTeamId }), data)
