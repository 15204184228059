import { action, computed, makeObservable, observable } from 'mobx'

import { isEmpty } from '@elo-kit/utils/validators.utils'
import SharedStore from 'shared/stores/shared.store'

import { ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES } from 'constants/paymentSetting.constants'

import * as api from '../api/elopageConnect.api'

export class ElopageConnectStore extends SharedStore {
  storeName = 'ElopageConnectStore'
  childApi = api
  @observable bankAccountsList = []
  @observable walletsList = []

  @action setBankAccountList = (bankAccountsList) => (this.bankAccountsList = bankAccountsList)

  @action setWalletsList = (wallets) => {
    this.walletsList = wallets
  }

  @computed get legitimationCreated() {
    return !isEmpty(this.item)
  }

  fetchBankAccountsList = async (data) => {
    const resp = await this.childApi.fetchBankAccountsList(data)
    const { success } = resp || {}

    if (success && resp.data?.list) {
      this.setBankAccountList(resp.data.list)
    }
  }

  fetchWalletsList = async () => {
    this.toggleLoading(true)

    const resp = await this.childApi.fetchWalletsList()

    if (resp?.success) {
      const { data } = resp
      this.setWalletsList(data?.list)
    }

    this.toggleLoading(false)
  }

  @computed get allowedToSell() {
    const { active, state } = this.item

    return (
      active &&
      (state === ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.payments ||
        state === ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES.paymentsPayouts)
    )
  }

  continueOnboarding = this.childApi.continueOnboarding
  createBankAccount = this.childApi.createBankAccount
  registerBankAccount = this.childApi.registerBankAccount
  fetchRequirements = this.childApi.fetchRequirements
  fetchSupportedCurrencies = this.childApi.fetchSupportedCurrencies

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new ElopageConnectStore()
