import { action, computed, makeObservable, observable, override } from 'mobx'
import moment from 'moment'

import { MAX_TICKET_QUANTITY_PER_PAYMENT, TICKET_DESIGN_DEFAULT_CONFIG } from 'constants/ticket-design-step.constants'
import { getObjectWithoutProperties } from 'utils/helpers.utils'

import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/tickets.api'

export class TicketsStore extends SharedStore {
  storeName = 'TicketsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  initTicket = {
    codePrefix: '',
    free: '',
    hasPassword: false,
    limit: '',
    limitPerOrder: '',
    locationAddress: '',
    locationShortName: '',
    minToBeBought: '',
    name: '',
    online: false,
    password: '',
    pricingPlans: [],
    ticketDates: [],
    destroyable: true,
    isNew: true,
  }

  initTicketDate = {
    uid: +new Date(),
    endDate: '',
    limit: '',
    startDate: '',
    destroyable: true,
    eventForm: 'with_dates',
    timeZoneName: moment.tz.guess(),
  }

  @observable showForm = false
  @observable selectedTicket = this.initTicket
  @observable selectedTicketDate = this.initTicketDate
  @observable upgradableTicketsList = []

  //methods used for multi-level tables
  @observable ticketsByProductId = {}
  @observable ticketsByProductIdLoading = {}

  @action fetchUpgradableTickets = async (data) => {
    this.toggleLoading(true)
    const { data: { list = [] } = {} } = await this.childApi.fetchFullList(data)
    this.upgradableTicketsList = this.filterExpiredTickets(list)
    this.toggleLoading(false)
  }

  @action fetchTicketsByProductId = (productId) => {
    this.ticketsByProductIdLoading = {
      ...this.ticketsByProductIdLoading,
      [productId]: true,
    }

    this.fetchFullList({ productId }).then(({ data }) => {
      this.ticketsByProductId = {
        ...this.ticketsByProductId,
        [productId]: data.list,
      }

      this.ticketsByProductIdLoading = {
        ...this.ticketsByProductIdLoading,
        [productId]: false,
      }
    })
  }
  //end methodth for multi-level tables

  @override handleSort(items) {
    const initialParams = {
      positions: {},
      productId: this.root.productsStore.item.id,
    }

    const paramsForSave = items.reduce(
      (result, { id }, index) => ({
        ...result,
        positions: {
          ...result.positions,
          [index]: {
            ticketId: id,
            position: index,
          },
        },
      }),
      initialParams
    )

    api.updatePositions(paramsForSave)
  }

  toggle = (state) => () => (this[state] = !this[state])

  @action toggleFormShow = this.toggle('showForm')
  @action setFormShow = (value) => (this.showForm = value)

  @action handleEdit = (ticket) => {
    this.selectedTicket = {
      ...ticket,
      hasPassword: !!ticket.password,
    }
    this.toggleFormShow()
  }

  @action handleEditTicketDate = (ticketDate) => {
    this.selectedTicketDate = ticketDate
    const list = this.selectedTicket.ticketDates.filter(
      (date) => (date.id || date.uid) != (ticketDate.id || ticketDate.uid)
    )
    this.selectedTicket = {
      ...this.selectedTicket,
      ticketDates: list,
    }
  }

  @action removeTicketDate = (id) => {
    const ticketDates = this.selectedTicket.ticketDates.filter((date) => (date.id || date.uid) != id)
    const toRemove = this.selectedTicket.ticketDates.filter((date) => (date.id || date.uid) == id)[0]
    if (toRemove.id) {
      this.selectedTicket = {
        ...this.selectedTicket,
        ticketDates: Object.assign(ticketDates, {
          [ticketDates.length]: {
            id: toRemove.id,
            _destroy: '1',
          },
        }),
      }
    } else {
      this.selectedTicket = {
        ...this.selectedTicket,
        ticketDates,
      }
    }
  }

  @action handleTicketDateNew = () => {
    this.selectedTicketDate = this.initTicketDate
  }

  @action handleTicketDateCancel = (dates) => {
    this.selectedTicket = {
      ...this.selectedTicket,
      ticketDates: dates,
    }
  }

  @action handleDateChange = (field, value) => {
    this.selectedTicketDate = {
      ...this.selectedTicketDate,
      [field]: value,
    }
  }

  @action handleTicketDateSave = () => {
    const { ticketDates } = this.selectedTicket
    const newDates = ticketDates

    newDates[ticketDates.length] = Object.assign(this.selectedTicketDate, { uid: +new Date() })

    this.selectedTicket = {
      ...this.selectedTicket,
      ticketDates: newDates,
    }
    this.selectedTicketDate = this.initTicketDate
  }

  @action handleInputChange = (field, value) => {
    this.selectedTicket = {
      ...this.selectedTicket,
      [field]: value,
    }
  }

  @action removeTicket = async (id) => {
    const { success } = await api.deleteItem(id, { productId: this.root.productsStore.item.id })

    success && this.fetchList()
  }

  @action updateTicketPlans = ({ pricingPlans }) => {
    this.selectedTicket = {
      ...this.selectedTicket,
      pricingPlans,
    }
  }

  @action saveTicket = async () => {
    const { id } = this.selectedTicket
    let resp = {}

    if (!!id) {
      resp = await api.updateItem(id, this.prepareTicketForSave)
    } else {
      resp = await api.createItem(this.prepareTicketForSave)
    }
    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))

      await this.fetchList()
      this.toggleFormShow()
    }
  }

  @action bulkApplyDesign = async (data) => {
    const resp = await api.bulkApplyDesign(data)
    if (resp?.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
      await this.fetchList({
        productId: this.root.productsStore.item.id,
        expand: ['ticket_dates', 'location', 'cover'],
      })
    }
    return resp
  }

  @action resetSelectedTicket = () => (this.selectedTicket = this.initTicket)

  @computed get prepareTicketForSave() {
    const {
      codePrefix,
      free,
      hasPassword,
      id,
      name = '',
      locationShortName = '',
      locationAddress,
      limitPerOrder,
      limit,
      minToBeBought,
      online,
      password,
      countryCode,
      designConfig,
      limitVisibilityType,
      showLimit,
      hideQrCode,
    } = this.selectedTicket

    const defaultDesignConfig = getObjectWithoutProperties(
      TICKET_DESIGN_DEFAULT_CONFIG.reduce(
        (accumulator, currentValue) => ({
          ...currentValue,
          ...accumulator,
        }),
        {}
      ),
      ['id']
    )

    return {
      id,
      free,
      limit,
      online,
      name,
      locationShortName,
      locationAddress,
      countryCode,
      codePrefix,
      productId: this.root.productsStore.item.id,
      minToBeBought: minToBeBought || '',
      password: hasPassword ? password : '',
      limitPerOrder: limitPerOrder || MAX_TICKET_QUANTITY_PER_PAYMENT,
      ticketDatesAttributes: this.prepareTicketDatesForSave,
      pricesAttributes: this.preparePricesForSave,
      designConfig: designConfig || defaultDesignConfig,
      showLimit: !!showLimit,
      hideQrCode: !!hideQrCode,
      ...(limitVisibilityType && { limitVisibilityType }),
    }
  }

  @computed get preparePricesForSave() {
    return this.selectedTicket.pricingPlans.map(({ id, priceId, position, _destroy, recommended }) => ({
      position,
      _destroy,
      id: priceId,
      pricingPlanId: id,
      recommended,
    }))
  }

  @action selectRecommendedPricingPlan = (planId) => {
    const pricingPlans = this.selectedTicket.pricingPlans.map((val) => ({
      ...val,
      recommended: val.recommended ? false : val.id === planId,
    }))
    this.selectedTicket = {
      ...this.selectedTicket,
      pricingPlans: pricingPlans,
    }
  }

  filterExpiredTickets = (list = []) =>
    list
      .filter((ticket) => !ticket.ticketDates.every((ticketDate) => ticketDate.expired))
      .map((ticket) => ({
        ...ticket,
        ticketDates: ticket.ticketDates.filter((ticketDate) => !ticketDate.expired),
      }))

  @computed get notExpiredTicketsList() {
    return this.filterExpiredTickets(this.list)
  }

  @computed get currentProductTicketsList() {
    return this.filterExpiredTickets(
      this.list.filter((item) => String(item.productId) === String(this.root.productsStore.item.id))
    )
  }

  @computed get prepareTicketDatesForSave() {
    return this.selectedTicket.ticketDates.map((item) => {
      const {
        additionalText,
        saleAfterStartDate,
        endDate,
        eventForm,
        timeZoneName,
        id,
        limit = '',
        startDate,
        _destroy,
      } = item

      return {
        additionalText,
        eventForm,
        _destroy,
        saleAfterStartDate,
        id,
        limit,
        endDate,
        startDate,
        timeZoneName,
      }
    })
  }

  constructor(rootStore) {
    super()

    this.root = rootStore
    makeObservable(this)

    this.loading = false
  }
}

export default new TicketsStore()
