import { GET, POST, PUT, PATCH, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/domain'
const SUBDOMAIN_BASE_URL = '/cabinet/subdomain'

export const fetchDomain = () => GET(BASE_URL)

export const createDomain = (data) => POST(BASE_URL, data)

export const updateDomain = (data) => PATCH(BASE_URL, data)

export const removeDomain = () => DELETE(BASE_URL)

export const fetchSubDomain = (data) => GET(SUBDOMAIN_BASE_URL, data)

export const createSubDomain = (data) => POST(SUBDOMAIN_BASE_URL, data)

export const activateSubDomain = (data) => PUT(SUBDOMAIN_BASE_URL, data)

export const deactivateSubDomain = () => DELETE(SUBDOMAIN_BASE_URL)
