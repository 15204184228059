import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/incentivePlans.api'

export class IncentivePlansStore extends SharedStore {
  storeName = 'IncentivePlansStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
}

export default new IncentivePlansStore()
