import { action, computed, makeObservable, observable, override } from 'mobx'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { STATUSES } from '@elo-kit/constants/status-text.constants'

import { getWalletState, splitStreet } from 'utils/paymentSetting.utils'
import {
  MANGOPAY_EXTERNAL_STATUSES,
  MANGOPAY_INTERNAL_STATUSES,
  UBO_DECLARATION_STATUSES,
} from 'constants/paymentSetting.constants'
import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/mangoPayLegitimation.api'

export class MangopayLegitimationStore extends SharedStore {
  storeName = 'MangopayLegitimationStore'
  childApi = api

  @observable uboId = null
  @observable uboState = ''
  @observable uboList = []
  @observable bankAccountsList = []
  @observable walletsList = []

  @override
  setItem({ data } = {}) {
    if (data) this.item = data
  }

  setStateValue =
    (state) =>
    (value = '') =>
      (this[state] = value)

  @action setBankAccountList = this.setStateValue('bankAccountsList')
  @action setUboId = this.setStateValue('uboId')
  @action setUboState = this.setStateValue('uboState')

  @computed get hasBankAccounts() {
    return !!this.bankAccountsList.length
  }

  @computed get shareholdersAmount() {
    return this.uboList.length
  }

  @computed get legitimationCreated() {
    return !isEmpty(this.item)
  }

  @computed get readyForLegitimation() {
    const { internalState } = this.item
    return (
      !!internalState &&
      internalState !== MANGOPAY_INTERNAL_STATUSES.headQuarter &&
      internalState !== MANGOPAY_INTERNAL_STATUSES.legalRepresentative
    )
  }

  @computed get readyForBusinessLegitimation() {
    return this.readyForLegitimation && this.item.internalState !== MANGOPAY_INTERNAL_STATUSES.documents
  }

  @computed get legitimationRequested() {
    return this.readyForBusinessLegitimation && this.item.internalState !== MANGOPAY_INTERNAL_STATUSES.ubo
  }

  @computed get legitimationReviewed() {
    return this.legitimationRequested && this.item.internalState !== MANGOPAY_INTERNAL_STATUSES.requested
  }

  @computed get disableEdit() {
    const { active, internalState, state } = this.item
    return (
      !active ||
      internalState === MANGOPAY_INTERNAL_STATUSES.inReview ||
      internalState === MANGOPAY_INTERNAL_STATUSES.registered ||
      internalState === MANGOPAY_INTERNAL_STATUSES.legitimated ||
      state === MANGOPAY_EXTERNAL_STATUSES.regular
    )
  }

  @computed get allowedToSell() {
    const { active, internalState, state } = this.item
    return (
      active &&
      (internalState === MANGOPAY_INTERNAL_STATUSES.legitimated ||
        state === MANGOPAY_EXTERNAL_STATUSES.light ||
        state === MANGOPAY_EXTERNAL_STATUSES.regular)
    )
  }

  @computed get walletState() {
    const { active, internalState, state } = this.item
    return !this.legitimationCreated || active
      ? getWalletState({
          internalState,
          state,
        })
      : STATUSES.deactivated
  }

  @computed get invalidUbo() {
    return (
      !this.uboState ||
      this.uboState === UBO_DECLARATION_STATUSES.refused ||
      this.uboState === UBO_DECLARATION_STATUSES.validationAsked ||
      this.uboState === UBO_DECLARATION_STATUSES.incomplete
    )
  }

  @action setUboList = (response) => {
    const { data: { list } = {}, success } = response
    if (list && success) {
      const {
        data: { ubo = [] } = { ubo: [] },
        id,
        state = UBO_DECLARATION_STATUSES.refused,
      } = list.find(({ state }) => state !== UBO_DECLARATION_STATUSES.refused) || {}

      this.setUboId(id)
      this.setUboState(state)
      this.uboList = ubo.map(({ address, birthplace, ...rest }) => {
        const { city, region, street, zip, country: countryCode } = address
        const { city: birthplaceCity, country: birthplaceCountry } = birthplace
        const { streetName, streetNumber } = splitStreet(street)
        return {
          ...rest,
          city,
          countryCode,
          birthplaceCity,
          birthplaceCountry,
          region,
          street: streetName,
          streetNumber,
          zip,
        }
      })
    }
  }

  @action setWalletsList = ({ data, success } = {}) => {
    if (data && success) this.walletsList = data.list
  }

  fetchBankAccountsList = async () => {
    const response = await this.childApi.fetchBankAccountsList()
    const { success, data = {} } = response || {}
    const { list } = data
    if (success && list) {
      this.setBankAccountList(list)
    }
  }

  mangopayHandler = (name) => async (data) => {
    const response = await this.childApi[name](data)
    this.setItem(response)
  }

  createBankAccount = this.childApi.createBankAccount

  createLegalRepresentative = this.mangopayHandler('createLegalRepresentative')
  createDocuments = this.mangopayHandler('createDocuments')
  createUbo = this.mangopayHandler('createUbo')
  createLegitimate = this.mangopayHandler('createLegitimate')

  fetchMangopayUboList = async () => {
    const response = await this.childApi.fetchMangopayUboList()
    this.setUboList(response)
  }

  createWallet = async (currencyId) => {
    const resp = await this.childApi.createWallet({ currencyId })
    this.fetchWalletsList()
    return resp
  }

  fetchWalletsList = async () => {
    const response = await this.childApi.fetchWalletsList()
    this.setWalletsList(response)
  }

  createMangopayUbo = (data) => this.childApi.createMangopayUbo(data)
  updateMangopayUbo = (data) => this.childApi.updateMangopayUbo(this.uboId, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new MangopayLegitimationStore()
