import SharedStore from 'shared/stores/shared.store'
import { makeObservable, observable, override } from 'mobx'
import * as api from '../api/salesTeamMembers.api'

export class SalesTeamMembersStore extends SharedStore {
  storeName = 'SalesTeamMembersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()
    makeObservable(this)
  }

  @observable currentTotalCount = null

  @override async fetchList(data) {
    const resp = await super.fetchList(data)
    if (resp.success) {
      this.currentTotalCount = resp.data?.totalCount
    }
    return resp
  }
}

export default new SalesTeamMembersStore()
