import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/monthlyInvoices.api'

export class MonthlyInvoicesStore extends SharedStore {
  storeName = 'MonthlyInvoicesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action getData = (data) => api.getData(data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new MonthlyInvoicesStore()
