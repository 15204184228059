import { GET, POST, PATCH, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/coupons'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PATCH(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const sendCouponsToRemove = (data) => POST(`${BASE_URL}/sent_to_remove`, data)
