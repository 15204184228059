import { GET, PUT, POST, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/integrations'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const duplicateItem = (id) => POST(`${BASE_URL}/${id}/duplicate`)
