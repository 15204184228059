import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable, action, makeObservable } from 'mobx'

import Select, { components } from 'react-select'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { I18nContext } from '@elo-kit/components/i18n/i18n'
import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'

import { isString } from 'utils/validators.utils'

import DropdownIndicator from '@elo-kit/components/form/select/SelectDropdownIndicator'
import ClearIndicator from '@elo-kit/components/form/select/SelectClearIndicator'
import { addTestId } from 'utils/e2e.utils'

const propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ]),
  }),
  form: PropTypes.shape({}).isRequired,
  customOnChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  required: PropTypes.bool,
  useObjectValue: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  testId: PropTypes.string,
}

const defaultProps = {
  useObjectValue: false,
  disabled: false,
  searchable: false,
  isClearable: false,
  options: [],
}

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
)

@observer
class FSelectField extends Component {
  static contextType = I18nContext

  @observable showIsRequiredMessage = false
  @observable selectedOption = null

  @action onFieldChange = (option) => {
    const { field, form, required, customOnChange, useObjectValue } = this.props

    if (required) {
      this.showIsRequiredMessage = false
    }

    if (useObjectValue) {
      form.setFieldValue(field.name, option)
      customOnChange && customOnChange(option)
    } else {
      this.selectedOption = option
      form.setFieldValue(field.name, option.value)
      customOnChange && customOnChange(option.value)
    }
  }

  @action onBlur = (event) => {
    const { field, required, onBlur = () => {} } = this.props

    if (!field.value && required) {
      this.showIsRequiredMessage = true
    }

    if (field.onBlur) {
      field.onBlur(event)
    }

    onBlur()
  }

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  get errorMessage() {
    const {
      field: { name },
      form: { errors },
    } = this.props
    const I18n = this.context

    if (errors && name && errors[name]) {
      return errors[name]
    }

    return I18n.t('react.shared.validations.required')
  }

  get shouldShowErrorMessage() {
    const {
      field: { name: fieldName },
      form: { errors, touched },
    } = this.props

    if (errors && fieldName && errors[fieldName] && touched[fieldName]) return true

    return this.showIsRequiredMessage
  }

  render() {
    const I18n = this.context
    const {
      className,
      classNamePrefix,
      isClearable,
      containerClasses,
      defaultValue,
      disabled,
      field,
      label,
      useObjectValue,
      options,
      placeholder = I18n.t('react.shared.select.placeholder'),
      searchable,
      tooltipTitle,
      required,
      tooltipId,
      id,
      testId,
    } = this.props

    // Use saved selectedOption to avoid find on every change
    let selectValue = this.selectedOption && !useObjectValue ? this.selectedOption : field.value
    let selectDefaultValue = defaultValue

    // Set option object as value
    if (isString(selectValue)) {
      selectValue = options.find(({ value }) => value === selectValue)
    }

    // Set option object as default value
    if (selectDefaultValue && isString(selectDefaultValue)) {
      selectDefaultValue = options.find(({ value }) => value === defaultValue)
    }

    const fieldContainerClassNames = classNames('field elo-select-container', containerClasses, {
      'field--disabled': disabled,
      'field--required': required,
      'field--error': this.shouldShowErrorMessage,
    })

    const fieldClassNames = classNames('elo-select-field', className, {
      searchable,
      error: this.shouldShowErrorMessage,
    })

    const fieldClassNamePrefix = classNames(classNamePrefix || 'elo-select-field')

    const tooltipTitles = {
      select_product: I18n.t('react.cabinet.help_icon.select_product.title'),
      assign_publisher: I18n.t('react.cabinet.help_icon.assign_publisher.title'),
    }

    const tooltipContent = {
      smtp_authentication: I18n.t('react.cabinet.help_icon.smtp_authentication.content'),
      select_product: I18n.t('react.cabinet.help_icon.select_product.content'),
      select_coupon: I18n.t('react.cabinet.help_icon.select_coupon.content'),
      assign_publisher: I18n.t('react.cabinet.help_icon.assign_publisher.content'),
      buyer_list_id: I18n.t('react.cabinet.help_icon.buyer_list_id.content'),
      buyer_campaign_id: I18n.t('react.cabinet.help_icon.buyer_campaign_id.content'),
      mailing_work: I18n.t('react.cabinet.help_icon.mailing_work.content'),
      body: I18n.t('react.cabinet.help_icon.body.content'),
      tracify_cs_id: I18n.t('react.cabinet.help_icon.tracify_cs_id.content'),
      tracify_api_key: I18n.t('react.cabinet.help_icon.tracify_api_key.content'),
      tracify_is_staging: I18n.t('react.cabinet.help_icon.tracify_is_staging.content'),
    }
    return (
      <div className={fieldContainerClassNames} {...addTestId(testId ?? field.name)}>
        {label && (
          <label className='field__label' htmlFor={field.name}>
            <span>{label}</span>
            {tooltipId && (
              <InfoTooltip
                id={`${field.name}${id ? `_${id}` : ''}_popover`}
                title={tooltipTitle ? tooltipTitles[tooltipId] : ''}
                body={tooltipContent[tooltipId]}
              />
            )}
          </label>
        )}

        {this.shouldShowErrorMessage && (
          <div className='field__error'>
            {this.errorMessage}
            <i className='fas fa-exclamation-circle' />
          </div>
        )}

        <Select
          inputId={field.name}
          placeholder={placeholder}
          options={options}
          onChange={this.onFieldChange}
          value={selectValue}
          valueKey='tags'
          classNamePrefix={fieldClassNamePrefix}
          className={fieldClassNames}
          isDisabled={disabled}
          isSearchable={searchable}
          isClearable={isClearable}
          defaultValue={selectDefaultValue}
          styles={{
            valueContainer: (base) => ({
              ...base,
              height: 44,
            }),
          }}
          components={{
            ClearIndicator,
            DropdownIndicator,
            ValueContainer,
          }}
          menuPlacement='auto'
          onBlur={this.onBlur}
          noOptionsMessage={() => I18n.t('shared.common.no_options')}
        />
      </div>
    )
  }
}

FSelectField.displayName = 'FSelectField'
FSelectField.propTypes = propTypes
FSelectField.defaultProps = defaultProps

export default FSelectField
