import { action, makeObservable, observable, override, toJS } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/hubspot.api'

export class ElopageIntegrationExecutionServiceStore extends SharedStore<any> {
  storeName = 'ElopageIntegrationExecutionServiceStore'
  childApi = api
  @observable integrationId = null

  constructor() {
    super()

    makeObservable(this)
  }

  @override
  async fetchList(data = {}) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchExecutions(this.integrationId, {
      expand: toJS(this.expands),
      counts: toJS(this.counts),
      ...this.getParamsWoRelativeData(this.queryParams),
      ...this.getFormattedRelativeData(),
      ...data,
    })

    if (resp.success) {
      const { list = [], totalCount = 0 } = resp.data || {}

      this.setList(list)
      this.updatePagination({ total: totalCount })
    }

    this.toggleLoading(false)
    return resp
  }

  @action
  setIntegrationId = (value) => {
    this.integrationId = value
  }

  retryExecution = (id: number, integrationId: number) => this.childApi.retryExecution(id, integrationId)
}

export default new ElopageIntegrationExecutionServiceStore()
