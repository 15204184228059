import { GET, POST, PUT, DELETE } from 'utils/requests.utils'
import { HTTPResponse, PaginatedResponse, Response } from 'types/responses'
import { OrderRate } from 'types/order-rate'

const BASE_URL = '/cabinet/order_rates'

export const fetchList = (data) => GET<PaginatedResponse<OrderRate[]>>(BASE_URL, data)

export const fetchItem = (id: number | string, data) => GET<Response<OrderRate>>(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST<HTTPResponse>(BASE_URL, data)

export const updateItem = (id: number | string, data) => PUT<HTTPResponse>(`${BASE_URL}/${id}`, data)

export const deleteItem = (id: number | string, data) => DELETE<HTTPResponse>(`${BASE_URL}/${id}`, data)

export const refundItem = (id: number | string, data) => POST<HTTPResponse>(`${BASE_URL}/${id}/refund`, data)
