import { computed, makeObservable, override, observable, action } from 'mobx'

import { Nullable, SelectOption } from 'types/helpers'

import {
  filterBySublist,
  formatCountryListOptions,
  formatCountryListOptionsForSignUp,
  formatStateListOptions,
  isEqualCH,
} from 'utils/countries.utils'

import SharedStore from 'shared/stores/shared.store'

import { EVENT_COUNTRIES } from 'constants/countries.constants'

import { CountriesApi, createCountriesApi, Country, State } from '../api/countries.api'

const defaultCountry: Country = {
  id: 'DE',
  name: 'Germany',
  alpha2: 'DE',
  euMember: true,
  region: '',
}

const defaultState: State = {
  id: '',
  name: '',
  alpha2: '',
}

type CountryOption = SelectOption<Country>
type StateOption = SelectOption<State>

export class CountriesV2Store extends SharedStore<Country> {
  storeName = 'CountriesStore'
  declare childApi: CountriesApi
  private memoCountry: Nullable<Country> = null

  constructor(root) {
    super()
    this.childApi = createCountriesApi(root.apiClient)
    makeObservable(this)
  }

  @observable states = new Map<string, State[]>()

  @action
  async fetchStates(id: string): Promise<void> {
    if (!this.states.has(id)) {
      const { data } = await this.childApi.fetchStates(id)
      this.states.set(id, data)
    }
  }

  @override
  async fetchList(): Promise<void> {
    if (this.list?.length) {
      return
    }
    const { data } = await this.childApi.fetchList()
    this.list = data
  }

  @computed get euCountries() {
    return this.list.filter(({ euMember, alpha2 }) => euMember || isEqualCH(alpha2))
  }

  @computed get withoutChEuCountries() {
    return this.list.filter(({ euMember }) => euMember)
  }

  @computed get withChNonEuCountries() {
    return this.list.filter(({ euMember }) => !euMember)
  }

  @computed get nonEuCountries() {
    return this.list.filter(({ euMember, alpha2 }) => !euMember && !isEqualCH(alpha2))
  }

  @computed get countrySelectOptionsForSignUp() {
    return formatCountryListOptionsForSignUp(this.list)
  }

  @computed get countrySelectOptions() {
    return formatCountryListOptions(this.list)
  }

  @computed get alpha2CountryMap() {
    return this.list.reduce((acc, country) => {
      acc[country.alpha2] = country
      return acc
    }, {})
  }

  eventCountries(additionalCountries = []) {
    return filterBySublist(this.list, [...additionalCountries, ...EVENT_COUNTRIES])
  }

  getStates = (countryKey: string): State[] => this.states.get(countryKey) ?? ([] as State[])

  getStatesSelectOptions = (countryKey: string): StateOption[] => {
    const states = this.getStates(countryKey)

    return formatStateListOptions(states)
  }

  getState = (countryKey: string, stateKey: string): State => {
    const states = this.getStates(countryKey)
    const state = states.find((state) => state.id === stateKey)

    return state ?? defaultState
  }

  getStateSelectOptions = (countryKey: string, stateKay: string): StateOption => {
    const state = this.getState(countryKey, stateKay)

    return {
      label: state.name,
      value: state.id,
      ...state,
    }
  }

  getCountry = (id: string): Country => {
    if (this.memoCountry?.id === id) {
      return this.memoCountry
    }

    const country = this.list.find((country) => country.id === id)
    this.memoCountry = country

    if (!country) {
      console.error(`Country with id ${id} not found`)
    }

    return country ?? defaultCountry
  }

  getCountrySelectOption = (id: string): CountryOption => {
    const country = this.getCountry(id)

    return {
      label: country.name,
      value: country.alpha2,
      ...country,
    }
  }
}
