import { GET } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = '/cabinet/sales_team_deals_payers'

export interface SalesTeamPayersList {
  avatar: string
  bank_accountId: number
  createdAt: string
  firstPurchaseDate: string
  id: number
  mergeToken: string
  ordersCount: number
  updatedAt: string
  userId: number
  userProfileId: number
}

export const fetchList = (data) => GET<Response<SalesTeamPayersList>>(BASE_URL, data)
