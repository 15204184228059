export const QUIZ_EXPANDS = ['quiz_questions', 'quiz_choices']

export const QUIZ_ATTEMPTS_EXPANDS = [
  'product.covers',
  'payer.user_profile',
  'payer.user',
  'quiz_answers.quiz_choices',
  'quiz.quiz_questions.quiz_choices',
  'gained_points',
]

export const QUIZ_ATTEMPTS_FILTER_LIST = ['payerId', 'courseId', 'lessonId', 'status', 'createdAt']

export const QUIZ_ATTEMPT_STATUSES = {
  waiting: 'waiting',
  failed: 'failed',
  completed: 'completed',
}

export const QUIZ_QUESTION_TYPES = {
  text: 'text_field',
  single: 'single_choice',
  multiple: 'multiple_choice',
}

export const PREFILLED_INFO_KEYS = {
  phone: 'phone',
  email: 'email',
  fullName: 'full_name',
}
export const PREFILLED_INFO_OPTIONS = [
  {
    value: PREFILLED_INFO_KEYS.phone,
    label: I18n.t('react.cabinet.common.phone_number'),
  },
  {
    value: PREFILLED_INFO_KEYS.email,
    label: I18n.t('react.cabinet.common.email'),
  },
  {
    value: PREFILLED_INFO_KEYS.fullName,
    label: I18n.t('react.shared.full_name'),
  },
]

export const COURSES_QUIZ_ANSWERS_CSV_PARAMS = [
  {
    label: I18n.t('react.cabinet.common.id'),
    csvKey: 'id',
  },
  {
    label: I18n.t('react.cabinet.common.course'),
    csvKey: 'product',
  },
  {
    label: I18n.t('react.cabinet.quiz.lesson'),
    csvKey: 'lesson',
  },
  {
    label: I18n.t('react.cabinet.common.payer'),
    csvKey: 'payer',
  },
  {
    label: I18n.t('react.cabinet.common.status'),
    csvKey: 'status',
  },
  {
    label: I18n.t('react.cabinet.quiz.result'),
    csvKey: 'result',
  },
  {
    label: I18n.t('shared.common.created'),
    csvKey: 'created_at',
  },
  {
    label: I18n.t('react.cabinet.product.lesson.quizzes.questions.answers_title'),
    csvKey: 'answers',
  },
]
