import { createOrdersApi, OrdersApi, UpdateSubscriptionBody, UpdateRegularSubscriptionBody } from '../api/orders-v2.api'

export class OrdersV2Store {
  storeName = 'OrdersV2Store'
  declare childApi: OrdersApi

  updateList = (id: number, data: UpdateRegularSubscriptionBody | UpdateSubscriptionBody) =>
    this.childApi.updateOrderRates(id, data)

  constructor(root) {
    this.childApi = createOrdersApi(root.apiClient)
  }
}
