import SharedStore from 'shared/stores/shared.store'
import { makeObservable } from 'mobx'
import * as api from '../api/pastDueOrders.api'

export class PastDueOrdersFiltersStore extends SharedStore<api.PastDueOrder> {
  storeName = 'PastDueOrdersFiltersStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PastDueOrdersFiltersStore()
