import { observable, action, toJS, makeObservable, override } from 'mobx'
import { DEFAULT_FULL_LIST, DEFAULT_PAGE } from 'constants/pagination.constants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/cancellationTerms.api'

export class CancellationTermsStore extends SharedStore {
  storeName = 'CancellationTermsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promice .then() to do after-actions

  @observable additionalQueryParams = {}

  @override
  async fetchList(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchList({
      expand: toJS(this.expands),
      ...this.queryParams,
      ...data,
      ...this.additionalQueryParams,
    })
    this.processFetchListResponse(resp)

    this.toggleLoading(false)
    return resp
  }

  @override
  async fetchFullList(data) {
    this.updatePagination({
      page: DEFAULT_PAGE,
      per: DEFAULT_FULL_LIST,
    })

    if (data) {
      this.setAdditionalQueryParams(data)
    }

    const resp = await this.fetchList({
      ...data,
    })

    if (this.pagination.total > DEFAULT_FULL_LIST) {
      return this.fetchList({
        per: this.pagination.total,
        ...data,
      })
    }

    return resp
  }

  @action setAdditionalQueryParams = (params) => {
    this.additionalQueryParams = params
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new CancellationTermsStore()
