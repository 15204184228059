import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/payers.api'

export class PayersStore extends SharedStore {
  storeName = 'PayersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  changeEmail = (id, data) => api.changeEmail(id, data)
}

export default new PayersStore()
