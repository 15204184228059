import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/pricing_plans'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id, data) => GET(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const addPayMethods = (data) => POST(`${BASE_URL}/add_pay_methods`, data)

export const removePayMethods = (data) => POST(`${BASE_URL}/remove_pay_methods`, data)
