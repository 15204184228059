import { computed } from 'mobx'

import { AffiliateMarketplaceCategory } from 'shared/components/affiliate-marketplace/types/types'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/affiliateMarketplaceCategories.api'

export class AffiliateMarketplaceCategoriesStore extends SharedStore<AffiliateMarketplaceCategory> {
  storeName = 'AffiliateMarketplaceCategoriesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @computed get categoriesOptionsList() {
    return this.list.map((item) => ({
      value: item.id,
      label: item.name,
    }))
  }

  getSelectedCategoryOption = (id) => this.categoriesOptionsList.find((item) => String(item.value) === String(id))

  getSelectedCategory = (id) => this.list.find((item) => String(item.id) === String(id))
}

export default new AffiliateMarketplaceCategoriesStore()
