import { GET, DELETE, POST_WITH_PROGRESS, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/seller_fonts'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data, handleProgress) => POST_WITH_PROGRESS(BASE_URL, data, handleProgress)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)
