import { GET, POST, PATCH, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/comments'

export const fetchList = (data) => GET(BASE_URL, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PATCH(`${BASE_URL}/${id}`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL}/${id}`, data)
