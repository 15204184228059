import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/jointVenture.api'

const defaultForm = {
  teamMemberInviteId: null,
  futureCommission: null,
  futureCommissionChecked: false,
  jointVentureProductsAttributes: [],
}

export class JointVenturesStore extends SharedStore {
  storeName = 'JointVenturesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable form = defaultForm

  @action resetForm = () => {
    this.form = defaultForm
  }

  @action setForm = (form) => {
    this.form = {
      ...this.form,
      ...form,
    }
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new JointVenturesStore()
