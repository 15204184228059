import { GET, POST, PUT } from 'utils/requests.utils'
import { Nullable } from '../../../../types/helpers'
import { PaginatedResponse, Response } from '../../../../types/responses'

const BASE_URL = '/cabinet/invoices'

export interface Invoice {
  id: number
  state: string
  autocreated: boolean
  deadline: Nullable<unknown>
  docVersion: string
  edited: boolean
  form: string
  fromBills: boolean
  issued: Nullable<unknown>
  number: string
  orderId: number
  parentId: Nullable<unknown>
  payment: InvoicePayment
  price: number
  publisherId: Nullable<unknown>
  remindedAt: Nullable<unknown>
  reminderDelay: Nullable<number>
  resellerId: Nullable<number>
  sellerBuyerData: InvoiceSellerData
  sellerId: number
  tableData: InvoiceTabelData
  token: string
  total: string
  createdAt: string
  updatedAt: string
  order: InvoiceOrder
  transfers: InvoiceTransfer[]
}

interface InvoiceOrder {
  id: number
  payerId: number
  currencyId: number
  paymentForm: string
  cardProvider: string
  periodType: string
  token: string
  totalAmount: string
  unpaidAmount: string
  paidAmount: string
  overdue: number
  creditMemoAmount: number
  seller: InvoiceOrderSeller
  product: InvoiceOrderProduct
  coupon: Nullable<InvoiceOrderCoupon>
  affiliateNode: Nullable<InvoiceOrderAffiliateNode>
}

interface InvoiceOrderSeller {
  username: string
}

interface InvoiceOrderProduct {
  id: number
  name: string
  link: string
  paymentLink: string
}

interface InvoiceOrderCoupon {
  code: string
  value: string
  valueForm: string
}

interface InvoiceOrderAffiliateNode {
  publisher: InvoiceOrderAffiliateNodePublisher
  program: InvoiceOrderAffiliateNodeProgram
}

interface InvoiceOrderAffiliateNodePublisher {
  id: number
  fullName: string
  email: string
}

interface InvoiceOrderAffiliateNodeProgram {
  id: number
  name: string
  commissionInWords: string
}

interface InvoiceTransfer {
  idForSeller: number
  state: string
  form: string
  successDate: string
}

interface InvoicePayment {
  transferId: number
}

interface InvoiceSellerData {
  buyerId: string
  buyerZip: Nullable<string>
  showName: string
  buyerCity: Nullable<string>
  buyerName: string
  sellerZip: string
  showEmail: string
  showPhone: string
  buyerEmail: string
  buyerPhone: string
  sellerCity: string
  sellerName: string
  buyerStreet: Nullable<string>
  buyerVatNo: Nullable<string>
  resellerZip: Nullable<string>
  sellerEmail: string
  sellerPhone: string
  buyerCompany: string
  resellerCity: Nullable<string>
  sellerStreet: string
  sellerTaxNo: Nullable<unknown>
  sellerVatNo: Nullable<string>
  resellerPhone: Nullable<string>
  sellerCompany: string
  additionalInfo: string
  resellerStreet: Nullable<string>
  resellerTaxNo: Nullable<string>
  resellerVatNo: Nullable<string>
  sellerUsername: string
  resellerCompany: Nullable<string>
  buyerCountryCode: string
  buyerState: string
  resellerFullName: Nullable<string>
  buyerStreetNumber: Nullable<string>
  sellerCountryCode: string
  sellerState: string
  sellerIsVatPayer: string
  sellerSupportName: string
  sellerStreetNumber: string
  sellerSupportEmail: string
  sellerSupportPhone: Nullable<string>
  resellerCountryCode: Nullable<string>
  resellerState: Nullable<string>
  resellerStreetNumber: Nullable<unknown>
  resellerSupportEmail: Nullable<unknown>
  resellerSellerSupportEmail: Nullable<string>
  resellerSellerSupportPhone: Nullable<string>
  resellerSellerSupportFullName: Nullable<string>
  buyerCountryName: Nullable<string>
}

interface InvoiceTabelData {
  mainTable: InvoiceMainTabelData[]
  summTable: InvoiceTabelDataProp
  docVersion: number
}

interface InvoiceMainTabelData {
  name: string
  itemsDe: InvoiceTabelDataItem[]
  itemsEn: InvoiceTabelDataItem[]
  cfgsData: InvoiceMainTabelCfgsData
  rowsData: InvoiceMainTabelDataRow
  shortDescription: Nullable<unknown>
}

interface InvoiceTabelDataItem {
  name: string
  count?: number
  additionalInfo?: InvoiceTabelDataAdditionalInfo
}

interface InvoiceTabelDataAdditionalInfo {
  eventDate: string
  eventName: string
}

interface InvoiceMainTabelCfgsData {
  isBusiness: boolean
  withVatNo: boolean
  mustPayVat: boolean
  calcsVatRate: Nullable<unknown>
  privateVatRate: number
  privateVatText: string
  businessVatRate: number
  businessVatText: string
  transferVatRate: number
}

interface InvoiceMainTabelDataRow {
  rate: InvoiceTabelDataProp
  discount?: InvoiceTabelDataProp
}

interface InvoiceTabelDataProp {
  net: number
  vat: number
  count: number
  gross: number
  netSum: number
  vatSum: number
  dVatSum: number
  grossSum: number
  dGrossSum: number
  vatByRate: VatByRate
}

interface VatByRate {
  ['19.0']: VatByRateProp
}

interface VatByRateProp {
  net: number
  vat: number
  count: number
  gross: number
  netSum: number
  vatSum: number
  dVatSum: number
  grossSum: number
  dGrossSum: number
}

export const fetchList = (data) => GET<PaginatedResponse<Invoice[]>>(BASE_URL, data)

export const fetchItem = (id, data) => GET<Response<Invoice>>(`${BASE_URL}/${id}`, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const cancelItem = (id, data?: any) => POST(`${BASE_URL}/${id}/cancel`, data)
