import { action, computed, makeObservable, observable } from 'mobx'

import { isEmpty } from '@elo-kit/utils/validators.utils'
import { getPaypalProvider } from 'utils/paymentSetting.utils'
import { PAYMENT_METHODS } from 'constants/paymentSetting.constants'
import { notify } from 'libs/common/notify'

import * as api from '../api/paymentSetting.api'
import { CabinetRootStore } from './cabinetRoot.store'

export class PaymentSettingStore {
  @observable data = {} as api.PaymentSetting
  @observable loading = false
  @observable areSettingsUpdating = false
  @observable currenciesLoading = {}
  @observable currencies = {}
  root: CabinetRootStore

  @computed get allowedToSellByStripe() {
    const { stripe = {} as api.StripeSetting } = this.data?.apiSettings || {}
    const { active, valid } = stripe
    return active && valid
  }

  @computed get allowedToSellByPaypal() {
    const { apiSettings: { payPal = {} } = {}, payPalProvider } = this.data || {}
    const provider = getPaypalProvider(payPalProvider, payPal)
    const { active, valid } = payPal[provider] || {}
    return active && valid
  }

  @computed get showStripeSettingCard() {
    const { apiSettings: { stripe = {} as api.StripeSetting } = {} } = this.data || {}
    const { publishableKey, secretKey } = stripe || {}
    return publishableKey && secretKey
  }

  getHasActiveSetting = ({ lemonwayAllowedToSell, isMangopayAppActive, elopageConnectAllowedToSell }) => {
    const {
      lemonWay = {} as api.PaymentGeneralSetting,
      mangoPay = {} as api.PaymentGeneralSetting,
      elopageConnect = {} as api.ElopageConnectSetting,
    } = this.data?.apiSettings || ({} as api.PaymentApiSetting)
    const activeSettings = {
      lemonway: lemonWay.active && lemonwayAllowedToSell,
      mangopay: mangoPay.active && isMangopayAppActive,
      stripe: this.allowedToSellByStripe,
      paypal: this.allowedToSellByPaypal,
      elopageConnect: elopageConnect.active && elopageConnectAllowedToSell,
    }
    return Object.values(activeSettings).some(Boolean)
  }

  @action setCurrencies = (currencies) => (this.currencies = currencies)
  @action setCurrenciesLoading = (currenciesLoading) => (this.currenciesLoading = currenciesLoading)
  @action setLoading = (loading) => (this.loading = loading)
  @action setData = (data) => {
    if (data) {
      this.data = data
    }
  }

  updateCurrencies = (currencies) =>
    this.setCurrencies({
      ...this.currencies,
      ...currencies,
    })

  updateCurrenciesLoading = (currenciesLoading) =>
    this.setCurrenciesLoading({
      ...this.currenciesLoading,
      ...currenciesLoading,
    })

  updatePaymentSettings = async (values) => {
    const resp = await api.updateItem(values)
    const { data = {} } = resp || {}
    this.setData(data)
    this.root.currenciesStore.fetchFullList()

    notify('success', I18n.t('react.cabinet.notific.updated'))
  }

  fetchData = async () => {
    if (isEmpty(this.data)) {
      this.setLoading(true)

      const resp = await api.fetchItem()
      const { data = {} } = resp || {}
      this.setData(data)
      this.setLoading(false)
    }
  }

  setPaymentSettingProvider = async ({ provider, value }) => {
    if (this.data?.[provider] != value) {
      this.setLoading(true)
      const resp = await api.updateItem({ [provider]: value })
      const { data = {} } = resp || {}
      this.setData(data)
      this.setLoading(false)
      notify('success', I18n.t('react.cabinet.notific.updated'))
    }
  }

  setPayPalSettings = async (values, paypalProvider) => {
    const { payPal = {} } = this.data?.apiSettings
    const provider = payPal[paypalProvider] || {}

    const data = {
      apiSettings: {
        payPal: {
          [paypalProvider]: {
            ...provider,
            ...values,
          },
        },
      },
    }
    await this.updatePaymentSettings(data)
    const updatedPaypalProvider = this.data?.apiSettings?.payPal[paypalProvider]
    const shouldSetActive = !provider.valid && updatedPaypalProvider.valid && !updatedPaypalProvider.active
    if (shouldSetActive) this.toggleActive(PAYMENT_METHODS.payPal, paypalProvider)
  }

  setStripeSettings = async (values) => {
    const { stripe = {} as api.StripeSetting } = this.data?.apiSettings || {}

    const data = {
      apiSettings: {
        stripe: {
          ...stripe,
          ...values,
        },
      },
    }

    await this.updatePaymentSettings(data)
    const updatedStripeProvider = this.data?.apiSettings?.stripe
    const shouldSetActive = !stripe.valid && updatedStripeProvider.valid && !updatedStripeProvider.active
    if (shouldSetActive) this.toggleActive(PAYMENT_METHODS.stripe)
  }

  setActiveSetting = async (values, selector, paypalProvider?: string) => {
    const { apiSettings = {} } = this.data || {}
    const setting = apiSettings[selector] || {}

    const isPayPal = selector === PAYMENT_METHODS.payPal

    const data = {
      apiSettings: {
        [selector]: {
          ...(paypalProvider
            ? {
                [paypalProvider]: {
                  ...(setting[paypalProvider] || {}),
                  ...values,
                },
              }
            : {
                ...(isPayPal ? {} : setting),
                ...values,
              }),
        },
      },
    }

    await this.updatePaymentSettings(data)
  }

  toggleActive = async (selector, paypalProvider?: string) => {
    const apiSetting = this.data?.apiSettings[selector] || {}
    const providerSettings = paypalProvider ? apiSetting[paypalProvider] : apiSetting
    const active = !providerSettings?.active
    await this.setActiveSetting({ active }, selector, paypalProvider)
  }

  toggleSepaActive = async (sepa, provider) => {
    this.areSettingsUpdating = true
    await this.setActiveSetting({ sepa: !sepa }, provider)
    this.areSettingsUpdating = false
  }

  toggleVirtualIban = async (bankWire, provider) => {
    this.areSettingsUpdating = true
    await this.setActiveSetting(bankWire, provider)
    this.areSettingsUpdating = false
  }

  togglePaymentMethod = async (valueObj, provider) => {
    this.areSettingsUpdating = true
    await this.setActiveSetting(valueObj, provider)
    this.areSettingsUpdating = false
  }

  createPaymentSettingCurrency = async (data) => {
    const resp = await api.createPaymentSettingCurrency(data)
    this.fetchPaymentSettingCurrenciesList(data.provider)
    return resp
  }

  fetchPaymentSettingCurrenciesList = async (provider) => {
    this.updateCurrenciesLoading({ [provider]: true })

    const { data, success } = await api.fetchPaymentSettingCurrenciesList({ provider })

    if (data && success) {
      this.updateCurrencies({ [provider]: data })
    }

    this.updateCurrenciesLoading({ [provider]: false })
  }

  constructor(rootStore: CabinetRootStore) {
    this.root = rootStore
    makeObservable(this)
  }
}
