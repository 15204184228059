import SharedStore from 'shared/stores/shared.store'

import { override } from 'mobx'
import * as api from '../api/mobileApp.api'

export class MobileAppStore extends SharedStore {
  storeName = 'MobileAppStore'
  childApi = api

  @override
  async fetchItem(fetchData) {
    this.toggleLoading(true)

    const resp = await api.fetchItem(fetchData)
    this.setItem(resp)
    this.toggleLoading(false)

    return resp
  }

  @override
  updateItem(data) {
    return api.updateItem(data)
  }

  checkServerKey = (data) => api.checkServerKey(data)
  fetchAppsUrl = api.fetchAppsUrl
}

export default new MobileAppStore()
