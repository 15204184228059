import { action, makeObservable } from 'mobx'
import { arrayMove } from 'react-sortable-hoc'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/goods.api'

export class GoodsStore extends SharedStore {
  storeName = 'GoodsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action sortGoods = (oldIndex, newIndex) => {
    this.list = arrayMove(this.list, oldIndex, newIndex)
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new GoodsStore()
