import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/api_keys'

export const createItem = (data) => POST(BASE_URL, data)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const fetchList = (data) => GET(BASE_URL, data)

export const updateItem = (id, data) => PUT(BASE_URL, data)
