import { GET, POST, PATCH, DELETE, PUT } from 'utils/requests.utils'

const PAGE_BASE_URL = '/cabinet/content_pages'
const BLOCK_BASE_URL = '/cabinet/content_blocks'

// contact form block requests
const BLOCK_FORM_FIELDS_BASE_URL = '/cabinet/content_block_form_fields'
const BLOCK_CONTACT_FORM_BASE_URL = '/cabinet/content_block_contact_forms'

export const fetchContentPage = (id, data) =>
  GET(`${PAGE_BASE_URL}/${id}`, {
    ...data,
  })

export const createItem = (data) =>
  POST(PAGE_BASE_URL, {
    ...data,
  })

export const deleteItem = (id, data) =>
  DELETE(`${PAGE_BASE_URL}/${id}`, {
    ...data,
  })

export const createContentBlock = (data) =>
  POST(BLOCK_BASE_URL, {
    ...data,
  })

export const updateContentBlock = (id, data, options) =>
  PATCH(`${BLOCK_BASE_URL}/${id}`, {
    ...data,
    ...options,
  })

export const duplicateContentBlock = (id, data) =>
  POST(`${BLOCK_BASE_URL}/${id}/duplicate`, {
    ...data,
  })

export const removeContentBlock = (id, data) =>
  DELETE(`${BLOCK_BASE_URL}/${id}`, {
    ...data,
  })

export const createPageFromTemplate = (id, data) =>
  POST(`${PAGE_BASE_URL}/${id}/create_from_template`, {
    ...data,
  })

export const createItemFromTemplate = (id, data) =>
  POST(`${BLOCK_BASE_URL}/${id}/create_from_template`, {
    ...data,
  })

export const createBlockFormField = (data) => POST(BLOCK_FORM_FIELDS_BASE_URL, data)

export const updateBlockFormFields = (id, data) => PUT(`${BLOCK_FORM_FIELDS_BASE_URL}/${id}`, data)

export const deleteBlockFormField = (id, data) => DELETE(`${BLOCK_FORM_FIELDS_BASE_URL}/${id}`, data)

export const updateContactFormBlock = (id, data) => PUT(`${BLOCK_CONTACT_FORM_BASE_URL}/${id}`, data)
