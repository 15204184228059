import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/productAddons.api'

export class ProductAddonsStore extends SharedStore {
  storeName = 'ProductAddonsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable addonsByProductId = {}
  @observable addonsByProductIdLoading = {}

  @action fetchAddonByProductId = (productId) => {
    const addons = this.addonsByProductId[productId]

    if (!!addons) {
      return addons
    }

    this.addonsByProductIdLoading = {
      ...this.addonsByProductIdLoading,
      [productId]: true,
    }

    this.fetchFullList({ productId }).then(({ data }) => {
      this.addonsByProductId = {
        ...this.addonsByProductId,
        [productId]: data.list,
      }

      this.addonsByProductIdLoading = {
        ...this.addonsByProductIdLoading,
        [productId]: false,
      }
    })
  }

  @action setListPositions = (list, oldPosition, newPosition) => {
    const itemToUpdate = this.list.find((item) => item.position === oldPosition)
    this.updateItem(itemToUpdate.id, { position: newPosition })
    this.list = list
    this.list.forEach((item, index) => {
      item.position = index
    })
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new ProductAddonsStore()
