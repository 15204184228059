import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/customEmails.api'
import { sendTestEmailNew } from '../api/products.api'

export class CustomEmailsStore extends SharedStore {
  storeName = 'CustomEmailsStore'
  childApi = api
  // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  sendTestEmail = async (data, id) => {
    this.toggleLoading(true)
    let resp
    if (data.application !== 'lesson') {
      resp = sendTestEmailNew(id, data)
    } else {
      resp = await this.childApi.sendTestEmail(data)
    }

    this.toggleLoading(false)
    return resp
  }
}

export default new CustomEmailsStore()
