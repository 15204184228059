import React from 'react'
import { components } from 'react-select'

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <i className='far fa-trash-alt' />
  </components.ClearIndicator>
)

export default ClearIndicator
