import { action, computed, observable, makeObservable } from 'mobx'
import queryString from 'query-string'
import { isEmpty } from '@elo-kit/utils/validators.utils'

import { prevLessonId, nextLessonId } from 'utils/lessonPages.utils'
import { getNestedChildren } from 'utils/product.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import { COURSE_FORMS } from 'constants/courses.constants'
import { desktopResolution } from 'constants/browser.constants'
import { LESSON_EXPAND } from 'constants/routes/lessons.contants'

export class CourseViewsStore {
  @observable inited = false
  @observable loading = false
  @observable cmpProps

  @observable mobileMenuActive = false
  @observable mobileSideMenuActive = false
  @observable showRetakeQuiz = false
  @observable showQuizResultModal = false
  @observable showQuizResults = false

  @observable courseSession = {}
  @observable product = {}
  @observable lessons = []
  @observable activeLesson = {}
  @observable activeLessonStatus
  @observable courseThemeData = {}

  @observable showOverviewPage = false

  @observable mainSideMenuOpen = true
  @observable membershipNavOpen = true

  @observable showHeaderLinks = true

  @observable browserTabTitle = ''

  @observable communityModalIsOpenedByDefault = false

  @action setBrowserTabTitle = () => {
    this.browserTabTitle = document.title
  }

  setSideMenuState = (state, history) => {
    if (history) {
      const menuShouldBeHide = history.includes('course-preview')

      this.setMainSideMenuOpen(!menuShouldBeHide)
    } else {
      this.setMainSideMenuOpen(state)
    }
  }

  @action setMembershipNavState = (state) => {
    this.membershipNavOpen = state
  }

  prepareCourse = async (cmpProps) => {
    const { productId } = cmpProps
    this.setInited(false)
    this.setLoading(true)
    const { lesson_id: lessonId } = getSearchParams()

    this.setCmpProps(cmpProps)

    const { data: product, success: productSuccess } = await this.root.productsStore.fetchItem(productId)

    if (product && productSuccess) {
      const { courseThemeId, id: productId } = product

      if (courseThemeId) {
        const { data: courseTheme, success: courseThemeSuccess } =
          await this.root.courseThemesStore.fetchItem(courseThemeId)

        const courseThemeData =
          courseThemeSuccess && courseTheme && !isEmpty(courseTheme) ? courseTheme : { form: COURSE_FORMS.default }

        this.setCourseThemeData(courseThemeData)
      } else {
        this.setCourseThemeData({ form: COURSE_FORMS.default })
      }

      this.setProduct(product)

      const { data: { list = [] } = {} } = await this.root.lessonsStore.fetchFullList({ productId })
      this.setLessons(getNestedChildren(list, null))
      this.openStaticPageOrFirstLesson(lessonId)
    }
  }

  openStaticPageOrFirstLesson = (lessonId) => {
    const { form, prefs = {} } = this.courseThemeData
    if (!lessonId && form !== COURSE_FORMS.default && prefs.showOverview) {
      this.setOverviewPage()
      this.setInited(true)
      this.setLoading(false)
    } else {
      this.selectFirstLesson(lessonId)
    }
  }

  @action setOverviewPage = (showOverviewPage = true) => {
    this.showOverviewPage = showOverviewPage
    this.cmpProps.historyPush({ search: '' })
  }

  @action selectFirstLesson = (lessonId) => {
    this.selectLesson(lessonId || this.detectFirstLessonId())
  }

  @action detectFirstLessonId = () => this.detectFirstLessonIdDeep(this.lessons)

  @action detectFirstLessonIdDeep = (lessons) => {
    // with this deep detecting we can do a lot of features in the future
    // like detect first not passed, detect first with quiz OR even
    // open for all payers lesson which seller select as promotion
    const detected = false
    let deepResult

    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].isCategory) {
        deepResult = this.detectFirstLessonIdDeep(lessons[i].children)
        if (deepResult) {
          return deepResult
        }
      } else if (lessons[i].active && lessons[i].id) {
        return lessons[i].id
      } else {
        lessons.shift()
        deepResult = this.detectFirstLessonIdDeep(lessons)
        if (deepResult) {
          return deepResult
        }
      }
    }

    return detected
  }

  selectLesson = async (lessonId, handleScrollChange, shouldSetQueryParams = false) => {
    if (lessonId) {
      const resp = await this.root.lessonsStore.fetchItem(lessonId, {
        productId: this.product.id,
        expand: LESSON_EXPAND,
      })
      const { data } = resp || {}
      if (data) {
        this.setActiveLesson(data)
        if (this.viewConfig.widgets.comments) {
          this.root.commentsStore.fetchFullList({ lessonId })
        }
        this.setQueryParams(shouldSetQueryParams)
        this.setMobileMenuActive(false)
        this.setMobileSideMenuActive(false)
        this.setInited(true)
        this.setLoading(false)

        if (this.activeLesson.name) {
          document.title = this.activeLesson.name
        }

        if (handleScrollChange && desktopResolution) {
          handleScrollChange()
        } else {
          window.scrollTo(0, 0)
        }
      }
    }
  }

  @action setActiveLesson = (activeLesson) => (this.activeLesson = activeLesson)
  @action setCourseThemeData = (courseThemeData) => (this.courseThemeData = courseThemeData)
  @action setCmpProps = (cmpProps) => (this.cmpProps = cmpProps)
  @action setInited = (inited) => (this.inited = inited)
  @action setLessons = (lessons) => (this.lessons = lessons)
  @action setLoading = (loading) => (this.loading = loading)
  @action setMainSideMenuOpen = (mainSideMenuOpen) => (this.mainSideMenuOpen = mainSideMenuOpen)
  @action setMobileMenuActive = (mobileMenuActive) => (this.mobileMenuActive = mobileMenuActive)
  @action setMobileSideMenuActive = (mobileSideMenuActive) => (this.mobileSideMenuActive = mobileSideMenuActive)
  @action setProduct = (product) => (this.product = product)

  @action setPrevLesson = () => {
    this.selectLesson(this.prevLessonId)
  }

  @action setNextLesson = () => {
    this.selectLesson(this.nextLessonId)
  }

  setQueryParams = (shouldPushHistory) => {
    const { comment_id: commentId } = getSearchParams()

    const queryStringObj = commentId
      ? {
          lesson_id: this.activeLesson.id,
          comment_id: commentId,
        }
      : {
          lesson_id: this.activeLesson.id,
        }

    const newQueryString = queryString.stringify(queryStringObj, { sort: false })

    if (shouldPushHistory && this.courseThemeData.form !== COURSE_FORMS.default) {
      this.cmpProps.historyPush({ search: newQueryString })
    } else {
      this.cmpProps.historyReplace({ search: newQueryString })
    }
  }

  toggleMobileMenu = () => this.setMobileMenuActive(!this.mobileMenuActive)

  toggleSideMobileMenu = () => this.setMobileSideMenuActive(!this.mobileSideMenuActive)

  isAppActive = (key) => this.root.sellerStore.isAppActive(key)

  constructor(rootStore) {
    this.root = rootStore
    makeObservable(this)
  }

  get isOverviewPage() {
    const { location: { search = '' } = {} } = window
    return !search.includes('lesson_id')
  }

  @computed get prevLessonId() {
    return prevLessonId(this.activeLesson, this.lessons)
  }

  @computed get nextLessonId() {
    return nextLessonId(this.activeLesson, this.lessons)
  }

  @computed get seller() {
    const {
      item: { avatar = {} },
    } = this.root.userStore
    return {
      id: this.product.sellerId,
      avatarUrl: (avatar || {}).s200,
    }
  }

  @computed get viewConfig() {
    const { commentsAllowed, fbCommentsAllowed, contentPageId } = this.activeLesson || {}

    const layout = this.courseThemeData.form || COURSE_FORMS.default

    const showPrevBtn = !!this.prevLessonId
    const showNextBtn = !!this.nextLessonId

    const displayCommentsWidget = commentsAllowed || fbCommentsAllowed
    const fbAppId = getElopageConfig('fbAppId')

    return {
      layout,
      isShop: false,
      isPayerCabinet: false,
      contentPagePreviewScope: 'cabinet',
      shouldCompletePrevQuizzes: false,

      backLink: `/cabinet/products/${this.product.id}`,

      facebookAppId: fbAppId,
      avatarUrl: (this.root.userStore.item.avatar || {}).s200,
      currentProfileId: this.product.sellerId,
      currentProfileType: 'seller',

      contentToDisplayKey: 'contentPageCont',
      showPrevBtn,
      showNextBtn,

      widgets: {
        quiz: false,
        content: !!contentPageId,
        comments: displayCommentsWidget,
      },
    }
  }
}

export default new CourseViewsStore()
