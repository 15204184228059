import { GET } from 'utils/requests.utils'
import { PaginatedResponse } from 'types/responses'

import { AffiliateProgramProduct } from 'shared/components/affiliate-marketplace/types/types'

const BASE_URL = (id: string | number) => `/cabinet/affiliate_marketplace/products/${id}/program_products`

interface RequestData {
  productId: string | number
}

export const fetchList = ({ productId, ...data }: RequestData) =>
  GET<PaginatedResponse<AffiliateProgramProduct>>(BASE_URL(productId), data)
