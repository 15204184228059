import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/affiliateNodes.api'

export class AffiliateNodesStore extends SharedStore {
  storeName = 'AffiliateNodesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @action cancelItem = async (id) => {
    this.loading = true
    const resp = await this.childApi.cancelItem(id)
    this.loading = false
    return resp
  }

  @action reinviteItem = async (id) => {
    this.loading = true
    const resp = await this.childApi.reinviteItem(id)
    this.loading = false
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new AffiliateNodesStore()
