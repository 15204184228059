import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { ClearCacheApi, createClearCacheApi } from '../api/clearCache.api'

export class ClearCacheStore extends SharedStore<any> {
  storeName = 'ClearCacheStore'
  declare childApi: ClearCacheApi

  constructor(root) {
    super()
    this.childApi = createClearCacheApi(root.apiClient)
    makeObservable(this)
  }

  clearCache = async (id: string) => await this.childApi.clearCache(id)
}
