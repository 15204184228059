import { GET, POST, PATCH, DELETE } from 'utils/requests.utils'
import { PaginatedResponse, Response, HTTPResponse } from 'types/responses'

const BASE_URL = '/cabinet/trust_seals'

export interface TrustSeal {
  id: number
  file: {
    original: string
    size: number
    contentType: string
    custom: string
    name: string
    createdAt: string
  }
  fileCropH: number
  fileCropW: number
  fileCropX: number
  fileCropY: number
  ownerableId: number
  ownerableType: string
}

export const fetchList = (data) => GET<PaginatedResponse<TrustSeal[]>>(BASE_URL, data)

export const fetchItem = (id) => GET<Response<TrustSeal>>(`${BASE_URL}/${id}`)

export const createItem = (data) => POST<Response<TrustSeal>>(BASE_URL, data)

export const updateItem = (id, data) => PATCH<Response<TrustSeal>>(`${BASE_URL}/${id}`, data)

export const deleteItem = (id) => DELETE<HTTPResponse>(`${BASE_URL}/${id}`)
