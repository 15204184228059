import { makeObservable } from 'mobx'

import SharedStore from './shared.store'
import * as api from '../api/optionAdvertisementBlocks'

export class OptionAdvertisementBlocksStore extends SharedStore<any> {
  storeName = 'OptionAdvertisementBlocksStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new OptionAdvertisementBlocksStore()
