import { GET, POST } from 'utils/requests.utils'

import { Response } from 'types/responses'
import { ProfileDeletion, CreateReqData, ProfileDeletionCheck } from '../../shared/types/profile-deletion'

const BASE_URL = '/cabinet/profile_deletion_request'

export const fetchItem = () => GET<Response<ProfileDeletion>>(`${BASE_URL}`)

export const createItem = (data: CreateReqData) => POST<Response<ProfileDeletion>>(BASE_URL, data)

export const checkProfileDeletion = () => GET<Response<ProfileDeletionCheck>>(`${BASE_URL}/check`)
