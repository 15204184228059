import { observable, action, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/affiliateStatisticsOverview.api'

export class AffiliateStatisticsOverviewStore extends SharedStore {
  storeName = 'AffiliateStatisticsOverviewStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable detailsList = {}
  @observable detailsLoading = {}

  @override
  async fetchList(data) {
    this.loading = true

    const resp = await this.childApi.fetchList({
      ...this.queryParams,
      ...data,
    })

    const respData = resp.data
    this.list = []

    for (const id in respData) {
      const list = (respData[id] || []).sort((a, b) => a.productId - b.productId)

      this.list.push({
        publisherId: id,
        list,
      })

      list.map((product) => {
        if (product.productId) {
          this.detailsList = {
            ...this.detailsList,
            [id]: {
              ...this.detailsList[id],
              [product.productId]: [],
            },
          }

          this.detailsLoading = {
            ...this.detailsLoading,
            [id]: {
              ...this.detailsLoading[id],
              [product.productId]: false,
            },
          }
        }
      })
    }

    this.loading = false
    return resp
  }

  @action fetchProductDetails = async (data) => {
    this.detailsLoading = {
      ...this.detailsLoading,
      [data.publisherId]: {
        [data.productId]: true,
      },
    }

    const resp = await this.childApi.fetchProductDetails({
      ...this.queryParams,
      ...data,
    })

    const transactionsList = (resp.data || []).sort((a, b) => b.idForSeller - a.idForSeller)

    this.detailsList = {
      ...this.detailsList,
      [data.publisherId]: {
        ...this.detailsList[data.publisherId],
        [data.productId]: transactionsList,
      },
    }

    this.detailsLoading = {
      ...this.detailsLoading,
      [data.publisherId]: {
        [data.productId]: false,
      },
    }
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}
export default new AffiliateStatisticsOverviewStore()
