import { observable, action, makeObservable } from 'mobx'

import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { THEME_SHARING_PARAMS, THEME_SLUGS } from 'constants/themes.constants'
import { SHARABLE_FORM, SHARABLE_TYPE } from 'constants/sharing.constants'

import { getParamsFromUrl } from 'utils/helpers.utils'
import { getThemeStoreAndSlug } from 'utils/themes.utils'
import { profile } from 'utils/profileHelper.utils'

import { getCookies, removeCookies } from 'libs/common/cookies'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sharings.api'

export class SharingsStore extends SharedStore {
  storeName = 'SharingsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions
  @observable isSharingLoading = false
  @observable sharingSubmitEnabled = true
  @observable sharingConfirmationModalOpened = false
  @observable successSharingModalOpened = false

  @action toggleSharingConfirmationModalOpened = () =>
    (this.sharingConfirmationModalOpened = !this.sharingConfirmationModalOpened)

  @action toggleSuccessSharingModal = () => {
    this.successSharingModalOpened = !this.successSharingModalOpened
  }

  @action setSharingLoading = (value) => (this.isSharingLoading = value)

  @action setSharingSubmitEnabled = (value) => (this.sharingSubmitEnabled = value)

  handleSharingForm = () => {
    const isTeamMemberProfile = profile.profileType === ACTIVE_PROFILES.teamMember
    const urlParams = getParamsFromUrl(THEME_SHARING_PARAMS)
    const sharingToken = urlParams.shared_theme_token || getCookies('shared_theme_token')
    const sharableType = urlParams.shared_theme_type || getCookies('shared_theme_type')

    if (sharingToken && sharableType && !isTeamMemberProfile) {
      this.fetchSharingForm(sharingToken, { sharableType }).then(({ data = {}, success }) => {
        if (success) {
          if (data.form === SHARABLE_FORM.public) {
            this.toggleSharingConfirmationModalOpened()
          }
        } else {
          this.handleSharingCancel()
        }
      })
    }
  }

  handleThemeSharing = (data) => {
    const urlParams = getParamsFromUrl(THEME_SHARING_PARAMS)
    const sharingToken = urlParams.shared_theme_token || getCookies('shared_theme_token')
    const sharableType = urlParams.shared_theme_type || getCookies('shared_theme_type')
    const requestData = {
      ...(data || {}),
      expand: ['sharing_items.sharable'],
    }
    const isMultiSharing = sharableType === SHARABLE_TYPE.multiItemsSharing

    if (sharingToken && sharableType) {
      const { themeStore, themeSlug } = getThemeStoreAndSlug()
      this.setSharingLoading(true)
      this.setSharingSubmitEnabled(false)

      return (themeStore || this).duplicateByToken(sharingToken, requestData).then(({ success, data = {} }) => {
        if (!success) {
          this.setSharingSubmitEnabled(true)
        }
        if (success) {
          removeCookies('shared_theme_token')
          removeCookies('shared_theme_type')
          this.setItem({ success, data })

          if (isMultiSharing) {
            this.toggleSuccessSharingModal()
            this.toggleSharingConfirmationModalOpened()
          } else {
            const url =
              themeSlug === THEME_SLUGS.orderBumpsThemes
                ? `/cabinet/order_bumps_templates?shared=true`
                : `/cabinet/${themeSlug}/${data.id}/edit?shared=true`
            window.location.assign(url)
          }
        }
        this.setSharingLoading(false)
        return success
      })
    }
  }

  handleSharingCancel = () => {
    window.history.replaceState(null, null, window.location.pathname)
    removeCookies('shared_theme_token')
    removeCookies('shared_theme_type')
  }

  handleCancelSharingConfirmation = () => {
    this.handleSharingCancel()
    this.toggleSharingConfirmationModalOpened()
  }

  fetchSharingForm = async (token, data) => {
    const resp = await this.childApi.fetchSharingForm(token, data)
    this.item = resp.data
    return resp
  }

  @action duplicateByToken = async (token, data) => {
    const resp = await api.duplicateByToken(token, data)
    this.setItem(resp.data)
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new SharingsStore()
