import { observable, action, toJS, makeObservable } from 'mobx'

import { notify } from 'libs/common/notify'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/invoices.api'

export class InvoicesStore extends SharedStore<api.Invoice> {
  storeName = 'InvoicesStore'
  childApi = api
  @observable historyModal = false
  @observable editInfoModal = false

  @action handleModal = (type) => {
    this[type] = !this[type]
  }

  @action cancelItem = async () => {
    if (confirm(I18n.t('react.cabinet.invoice.cancel_confirm'))) {
      this.loading = true
      const resp = await api.cancelItem(this.item.id)
      if (resp.success) {
        this.item = resp.data
        notify('success', I18n.t('react.cabinet.invoice.canceled'))
      } else if (!resp.error) {
        notify('error', I18n.t('react.cabinet.invoice.not_canceled'))
      }
      this.loading = false
    }
  }

  @action handleVersionChange = (field, value) => {
    this.item.sellerBuyerData = {
      ...this.item.sellerBuyerData,
      [field]: value,
    }
  }

  @action handleVersionSubmit = async (history) => {
    const { id, sellerBuyerData } = this.item
    this.loading = true
    const resp = await api.updateItem(id, { sellerBuyerData, expand: toJS(this.expands) })
    if (resp.success) {
      notify('success', I18n.t('react.cabinet.invoice.edit.success'))
      if (resp.data?.childIds?.[0]) {
        history.push(`/cabinet/invoices/${resp.data.childIds[0]}`)
      }
    }
    this.editInfoModal = false
    this.loading = false
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new InvoicesStore()
