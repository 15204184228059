import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/payouts.api'

export class PayoutsStore extends SharedStore {
  storeName = 'PayoutsStore'
  childApi = api

  fetchWallet = (id) => this.childApi.fetchWallet(id)

  getLeftAmount = (data) => this.childApi.getLeftAmount(data)

  payoutCashback = (data) => this.childApi.payoutCashback(data)

  fetchSchedule = (data) => this.childApi.fetchSchedule(data)

  fetchStatistic = (data) => this.childApi.fetchStatistic(data)
}

export default new PayoutsStore()
