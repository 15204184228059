import classNames from 'classnames'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import { STATUSES } from '@elo-kit/constants/status-text.constants'

import {
  LEMONWAY_EXTERNAL_STATUSES,
  LEMONWAY_INTERNAL_STATUSES,
  LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES,
  MANGOPAY_EXTERNAL_STATUSES,
  MANGOPAY_INTERNAL_STATUSES,
  MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES,
  PAYMENT_METHODS,
  PAYMENT_PROVIDERS,
  PAYPAL_PROVIDERS,
  UBO_DECLARATION_STATUSES,
} from 'constants/paymentSettingShared.constants'
import { COLORS } from '@elo-kit/constants/general.constants'

import { snakeCaseToCamelCase } from './nameStyle.utils'

import FTextField from '../ui/formik-form/FTextField'
import FSelectField from '../ui/formik-form/select/FSelectField'

export { createImg, getPaymentMethodIcon, getPubKey } from 'utils/paymentSettingShared.utils'

const { eloBlue, eloGreen, eloOrange, eloRed } = COLORS

export const getWalletState = ({ state, internalState }) => {
  const [isStateEqualRejected, isStateEqualChange, isStateEqualInReview, isStateEqualRequested] = [
    MANGOPAY_INTERNAL_STATUSES.rejected,
    LEMONWAY_INTERNAL_STATUSES.change,
    LEMONWAY_INTERNAL_STATUSES.inReview,
    LEMONWAY_INTERNAL_STATUSES.requested,
  ].map((name) => internalState === name)

  const returnInternalState =
    !state || isStateEqualRejected || isStateEqualChange || isStateEqualInReview || isStateEqualRequested

  return returnInternalState ? internalState : state
}

export const getStatusLabelProps = (key) => {
  switch (key) {
    case LEMONWAY_EXTERNAL_STATUSES.kyc2:
    case MANGOPAY_INTERNAL_STATUSES.legitimated:
    case MANGOPAY_EXTERNAL_STATUSES.regular:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_EXTERNAL_STATUSES.closed:
      return {
        statusIconClassName: 'fas fa-times-circle',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_EXTERNAL_STATUSES.kyc1:
    case LEMONWAY_EXTERNAL_STATUSES.kycIncomplete:
    case MANGOPAY_EXTERNAL_STATUSES.light:
    case UBO_DECLARATION_STATUSES.incomplete:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_EXTERNAL_STATUSES.rejected:
    case MANGOPAY_INTERNAL_STATUSES.rejected:
    case STATUSES.deactivated:
      return {
        statusIconClassName: 'fas fa-times-circle',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_EXTERNAL_STATUSES.blocked:
      return {
        statusIconClassName: 'fas fa-ban',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_EXTERNAL_STATUSES.expired:
      return {
        statusIconClassName: 'fas fa-calendar-times',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_INTERNAL_STATUSES.change:
      return {
        statusIconClassName: 'fas fa-info-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_INTERNAL_STATUSES.inReview:
      return {
        statusIconClassName: 'fas fa-search',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case LEMONWAY_INTERNAL_STATUSES.reviewed:
      return {
        statusIconClassName: 'far fa-check-circle',
        statusLabelStyle: { color: '#ff8d00' },
      }
    case LEMONWAY_INTERNAL_STATUSES.init:
      return {
        statusIconClassName: 'fas fa-certificate',
        statusLabelStyle: { color: eloBlue },
      }
    case LEMONWAY_INTERNAL_STATUSES.requested:
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.notVerified:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.validationAsked:
      return {
        statusIconClassName: 'fas fa-question-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.sent:
      return {
        statusIconClassName: 'fas fa-envelope',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.onHold:
      return {
        statusIconClassName: 'fas fa-pause-circle',
        statusLabelStyle: { color: eloOrange },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.accepted:
    case MANGOPAY_INTERNAL_STATUSES.registered:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.created:
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.validated:
      return {
        statusIconClassName: 'fas fa-check-circle',
        statusLabelStyle: { color: eloGreen },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.notAccepted:
      return {
        statusIconClassName: 'fas fa-ban',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.unreadable:
      return {
        statusIconClassName: 'fas fa-eye-slash',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.wrongType:
      return {
        statusIconClassName: 'fas fa-comment-slash',
        statusLabelStyle: { color: eloRed },
      }
    case LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES.wrongName:
      return {
        statusIconClassName: 'fas fa-user-slash',
        statusLabelStyle: { color: eloRed },
      }
    case MANGOPAY_INTERNAL_STATUSES.headQuarter:
      return {
        statusIconClassName: 'fas fa-building',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.legalRepresentative:
      return {
        statusIconClassName: 'fas fa-user-tie',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.documents:
      return {
        statusIconClassName: 'fas fa-file-alt',
        statusLabelStyle: { color: '#9e9e9e' },
      }
    case MANGOPAY_INTERNAL_STATUSES.ubo:
      return {
        statusIconClassName: 'fas fa-users',
        statusLabelStyle: { color: '#a8a8a8' },
      }
    case MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES.refused:
      return {
        statusIconClassName: 'fas fa-exclamation-circle',
        statusLabelStyle: { color: '#ff4747' },
      }
    default:
      return {
        statusIconClassName: '',
        statusLabelStyle: {},
      }
  }
}

export const checkIsCreated = (object) =>
  !isEmpty(object) &&
  Object.values(object)
    .filter((item) => typeof item !== 'boolean')
    .every(Boolean)

export const getTextFieldProps = ({ key, required = true, ...props }) => {
  const textFieldLabels = {
    company_name: I18n.t('react.shared.company_name'),
    street: I18n.t('react.shared.street'),
    street_number: I18n.t('react.shared.street_number'),
    zip: I18n.t('react.shared.zip'),
    city: I18n.t('react.shared.city'),
    region: I18n.t('react.shared.region'),
    registration_location: I18n.t('react.shared.registration_location'),
    company_number: I18n.t('react.shared.company_number'),
    first_name: I18n.t('react.shared.first_name'),
    last_name: I18n.t('react.shared.last_name'),
    phone_number: I18n.t('react.shared.phone_number'),
    birthplace_city: I18n.t('react.shared.birthplace_city'),
    full_name: I18n.t('react.shared.full_name'),
    iban: I18n.t('react.shared.iban'),
    bic: I18n.t('react.shared.bic'),
  }
  return {
    required,
    component: FTextField,
    label: textFieldLabels[key],
    name: snakeCaseToCamelCase(key),
    placeholder: textFieldLabels[key],
    ...props,
  }
}

export const getCountryFieldProps = ({ key, ...props }) => {
  const countryFieldLabels = {
    nationality: I18n.t('react.shared.nationality'),
    birthplace_country: I18n.t('react.shared.birthplace_country'),
  }
  return {
    required: true,
    searchable: true,
    component: FSelectField,
    label: countryFieldLabels[key],
    name: snakeCaseToCamelCase(key),
    placeholder: countryFieldLabels[key],
    ...props,
  }
}

export const transformShareholderData = (item = {}) => {
  const { birthplaceCity, birthplaceCountry, city, countryCode, region, street, streetNumber, zip, ...rest } = item
  return {
    ...rest,
    birthplace: {
      city: birthplaceCity,
      country: birthplaceCountry,
    },
    address: {
      city,
      region,
      zip,
      country: countryCode,
      street: `${street} ${streetNumber}`,
    },
  }
}

export const transformLegitimationDocuments = (legitimationDocuments) =>
  legitimationDocuments.map(({ documentId }) => documentId).filter(Boolean)

export const splitStreet = (street = '') => {
  if (!street) {
    return {}
  }
  const streetArray = street.split(' ')
  const streetNumber = streetArray.splice(-1)[0]
  const streetName = streetArray.join(' ')
  return {
    streetName,
    streetNumber,
  }
}

export const getCountryFieldClasses = ({ errors = {}, touched = {}, name = 'countryCode' } = {}) =>
  classNames('fields-container fields-container--evenly fields-container--bottom-align', {
    'fields-container--with-error': touched[name] && errors[name],
  })

export const getCompanyDetails = (userProfile, wallet) => {
  const { city, countryCode, street, streetNumber, zip } = userProfile
  const { prefs: { headquarterAddress: { region } = {} } = {} } = wallet
  const address = `${street || ''} ${streetNumber || ''}`
  return {
    address,
    city,
    countryCode,
    region,
    zip,
  }
}

export const getPaymentProviderKey = (paymentProvider) => {
  if (paymentProvider === PAYMENT_PROVIDERS.lemonWay) return PAYMENT_METHODS.lemonWay
  if (paymentProvider === PAYMENT_PROVIDERS.mangoPay) return PAYMENT_METHODS.mangoPay
  if (paymentProvider === PAYMENT_PROVIDERS.elopageConnect) return snakeCaseToCamelCase(PAYMENT_METHODS.elopageConnect)
  return paymentProvider
}

export const getPaypalProvider = (payPalProvider, payPal) =>
  payPalProvider ||
  (payPal[PAYPAL_PROVIDERS.rest] && PAYPAL_PROVIDERS.rest) ||
  (payPal[PAYPAL_PROVIDERS.nvp] && PAYPAL_PROVIDERS.nvp)

export const checkIsPaypalProviderCreated = (paypalProvider) => {
  const { active, valid, v2, ...remain } = paypalProvider || {}
  return checkIsCreated(remain)
}
