import { GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/mobile_app_setting'

export const fetchItem = (data) => GET(BASE_URL, data)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (data) => PUT(BASE_URL, data)

export const checkServerKey = (data) => POST('https://fcm.googleapis.com/fcm/send', {}, data, true)

export const fetchAppsUrl = () => GET(`${BASE_URL}/apps_url`)
