import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/ticketDates.api'

export class TicketDatesStore extends SharedStore {
  storeName = 'TicketDatesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  //methods used for multi-level tables
  @observable ticketDatesByTicketId = {}
  @observable ticketDatesByTicketIdLoading = {}

  @action fetchTicketDatesByTicketId = (ticketId) => {
    const ticketDates = this.ticketDatesByTicketId[ticketId]

    if (!!ticketDates) {
      return ticketDates
    }

    this.ticketDatesByTicketIdLoading = {
      ...this.ticketDatesByTicketIdLoading,
      [ticketId]: true,
    }

    this.fetchFullList({ ticketId }).then((resp) => {
      this.ticketDatesByTicketId = {
        ...this.ticketDatesByTicketId,
        [ticketId]: resp.data.list,
      }

      this.ticketDatesByTicketIdLoading = {
        ...this.ticketDatesByTicketIdLoading,
        [ticketId]: false,
      }
    })
  }
  //end methodth for multi-level tables

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new TicketDatesStore()
