import { action, makeObservable, observable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/balance.api'

export class BalanceStore extends SharedStore {
  storeName = 'balanceStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable payoutData = {}
  @observable data = {
    balances: {},
  }

  @action setData = (data) => (this.data = data)

  @action fetchData = async (data) => {
    this.toggleLoading(true)
    const resp = await api.fetchData(data)
    this.setData(resp.data)
    this.toggleLoading(false)
    return resp
  }

  constructor() {
    super()

    makeObservable(this)

    this.loading = false
  }
}

export default new BalanceStore()
