import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/paymentTickets.api'

export class PaymentTicketsStore extends SharedStore {
  storeName = 'PaymentTicketsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  rebookItem = async (id, data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.rebookItem(id, data)
    this.toggleLoading(false)
    return resp
  }
}

export default new PaymentTicketsStore()
