import { GET, PUT } from 'utils/requests.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { PaginatedResponse } from 'types/responses'
import { Nullable } from 'types/helpers'
const apiPathCommunity = getElopageConfig('apiPathCommunity')

const SECONDARY_URL = `${apiPathCommunity}/v1/owner`
const BASE_URL = `${apiPathCommunity}/v1/discussions`
export interface CommunityMember {
  id: number
  username: string
  muted: boolean
  avatar: [
    {
      original: string
      cropped: Nullable<string>
    }
  ]
}

export interface CommunityComment {
  id: number
  content: string
  edited: boolean
  postId: number
  memberId: number
  createdAt: string
  updatedAt: string
  member: CommunityMember
}

export interface PostLabel {
  id: number
  name: string
  postId: number
  discussionLabelId: number
  created_at: string
  updated_at: string
}

export interface PostForReview {
  id: number
  title: string
  content: string
  pinned: boolean
  edited: boolean
  state: string
  discussionId: number
  memberId: number
  createdAt: string
  updatedAt: string
  coverCropW: Nullable<number>
  coverCropH: Nullable<number>
  coverCropX: Nullable<number>
  coverCropY: Nullable<number>
  likesCount: number
  commentsCount: number
  liked: boolean
  member: CommunityMember
  discussion: {
    name: string
    productId: number
  }
  cover: [
    {
      original: string
      cropped: Nullable<string>
    }
  ]
  labels: PostLabel[]
  comments: CommunityComment[]
}
interface Post {
  id: number
  state: string
}

interface Params {
  skipErrorNotific?: boolean
}

export const fetchList = (): Promise<PaginatedResponse<PostForReview[]>> => GET(`${SECONDARY_URL}/posts/for_review`)
export const updateItem = (discussionId: number, post: Post, params: Params = {}) =>
  PUT(`${BASE_URL}/${discussionId}/posts/${post.id}`, post, params)
