import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

const BASE_URL = (data) => ((data || {}).productId ? `/cabinet/products/${data.productId}/lessons` : '/cabinet/lessons')

export const fetchList = (data) => GET(BASE_URL(data), data)

export const fetchItem = (id, data) => GET(`${BASE_URL(data)}/${id}`, data)

export const createItem = (data) => POST(BASE_URL(data), data)

export const updateItem = (id, data) => PUT(`${BASE_URL(data)}/${id}`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL(data)}/${id}`)

export const duplicateItem = (id, data) => POST(`${BASE_URL(data)}/${id}/duplicate`)

export const notifyAboutChangesItem = (id, data) => POST(`${BASE_URL(data)}/${id}/notify_about_changes`, data)
