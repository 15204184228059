import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/teamMemberRole.api'

const defaultForm = {
  name: '',
  teamMemberRolePermissionIds: [],
  loading: false,
}

export class TeamMemberRolesStore extends SharedStore {
  storeName = 'TeamMemberRolesStore'
  childApi = api
  @observable form = defaultForm

  @action resetForm = () => {
    this.form = defaultForm
  }

  @action setForm = (form) => {
    this.form = {
      ...this.form,
      ...form,
    }
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new TeamMemberRolesStore()
