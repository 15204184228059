import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/documents.api'

export class DocumentsStore extends SharedStore {
  storeName = 'DocumentsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions
}

export default new DocumentsStore()
