import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { AffiliateProgramProduct } from 'shared/components/affiliate-marketplace/types/types'

import * as api from '../api/affiliateMarketplaceProgramProductsStore.api'

export class AffiliateMarketplaceProgramProductsStore extends SharedStore<AffiliateProgramProduct> {
  storeName = 'AffiliateMarketplaceProgramProductsStore'
  childApi = api

  constructor() {
    super()
    makeObservable(this)
  }
}
