import { GET } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/cabinet/shared_templates'

export interface SharedTemplates {
  id: number
  homepage: boolean
  name: string
  description: string
  entityType: string
  previewLink?: string
  eloshareLink: string
  coverId: string
  createdAt: string
  updatedAt: string
  cover: Record<string, any>
}

export const fetchList = (data) => GET<PaginatedResponse<SharedTemplates[]>>(BASE_URL, data)

export const fetchItem = (id, data) => GET<Response<SharedTemplates>>(`${BASE_URL}/${id}`, data)
