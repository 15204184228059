import { GET, POST, PUT, DELETE } from 'utils/requests.utils'

import { Response, PaginatedResponse, HTTPResponse } from 'types/responses'

const BASE_URL = '/cabinet/labels'

interface ReqData {
  name: string
  description: string
  isPriority: boolean
  productLabelsAttributes: {
    productId: number
    _destroy: boolean
  }[]
}

export interface Label {
  createdAt: string
  id: number
  name: string
  description: string
  isPriority: boolean
  productsCount: number
  sellerId: number
  updatedAt: string
  products: {
    productId: number
    productLabelId: number
  }[]
}

export const fetchList = (data) => GET<PaginatedResponse<Label[]>>(BASE_URL, data)

export const fetchItem = (id: number) => GET<Response<Label>>(`${BASE_URL}/${id}`)

export const createItem = (data: ReqData) => POST<Response<Label>>(BASE_URL, data)

export const updateItem = (id: number, data: ReqData) => PUT<Response<Label>>(`${BASE_URL}/${id}`, data)

export const deleteItem = (id: number) => DELETE<HTTPResponse>(`${BASE_URL}/${id}`)
