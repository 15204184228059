import { GET, POST, PATCH } from 'utils/requests.utils'
import { HTTPResponse } from 'types/responses'

const BASE_URL = (id) => `/cabinet/sales_teams/${id}/sales_team_member_invites`

export const fetchList = ({ id, ...restData }) => GET<HTTPResponse>(BASE_URL(id), restData)

export const deleteItem = ({ id, salesTeamInviteId }) =>
  POST<HTTPResponse>(`${BASE_URL(id)}/${salesTeamInviteId}/cancel`)

export const updateItem = ({ id, salesTeamInviteId }, data) =>
  PATCH<HTTPResponse>(`${BASE_URL(id)}/${salesTeamInviteId}`, data)
