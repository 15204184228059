import { action, makeObservable, observable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/orderRates.api'

const defaultForm = {
  chargeDate: Date.now(),
  amount: 0,
}

export class OrderRatesStore extends SharedStore {
  storeName = 'OrderRatesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable form = defaultForm

  @action resetForm = () => {
    this.form = defaultForm
  }

  @action setForm = (form) => {
    this.form = {
      ...this.form,
      ...form,
    }
  }

  refundItem = (id, data) => api.refundItem(id, data)

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new OrderRatesStore()
