import { GET, POST } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'
import { Nullable } from 'types/helpers'

export interface BankAccountLegitimation {
  id: number
  bankAccountId: number
  currencyId: Nullable<number>
  errorData: ErrorData
  extId: Nullable<string>
  extState: string
  intState: string
  legitimationId: number
  msg: Nullable<string>
  type: string
  createdAt: string
  updatedAt: string
}

interface ErrorData {
  code?: string
  type?: string
  param?: string
  docUrl?: string
  message?: string
}

export interface LegitimationPayload {
  bankAccountId: number
  currencyId: number
}

const BASE_URL = '/cabinet/settings/payment_setting/elopage_legitimations/elopage_bank_accounts'

export const createBankAccount = (data: LegitimationPayload) => POST<Response<BankAccountLegitimation>>(BASE_URL, data)
export const fetchList = () => GET<PaginatedResponse<BankAccountLegitimation[]>>(`${BASE_URL}`)
