import React from 'react'
import { components } from 'react-select'

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <i
      className={
        props.selectProps.menuIsOpen
          ? 'fas fa-sort-up elo-select-field__dropdown-indicator--arrow-up-position'
          : 'fas fa-sort-down'
      }
    />
  </components.DropdownIndicator>
)

export default DropdownIndicator
