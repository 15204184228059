import type { ApiClientV2Default } from 'utils/api-client/api-client.utils'

import { HTTPResponse } from 'types/responses'

const BASE_URL = '/cabinet/sellers'

export const createClearCacheApi = ({ POST }: ApiClientV2Default) => ({
  clearCache: (sellerId: string) => POST<HTTPResponse>(`${BASE_URL}/${sellerId}/delete_cache`),
})

export type ClearCacheApi = ReturnType<typeof createClearCacheApi>
