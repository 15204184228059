import { computed, observable, makeObservable, override } from 'mobx'
import { PaginatedResponse } from 'types/responses'
import { isEmpty } from '@elo-kit/utils/validators.utils'
import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/inkassoLegitimation.api'

export class InkassoLegitimationStore extends SharedStore<api.BankAccountLegitimation> {
  storeName = 'InkassoLegitimationStore'
  childApi = api
  @observable bankAccountsList = []
  @observable submitting = false
  @observable wallet = {}

  constructor() {
    super()

    makeObservable(this)
  }

  @computed get hasBankAccounts(): boolean {
    return !!this.list.length
  }

  @computed get legitimationCreated(): boolean {
    return !isEmpty(this.item)
  }

  createBankAccount = this.childApi.createBankAccount

  @override async fetchList(id?: number): Promise<PaginatedResponse<api.BankAccountLegitimation[]>> {
    return await super.fetchList(id)
  }
}

export default new InkassoLegitimationStore()
