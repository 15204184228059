import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/teamMemberRolePermissionCategories.api'

export class TeamMemberRolePermissionCategoriesStore extends SharedStore {
  storeName = 'TeamMemberRolePermissionCategoriesStore'
  childApi = api
}

export default new TeamMemberRolePermissionCategoriesStore()
