import { action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/dnsRecords.api'

export class DnsRecordsStore extends SharedStore {
  storeName = 'DnsRecordsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  @action submitItems = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.submitItems()

    if (resp.success) {
      this.fetchFullList()
    }

    this.toggleLoading(false)

    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new DnsRecordsStore()
