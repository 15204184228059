import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import * as api from '../api/labels.api'

export class LabelsStore extends SharedStore<api.Label> {
  storeName = 'LabelsStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new LabelsStore()
