import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/liveShoppingOffers.api'

export class LiveShoppingOffersStore extends SharedStore {
  storeName = 'LiveShoppingOffersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  publishItem = api.publishItem
  unPublishItem = api.unPublishItem
}

export default new LiveShoppingOffersStore()
