import { computed, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/digitals.api'

export class DigitalsStore extends SharedStore {
  storeName = 'DigitalsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions

  @override
  async createItem(data, handleProgress) {
    this.loading = true
    const resp = await this.childApi.createItem(data || this.item, handleProgress)
    this.item = resp.data
    this.loading = false
    return resp
  }

  async patchWithProcess(id, data, handleProgress) {
    this.loading = true
    const resp = await this.childApi.patchItemWithProcess(id, data || this.item, handleProgress)
    this.item = resp.data
    this.loading = false
    return resp
  }

  @computed get wistiaProjectPass() {
    return this.root.sellerStore.item.wistiaProjectPass
  }

  @computed get wistiaProjectId() {
    return this.root.sellerStore.item.wistiaProjectId || this.root.sellerStore.createWistiaProject()
  }

  constructor(rootStore) {
    super()

    this.root = rootStore
    makeObservable(this)
  }

  sendVideosToRemove = async (data) => {
    this.toggleLoading(true)
    await api.sendVideosToRemove(data)
    this.toggleLoading(false)
  }

  async deleteVideoThumbnail(id) {
    this.loading = true
    const resp = await this.childApi.deleteVideoThumbnail(id)
    this.loading = false
    return resp
  }
}

export default new DigitalsStore()
