import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/publishers.api'

export class PublishersStore extends SharedStore {
  storeName = 'PublishersStore'
  childApi = api

  constructor() {
    super()

    this.expands = ['user_profile', 'user']
  }
}

export default new PublishersStore()
