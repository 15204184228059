import { GET, POST, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/settings/payment_setting/elopage_connect_legitimation'

const BANK_ACCOUNTS_URL = `${BASE_URL}/elopage_connect_bank_accounts`

const WALLETS_URL = `${BASE_URL}/elopage_connect_wallets`

export const fetchItem = () => GET(`${BASE_URL}?expand[]=legitimation_documents`, { skipErrorNotific: true })
export const updateItem = (data) => PUT(BASE_URL, data)
export const createItem = (data) => POST(BASE_URL, data)

export const fetchBankAccountsList = (data) => GET(`${BANK_ACCOUNTS_URL}`, { ...data, skipErrorNotific: true })
export const createBankAccount = (data) => POST(`${BANK_ACCOUNTS_URL}`, data)
export const registerBankAccount = (id) => POST(`${BANK_ACCOUNTS_URL}/${id}/register`)

export const fetchWalletsList = () => GET(WALLETS_URL)

export const continueOnboarding = () => POST(`${BASE_URL}/onboarding_link`)

export const fetchRequirements = () => GET(`${BASE_URL}/requirements`)

export const fetchSupportedCurrencies = () => GET(`${BASE_URL}/supported_currencies`)
