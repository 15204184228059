import { runInAction } from 'mobx'

import { QUIZ_ATTEMPTS_EXPANDS } from 'constants/quiz.constants'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/quizAttempts.api'

export class QuizAttemptsStore extends SharedStore {
  storeName = 'QuizAttemptsStore'
  childApi = api

  submitStatus = async ({ status, submitComment }, id) => {
    const resp = await api.updateItem(id, {
      status,
      submitComment,
      expand: QUIZ_ATTEMPTS_EXPANDS,
    })
    if (resp.success) {
      runInAction(() => (this.item = resp.data))
    }
    return resp
  }
}
export default new QuizAttemptsStore()
