import SharedStore from 'shared/stores/shared.store'
import { makeObservable } from 'mobx'
import * as api from '../api/pastDueOrders.api'

export class PastDueOrdersStore extends SharedStore<api.PastDueOrder> {
  storeName = 'PastDueOrdersStore'
  childApi = api

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new PastDueOrdersStore()
