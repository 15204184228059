import { GET, PUT, POST, DELETE } from 'utils/requests.utils'

const BASE_URL = '/cabinet/power_seller_documents'

export const fetchList = () => GET(BASE_URL)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const deleteItem = (id, data) => DELETE(`${BASE_URL}/${id}`, data)
