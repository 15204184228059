import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/sellerEmailConfigurations.api'

export class SellerEmailConfigurationsStore extends SharedStore {
  storeName = 'SellerEmailConfigurationsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to user them with promise.then() to do after-actions

  @override
  async updateItem(data) {
    this.toggleLoading(true)
    const resp = await this.childApi.updateItem(data || this.item)
    this.setItem(resp)
    this.toggleLoading(false)
    return resp
  }

  testEmail = async (data) => {
    this.toggleCustomLoading(true)
    const resp = await this.childApi.testEmail(data)
    this.toggleCustomLoading(false)
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new SellerEmailConfigurationsStore()
