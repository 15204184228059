import { GET, POST, PUT, DELETE, POST_WITH_PROGRESS, PUT_WITH_PROGRESS } from 'utils/requests.utils'

const BASE_URL = '/cabinet/covers'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchItem = (id) => GET(`${BASE_URL}/${id}`)

export const createItem = (data) => POST(BASE_URL, data)

export const updateItem = (id, data) => PUT(`${BASE_URL}/${id}`, data)

export const createItemWithProgress = (data, handleProgress) => POST_WITH_PROGRESS(BASE_URL, data, handleProgress)

export const updateItemWithProgress = (id, data, handleProgress) =>
  PUT_WITH_PROGRESS(`${BASE_URL}/${id}`, data, handleProgress)

export const deleteItem = (id) => DELETE(`${BASE_URL}/${id}`)

export const sendCoversToRemove = (data) => POST(`${BASE_URL}/sent_to_remove`, data)
