import SharedStore from 'shared/stores/shared.store'
import { makeObservable, override } from 'mobx'
import { PaginatedResponse } from 'types/responses'
import { Report, ReportPayload } from '../api/inkassoReports.api'
import * as api from '../api/inkassoReports.api'
export class InkassoReportsStore extends SharedStore<api.Report> {
  childApi = api
  storeName = 'inkassoReportsStore'
  constructor() {
    super()

    makeObservable(this)
  }

  createReport = (data: ReportPayload) => this.childApi.createReport(data)

  @override fetchList(): Promise<PaginatedResponse<Report[]>> {
    return super.fetchList({
      form: 'inkasso_balance',
    })
  }
}

export const inkassoReportsStore = new InkassoReportsStore()
