import { action, observable, makeObservable } from 'mobx'

import { ascendingSort } from 'utils/helpers.utils'

import { notify } from 'libs/common/notify'

import { ALL } from '@elo-kit/constants/general.constants'

import * as api from '../api/filters.api'

export class FiltersStore {
  @observable filters = {}
  @observable loading = {}

  @action setFilters = (filters) => (this.filters = filters)
  @action setLoading = (loading) => (this.loading = loading)

  updateFilters = ({ namespace = ALL, list }) => {
    const filters = {
      ...this.filters,
      [namespace]: ascendingSort(list),
    }
    this.setFilters(filters)
  }

  updateLoading = ({ namespace = ALL, value }) => {
    const loading = {
      ...this.loading,
      [namespace]: value,
    }
    this.setLoading(loading)
  }

  addStatistics = async ({ form, namespace }) => {
    const resp = await api.createItem({
      form,
      fromDefault: true,
    })

    const list = [...(this.filters[namespace] || []), resp.data]
    this.updateFilters({
      namespace,
      list,
    })

    notify('success', I18n.t('react.cabinet.notific.created'))
    return resp
  }

  deleteItem = async ({ id, namespace }) => {
    await api.deleteItem(id)

    const list = this.filters[namespace || ALL].filter((item) => item.id !== id)
    this.updateFilters({
      namespace,
      list,
    })

    notify('success', I18n.t('react.cabinet.notific.removed'))
  }

  duplicateItem = async (data, namespace) => {
    const { data: filter } = await api.createItem(data)

    const list = [...this.filters[namespace || ALL], filter]
    this.updateFilters({
      namespace,
      list,
    })

    notify('success', I18n.t('react.cabinet.notific.duplicated'))
  }

  updateItem = async ({ data, id, namespace }) => {
    const { data: filter = {} } = (await api.updateItem(id, { data })) || {}

    const list = this.filters[namespace].map((item = {}) => (item.id === filter.id ? filter : item))
    this.updateFilters({
      namespace,
      list,
    })
  }

  fetchList = async (namespace) => {
    this.updateLoading({
      namespace,
      value: true,
    })

    const resp = await api.fetchList({ namespace })
    const { list = [] } = resp.data || {}

    this.updateFilters({
      namespace,
      list,
    })
    this.updateLoading({
      namespace,
      value: false,
    })

    return list
  }

  constructor() {
    makeObservable(this)
  }
}

export default new FiltersStore()
