import { GET, POST } from 'utils/requests.utils'

const BASE_URL = '/cabinet/payouts'

export const fetchList = (data) => GET(`${BASE_URL}`, data)

export const fetchWallet = (id) => GET(`${BASE_URL}/new`, { wallet_id: id })

export const getLeftAmount = (data) => GET(`${BASE_URL}/amount`, data)

export const fetchSchedule = (data) => GET(`${BASE_URL}/schedule`, data)

export const fetchStatistic = (data) => GET(`${BASE_URL}/statistic`, data)

export const payoutCashback = (data) => POST(`${BASE_URL}`, data)

export default fetchList
