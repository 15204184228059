import { GET, POST } from 'utils/requests.utils'
import { Response, PaginatedResponse } from 'types/responses'

const BASE_URL = '/cabinet/financial_reports/'

export interface Report {
  id: number
  bankAccountLegitimation: {
    id: number
    currencyId: number
  }
  name: string
  dateFrom: string
  dateTo: string
}

export interface ReportPayload {
  currencyId: number
  name: string
  dateFrom: Date
  dateTo: Date
  form: string
}

export const fetchList = (data: { form: string }) => GET<PaginatedResponse<Report[]>>(BASE_URL, data)

export const fetchItem = (id: number) => GET<Response<Report>>(`${BASE_URL}/${id}`)

export const createReport = (data: ReportPayload) => POST<Response<Report>>(BASE_URL, data)
