import { observable, action, makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/teamMemberInvite.api'

const defaultForm = {
  inviteeFirstName: '',
  inviteeLastName: '',
  email: '',
  teamMemberRoleId: 0,
  limitByProducts: false,
  whitelistedProductIds: [],
  recaptcha: '',
  loading: false,
}

export class TeamMemberInvitesStore extends SharedStore {
  storeName = 'TeamMemberInvitesStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @observable form = defaultForm

  @action resetForm = () => {
    this.form = defaultForm
  }

  @action setForm = (form) => {
    this.form = {
      ...this.form,
      ...form,
    }
  }

  @action toggleCancelItem = async (id) => {
    this.loading = true

    const resp = await this.childApi.toggleCancelItem(id)

    if (resp.success) this.fetchList()

    this.loading = false
  }

  constructor() {
    super()

    makeObservable(this)
  }
}

export default new TeamMemberInvitesStore()
