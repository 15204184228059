import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/affiliateMarketplace.api'

export interface MarketplaceItem {
  displayCurrencyId(price: string, displayCurrencyId: any): import('react').ReactNode
  bonus: number
  commission: string[]
  covers: any
  form: string
  id: number
  internalName: string
  name: string
  price: string
  displayPrice: string
  published: boolean
  publishableToMarketplace: boolean
}

export class AffiliateMarketplaceStore extends SharedStore<MarketplaceItem> {
  storeName = 'AffiliateMarketplaceStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
}

export default new AffiliateMarketplaceStore()
