import { makeObservable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/transfers.api'

const initialValues = {
  canRefundNow: false,
  isGift: false,
  productForm: '',
  installment: false,
  payer: {
    name: '',
    adress: '',
    email: '',
    phone: '',
  },
  creditMemo: {
    number: '',
    link: '',
  },
  seller: {
    name: '',
    adress: '',
    email: '',
  },
  product: [
    {
      cover: '',
      productLink: '',
      successLink: '',
      name: '',
    },
  ],
  id: false,
  free: false,
  idForSeller: false,
  firstTotal: 0,
  cancellationTermPeriod: 0,
  baseForJvPartner: 0,
  baseForJvPartnerFormula: '',
  transferableType: '',
  price: 0,
  reportType: '',
  amount: 0,
  successDate: '',
  state: '',
  form: '',
  total: 0,
  teamMemberFeeFormula: '',
  coupon: {
    discount: 0,
  },
  publisherCommissions: {
    seller: false,
  },
  publisherFee: 0,
  teamMemberFee: 0,
  serviceFee: 0,
  vatRate: 0,
  vatAmount: 0,
  canBeRefunded: false,
  failedOnRefund: false,
  showRefundButton: false,
  processingFee: 0,
  chargebackFee: 0,
  refundFee: 0,
  refundAmount: 0,
  attendees: [],
  versions: [],
}

export class TransfersStore extends SharedStore {
  storeName = 'TransfersStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  refund = this.childApi.refund
  cancelBankWire = this.childApi.cancelBankWire
  markAsRefunded = this.childApi.markAsRefunded
  extendAccess = this.childApi.extendAccess
  confirmBankWire = this.childApi.confirmBankWire

  fetchSummary = (data) =>
    api.fetchSummary({ ...this.getParamsWoRelativeData(data), ...this.getFormattedRelativeData() })

  constructor() {
    super()

    makeObservable(this)

    this.item = initialValues
  }
}

export default new TransfersStore()
