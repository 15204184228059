import { action, observable, makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/covers.api'

export class CoversStore extends SharedStore {
  storeName = 'CoversStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promise.then() to do after-actions
  @observable loadingCovers = {}

  @action toggleLoadingForCover = (value, name) => {
    this.loadingCovers = {
      ...this.loadingCovers,
      [name]: value,
    }
  }

  @override
  async createItem(data, handleProgress) {
    this.loading = true

    const createFn = handleProgress ? this.childApi.createItemWithProgress : this.childApi.createItem
    const resp = await createFn(data || this.item, handleProgress)
    this.item = resp.data
    this.loading = false
    return resp
  }

  @override
  async updateItem(id, data, handleProgress) {
    this.loading = true

    const updateFn = handleProgress ? this.childApi.updateItemWithProgress : this.childApi.updateItem
    const resp = await updateFn(id, data || this.item, handleProgress)
    this.item = resp.data
    this.loading = false
    return resp
  }

  constructor() {
    super()

    makeObservable(this)
  }

  sendCoversToRemove = async (data) => {
    this.toggleLoading(true)
    await api.sendCoversToRemove(data)
    this.toggleLoading(false)
  }

  updateItemName = async (id, data) => {
    this.toggleLoading(true)
    const resp = await api.updateItem(id, data)
    this.toggleLoading(false)
    return resp
  }
}

export default new CoversStore()
