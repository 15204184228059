import { action, makeObservable, observable } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/domain.api'

export class DomainsStore extends SharedStore {
  storeName = 'DomainsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions
  @observable subDomainItem = {}
  @observable customDomainItem = {}

  @action setSubDomainItem = (data) => (this.subDomainItem = data)

  @action fetchSubDomain = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchSubDomain(data)

    if (resp.success) {
      this.setSubDomainItem(resp.data)
    }

    this.toggleLoading(false)

    return resp
  }

  @action fetchCustomDomain = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.fetchDomain(data)

    if (resp.success) {
      this.customDomainItem = resp.data
    }

    this.toggleLoading(false)

    return resp
  }

  @action activateSubDomain = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.activateSubDomain(data)

    if (resp.success) {
      this.fetchSubDomain()
    }

    this.toggleLoading(false)

    return resp
  }

  @action createSubDomain = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.createSubDomain(data)

    if (resp.success) {
      this.fetchSubDomain()
    }

    this.toggleLoading(false)

    return resp
  }

  @action deactivateSubDomain = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.deactivateSubDomain()

    if (resp.success) {
      this.fetchSubDomain()
    }

    this.toggleLoading(false)

    return resp
  }

  @action removeCustomDomain = async () => {
    this.toggleLoading(true)
    const resp = await this.childApi.removeDomain()
    this.toggleLoading(false)

    return resp
  }

  constructor() {
    super()

    makeObservable(this)

    this.loading = false
  }
}

export default new DomainsStore()
