import { GET, PUT } from 'utils/requests.utils'

const BASE_URL = '/cabinet/notifications'

export const fetchList = (data) => GET(BASE_URL, data)

export const fetchUnreadCount = (data) => GET(`${BASE_URL}/unread_count`, data)

export const read = (id) => PUT(`${BASE_URL}/${id}/read`)

export const unread = (id) => PUT(`${BASE_URL}/${id}/unread`)

export const readAll = () => PUT(`${BASE_URL}/read_all`)
