import { GET } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/cabinet/unpaid_order_rates'

export interface PastDueOrder {
  invoice: {
    invoiceIds: number[]
    invoiceNumbers: string[]
  }
  payer: {
    id: number
    companyName: Nullable<string>
    name: string
    business: boolean
  }
  order: {
    orderAmount: string
    dueAmount: string
    orderId: number
    pricingPlan: string
    pastDueDate: string
    daysOverdue: number
    currencyId: number
    buyerCountryCode?: string
    paymentForm: string
    provider: string
  }
  seller: {
    id: number
    powerSeller: boolean
    name: string
    preferredStrategy: string
  }
  orderRateId: number
  id: number
  number: number
  state: string
  amount: string
  chargeDate: string
  reminderSent: Nullable<string>
  orderId: number
  createdAt: string
  updatedAt: string
  invoiceId: Nullable<string>
  vat: string
  paidAt: Nullable<string>
  shiftDays: number
  data: Record<string, unknown>
}

export const fetchList = (data) => GET<PaginatedResponse<PastDueOrder[]>>(BASE_URL, data)

export const fetchItem = (id: number) => GET<Response<PastDueOrder>>(`${BASE_URL}/${id}`)
