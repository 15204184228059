import { GET, POST, PUT } from 'utils/requests.utils'
import { HTTPResponse } from 'types/responses'

const BASE_URL = '/cabinet/sales_teams'

export interface SalesTeam {
  name: string
  description?: string
}

interface InviteBulks {
  emails: string[]
  customerAccess: boolean
}

interface ProductIncentivesAttribute {
  commissionRate: number
  bonus: number
  productId: number
}

interface CreateIncentivePlanData {
  name: string
  productIncentivesAttributes: ProductIncentivesAttribute[]
  allProducts: boolean
}

export const fetchList = (data) => GET<HTTPResponse>(BASE_URL, data)

export const createItem = (data: SalesTeam) => POST<HTTPResponse>(BASE_URL, data)

export const updateItem = (id: number, data: SalesTeam) => PUT<HTTPResponse>(`${BASE_URL}/${id}`, data)

export const inviteBulks = (id: number, data: InviteBulks) =>
  POST<HTTPResponse>(`${BASE_URL}/${id}/sales_team_invite_bulks`, data)

export const activeTeam = (id: number) => POST<HTTPResponse>(`${BASE_URL}/${id}/activate`)

export const createIncentivePlan = (id: number, data: CreateIncentivePlanData) =>
  POST<HTTPResponse>(`${BASE_URL}/${id}/incentive_plans`, data)

export const fetchIncentivePlansList = (id) => GET<HTTPResponse>(`${BASE_URL}/${id}/incentive_plans`)
